<template>
    <div id="historical-databooks-view" class="page">
        <h1>Historical Data Books</h1>
        <ul class="download-list">
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2017
                    <span class="sub-heading">Last updated: November, 2018</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2017_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img :src="baseURL + '/static/img/acrobat.png'" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2017_Final.pptx" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2016
                    <span class="sub-heading">Last updated: November, 2018</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2016_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2016_Final.pptx" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2015
                    <span class="sub-heading">Last updated: November, 2017</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2015_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2015_Final.pptx" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2014
                    <span class="sub-heading">Last updated: May, 2016</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2014_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2014_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2013
                    <span class="sub-heading">Last updated: January, 2014</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2013_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2013_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2012
                    <span class="sub-heading">Last updated: November, 2013</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2012_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2012_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2011
                    <span class="sub-heading">Last updated: December, 2012</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2011_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2011_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2010
                    <span class="sub-heading">Last updated: April, 2012</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2010_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2010_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2009
                    <span class="sub-heading">Last updated: December, 2010</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2009_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2009_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2008
                    <span class="sub-heading">Last updated: May, 2009</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NDB_2008_Final.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NDB_2008_Final.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="heading">
                    NIH Data Book, Fiscal Year 2007
                    <span class="sub-heading">Last updated: May, 2008</span>
                </div>

                <div class="download-buttons">
                    <a href="static/historical/NEDB_Core_Deck.pdf" class="btn-download btn-pdf" rel="noopener">
                        <img src="static/img/acrobat.png" srcset="static/img/acrobat@2x.png 2x" alt="Download PDF"><span>Download PDF</span>
                    </a>

                    <a href="static/historical/NEDB_Core_Deck.ppt" class="btn-download btn-ppt" rel="noopener">
                        <img src="static/img/powerpoint.png" srcset="static/img/powerpoint@2x.png 2x" alt="Download PowerPoint"><span>Download PPT</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HistoricalDataBooks',
    data () {
        return {
            pageTitle: 'Historical Data Books',
            baseURL: window.baseURL
        };
    },
    mounted () {
        document.title = this.$parent.pageTitle + ' - ' + this.pageTitle;
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.download-list {
    list-style: none;
    margin: 30px 0;
    padding: 0;

    .heading {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 0;
        font-size: 18px;
        color: #333;
        vertical-align: top;
    }

    .sub-heading {
        display: block;
        margin-top: -4px;
        font-size: 12px;
        color: #6d6d6d;
        font-weight: 600;
    }

    > li {
        margin-bottom: 25px;
    }
}

.download-buttons {
    display: inline-block;
    a {
        text-decoration: none;
    }
}

.btn-download {
    display: inline-block;
    padding: 6px 10px;
    background-color: #fff;
    color: #ad1700;
    // border: 1px solid #ab1800;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    vertical-align: top;

    + .btn-download {
        margin-left: 6px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }

    img {
        margin-right: 5px;
    }
}
</style>
