<template>
    <div id="main" v-if="init" :class="{'page-or-category-view': $route.params.categoryId || $route.params.page}">
        <site-header v-if="!chartOnly"/>
        <sidebar :sidebarVisible="sidebarVisible" ref="sidebar" v-show="!chartOnly"/>

        <div id="content" role="main">
            <div v-if="!$route.params.categoryId && !$route.params.id && !$route.params.page" id="landing-page" class="page row">
                <div class="col-md-12">
                    <h1>Welcome to the NIH Data Book</h1>
                </div>
                <div class="col-xl-5 col-lg-6">
                    <div class="callout-box">
                        <h2>What’s New?</h2>
                        <ul class="ps-4">
                            <li class="new-reports-list ps-1 pb-1">FY2023 statistics are now available for most grant-related reports, including:
                                <router-link :to="{ path: '/category/10' }">Success Rates</router-link>
                                <router-link :to="{ path: '/category/6' }">Research Grants</router-link>
                                <router-link :to="{ path: '/category/14' }">The NIH-Funded Research Workforce</router-link>
                            </li>
                        </ul>
                    </div>
                    <p>The NIH Data Book (NDB) provides basic summary statistics on extramural grants and contract awards, grant applications, the organizations that NIH supports, the trainees and fellows supported through NIH programs, and the national biomedical workforce.<br>
                    <br>
                    The Data Book is organized into categories and sub-categories, each of which will display related reports together on a single page. Most reports provide both an interactive chart visualization and the underlying data table. Chart images can be exported in a PNG graphic format. Tabular data export is available in the Excel file format.<br>
                    <br>
                    The interactive chart interface provides a visual summary with easy access to the details of each report. Mouse over visualizations to see the specific values, and interact with any buttons above a chart to customize the display.<br>
                    <br>
                    <span class="button-with-svg widget-button widget-accent-button widget-button-popout nohover">
                        <i class="fas fa-external-link-alt widget-button-icon"></i>
                    </span>
                    The pop out icon expands a report into a single full screen display.<br>
                    <br>
                    <span class="button-with-svg widget-button widget-accent-button widget-button-share nohover">
                        Share
                        <i class="ms-1 fas fa-link widget-button-icon"></i>
                    </span>
                    The share link provides a direct link you can email or copy to access a specific report.<br>
                    <br>
                    The search feature in the upper right returns reports by specific title keywords or report number. For example, type <em>success rates</em> or <em>168</em> and press [enter] and select from the reports listed.<br>
                    <br>
                    Historical versions of the NIH Data Book are provided in both PowerPoint and PDF formats.<br>
                    <br>
                    Please address questions or comments on the NIH Data Book to <a href="mailto:RePORT@mail.nih.gov" class="text-decoration-none">RePORT@mail.nih.gov</a>.
                    </p>
                </div>
                <div class="col-xl-7 col-lg-6">
                    <div class="row">
                        <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                            <report-mini reportId="5" ref="reportMini"/>
                        </div>
                        <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                            <report-mini reportId="155" ref="reportMini"/>
                        </div>
                        <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                            <report-mini reportId="20" ref="reportMini"/>
                        </div>
                        <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                            <report-mini reportId="226" ref="reportMini"/>
                        </div>
                    </div>
                </div>
            </div>
            <report-full v-if="$route.params.id && !$route.params.page" ref="reportFull"/>
            <dashboard v-if="$route.params.categoryId && !$route.params.page" ref="dashboard"/>
            <historical-data-books v-if="$route.params.page === 'historical-data-books'" />
            <FAQ v-if="$route.params.page === 'faq'" />
            <field-classification v-if="$route.params.page === 'field-classification'" />
            <report-index v-if="$route.params.page === 'report-index'" />
            <retired-report v-if="$route.params.page === 'retired-report'" />
            <report-not-found v-if="$route.params.page === 'report-not-found'" />
        </div>

        <site-footer v-if="!chartOnly"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteHeader from '@/components/SiteHeader';
import SiteFooter from '@/components/SiteFooter';
import Sidebar from '@/components/Sidebar';
import Dashboard from '@/components/Dashboard';
import ReportFull from '@/components/ReportFull';
import ReportMini from '@/components/ReportMini';
import HistoricalDataBooks from '@/components/HistoricalDataBooks';
import RetiredReport from '@/components/RetiredReport';
import ReportNotFound from '@/components/ReportNotFound';
import FAQ from '@/components/faq';
import fieldClassification from '@/components/fieldClassification';
import reportIndex from '@/components/reportIndex';
export default {
    name: 'Main',
    components: {
        SiteHeader,
        SiteFooter,
        Sidebar,
        Dashboard,
        ReportFull,
        ReportMini,
        HistoricalDataBooks,
        RetiredReport,
        ReportNotFound,
        FAQ,
        reportIndex,
        fieldClassification
    },
    computed: {
        ...mapGetters([
            'categoryList'
        ])
    },
    mounted () {
        this.checkIsActualPageURL();

        this.testLocation();

        this.$nextTick(() => {
            this.mobileCheck({
                firstLoad: true
            });

            // $(window).resize(() => {
            window.addEventListener('resize', () => {
                this.mobileCheck();
            });
        });
        
        this.init = true;
    },
    data () {
        return {
            init: false,
            serverless: false,
            proxy: false,
            // proxyServer: '192.168.56.101:55028',
            proxyServer: 'https://localhost:59971',
            parentURL: window.parentURL,
            legacyURL: 'legacydatabook',
            isMobile: false,
            isLargeScreen: false,
            sidebarVisible: true,
            sidebarUserToggled: false,
            sidebarAnimating: false,
            activeReport: null,
            chartOnly: this.$route.query.chartOnly,
            reportRedirects: {
                '53': 52,
                '85': 223,
                '104': 60,
                '105': 61,
                '106': 62,
                '117': 96,
                '124': 20,
                '126': 29,
                '153': 226,
                '161': 115,
                '162': 116,
                '163': 157,
                '165': 136,
                '165': 136,
                '176': 132,
                '177': 132,
                '178': 131,
                '180': 52,
                '203': 293,
                '204': 294,
                '208': 207,
                '210': 300,
                '213': 281,
                '215': 214,
                '216': 282,
                '221': 30,
                '222': 164,
                '224': 211,
                '228': 227,
                '229': 227,
                '230': 227,
                '232': 231,
                '233': 231,
                '234': 231,
                '236': 235,
                '237': 235,
                '238': 235,
                '240': 239,
                '241': 239,
                '242': 239,
                '244': 243,
                '245': 243,
                '246': 243,
                '248': 247,
                '249': 247,
                '250': 247,
                '252': 251,
                '253': 251,
                '254': 251,
                '256': 255,
                '257': 255,
                '258': 255,
                '260': 259,
                '261': 259,
                '262': 259,
                '264': 263,
                '265': 263,
                '266': 26
            },
            retiredReports: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 28, 37, 38, 40, 42, 46, 47, 51, 54, 55, 56, 57, 58, 59, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 87, 88, 89, 91, 92, 93, 95, 97, 98, 99, 100, 101, 108, 109, 110, 111, 112, 113, 118, 119, 120, 121, 122, 128, 134, 135, 179, 181, 204, 212, 219, 220, 273, 275, 277, 279, 284, 291, 292, 295, 296, 297, 298],
            pageTitle: 'NIH Data Book'
        };
    },
    watch: {
        // watch for route changes, this will happen
        // if user uses browser back/forward buttons
        $route () {
            if (!window.isMobile) {
                if (this.$route.params.categoryId || this.$route.params.page) {
                    if (!this.sidebarVisible && !this.sidebarUserToggled) {
                        this.showSidebar(true);
                    }
                }
            }

            if (this.$route.params.categoryId) {
                // if (!this.sidebarVisible && !this.sidebarUserToggled) {
                //     this.showSidebar(true);
                // }

                this.$nextTick(() => {
                    this.$refs.dashboard.setReportIds(this.$route.params.categoryId);
							
					gtag('event','View', {
						'event_category': 'Report Category',
						'event_label': 'Category ' + this.$route.params.categoryId,
						'value': 'Category ' + this.$route.params.categoryId
					  });			
                });
            } else if (this.$route.params.id) {
                if (this.sidebarVisible && !this.sidebarUserToggled) {
                    this.showSidebar(false);
                }

				gtag('event','View', {
					'event_category': 'Report',
					'event_label': 'Report ' + this.$route.params.id,
					'value': 'Report ' + this.$route.params.id
				});			
            }

            this.checkIsActualPageURL();            
        }
    },
    methods: {
        testLocation () {
            window.isWin = /^win/.test(window.navigator.platform.toLowerCase());

            if (window.location.pathname && window.location.pathname.toLowerCase().indexOf('/' + window.siteName + '/') === 0) {
                window.baseURL = '/' + window.siteName;
            } else if (!!this.$route.params.subsite) {
                window.baseURL = '/' + this.$route.params.subsite;
            } else {
                window.baseURL = '';
            }

            if (this.proxy) {
                window.baseURL = this.proxyServer + window.baseURL;
            }

            window.parentURL = window.location.origin;
        },
        mobileCheck (options) {
            window.mobileCheck();

            this.isMobile = window.isMobile;
            this.isLargeScreen = window.isLargeScreen;

            if (options && options.firstLoad) {
                this.$nextTick(() => {
                    if (window.isMobile) {
                        this.showSidebar(false);
                    } else if (this.$route.params.categoryId || this.$route.params.page) {
                        this.showSidebar(true);
                    }
                });
            }
        },
        getURLSearchParameters () {
            let search = window.location.search.substring(1);

            return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function (key, value) {
                return key === '' ? value:decodeURIComponent(value);
            });
        },
        getReportRedirectId (id) {
            if (this.retiredReports.indexOf(Number(id)) !== -1) {
				
				gtag('event','Redirect', {
					'event_category': 'Report Retired',
					'event_label': id,
					'value': id
				});		

                document.title = this.pageTitle;
                this.$router.push({
                    path: '/page/retired-report'
                }).catch(()=>{});

                return false;
            }

            if (this.reportRedirects[id]) {
				gtag('event','Redirect', {
					'event_category': 'Report Redirect',
					'event_label':  id + ' -> ' + this.reportRedirects[id],
					'value':  id + ' -> ' + this.reportRedirects[id]
				});	

                return this.reportRedirects[id];
            }

            return id;
        },
        checkIsActualPageURL () {
            // console.log('checkIsActualPageURL');
            // console.log(this.$route.query.catid);

            if (this.$route.params.oldAspxPage) {
                let params = this.getURLSearchParameters(),
                    redirectId;

                if (params.chartId) {
                    redirectId = this.getReportRedirectId(params.chartId);

                    if (redirectId) {
                        this.loadReport(redirectId);
                    }
                }
            } else if (this.$route.params.id && this.$route.params.id !== 'undefined') {
                let redirectId = this.getReportRedirectId(this.$route.params.id);

                // if (redirectId && this.$route.params.id !== redirectId) {
                if (redirectId && this.$route.params.id !== redirectId) {
                    this.loadReport(redirectId);
                } else if (this.$route.params.id) {
                    this.loadReport(this.$route.params.id);
                }
            } else if (this.$route.params.page) {
                let pages = [
                    'historical-data-books',
                    'faq',
                    'field-classification',
                    'report-index',
                    'retired-report',
                    'report-not-found'
                ];

                // redirect renamed pages to correct URL
                if (this.$route.params.page.toLowerCase() === 'glossary') {
                    this.$router.push({
                        path: '/page/report-index'
                    }).catch(()=>{});
                }

                if (pages.indexOf(this.$route.params.page) === -1) {
                    this.$router.push({
                        path: '/page/report-not-found'
                    }).catch(()=>{});
                }
            }
        },
        loadReport (id) {
            let queryStr = {};

            // don't load report if it is already active
            // if (Number(this.$route.params.id) !== Number(id)) {
            this.noData = false;
            if (this.activeReport !== id) {
                // push category ID to router for use of
                // browser back/forward buttons
                if (this.$route.query) {
                    queryStr = this.$route.query;
                }
                this.$router.push({
                    path: '/report/' + id,  query: queryStr
                }).catch(()=>{});

                let $this = this;
                setTimeout(() => {
                    $this.mobileCheck({
                        firstLoad: true
                    });
                }, 300);

                this.activeReport = id;
            }
        },
        showSidebar (show) {
            this.sidebarVisible = show;
            this.sidebarAnimating = true;

            setTimeout(() => {
                this.sidebarAnimating = false;
                this.mobileCheck();
            }, 301);
        },
        scrollToTop () {
            $('html, body').animate({
                scrollTop: 0
            }, 1);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .callout-box {
        margin: 0 0 25px;
        padding: 8px 10px 8px;
        background-color: #f5fff5;
        /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15); */
        border-radius: 4px;

        h2 {
            // margin-bottom: 0.1em;
            font-size: 18px;
            color: #378037;
            line-height: 1.2em;
        }

        a {
            // color: #000;
            // color: #1a5e1a;
            // font-weight: 700;
            // text-decoration: underline;
        }

        .link-text {
            color: #212529;
        }

        .icon-exit-link {
            position: relative;
            top: -2px;
            margin-left: 4px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
        }

        .new-reports-list {
            a {
                display: block;
                text-decoration: none;
                padding: 0.25em 0.5em;
                margin-left: -0.5em;
                margin-top: 0.75em;
                margin-bottom: 0.75em;
                width: calc(100% - 0.5em);
                // font-size: 13px;
                font-weight: 500;
                line-height: 1.2em;
                background-color: #fff;
                color: #555;
                border-radius: 4px;
                // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
                box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
                transition: 0.3s;
                // border-radius: 5px;
                // border-left: 3px solid $green-light;
                &:hover,
                &:focus {
                    // background-color: $blue-lightest;
                    color: $green;
                    text-decoration: none;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
                }
            }
        }


        // .feedback-link {
        //     color: inherit;
        //     text-decoration: underline;
        // }
    }

</style>
