import {createStore} from 'vuex';

export default createStore({
    strict: true,
    state: {
        appVersion: '1.0',

        // layout
        layout: null,
        categoryList: null,
        globalFilters: '',
        LRPFilters: [],
        reportFilters: [],
        LRPReportData: [],
        LRPLookupURL: [
            {url: 'getlrpfilteroption', order: 1, name: 'LRP', isExtramural: true},
            {url: 'getawardfilteroption', order: 2, name: 'Award', isExtramural: 'both'},
            {url: 'getextramuralfyfilterOption', order: 3, name: 'fy', isExtramural: true},
            {url: 'getintramuralfyfilterOption', order: 4, name: 'eFy', isExtramural: false}
        ],
        chartSelection: []
    },
    getters: {
        categoryList: state => state.categoryList,
        globalFilters: state => state.globalFilters,
        LRPFilters: state => state.LRPFilters,
        reportFilters: state => state.reportFilters,
        LRPReportData: state => state.LRPReportData,
        LRPLookupURL: state => state.LRPLookupURL,
        chartSelection: state => state.chartSelection
    },
    mutations: {
        // category list
        setCategoryList (state, payload) {
            state.categoryList = payload;

            localStorage.setItem('categoryList', JSON.stringify(payload));
        },
        setGlobalFilters (state, payload) {
            state.globalFilters = payload;
        },
        setReportFilters (state, payload) {
            let objIndex = state.reportFilters.findIndex((obj => obj.reportId === payload.reportId));
            if (objIndex !== -1) {
                state.reportFilters[objIndex].filters = payload.filters;
            } else {
                state.reportFilters.push({
                    reportId: payload["reportId"],
                    filters: payload.filters
                });
            }
        },
        setLRPFilterOptions (state, payload) {
            if (!state.LRPFilters) {
                state.LRPFilters = [];
            }
            if (payload.filterName === 'fy') {
                state.LRPFilters.push({
                    'isExtramural': payload.isExtramural,
                    'name': payload.filterName,
                    'order': payload.order,
                    'isGlobal': true,
                    'data': [{
                        "name": "sFy",
                        "displayLabel": "From",
                        'options': payload.data
                    },
                    {
                        "name": "eFy",
                        "displayLabel": "To",
                        'options': payload.data
                    }
                ]
                });
            } else {
                let payloadData = payload.data;
                if (payload.data.length > 0 && typeof payload.data[0] != 'object') {
                    payloadData = payload.data.map(d => {
                        return {id: d, name: d, shortName: d}
                    });
                }
                state.LRPFilters.push({
                    'isExtramural': payload.isExtramural === 'both' ? true : payload.isExtramural,
                    'name': payload.filterName,
                    'order': payload.order,
                    'options': payloadData,
                    'isGlobal': true
                });
                if (payload.isExtramural === 'both') {
                    state.LRPFilters.push({
                        'isExtramural': false,
                        'name': payload.filterName,
                        'order': 50,
                        'options': payloadData,
                        'isGlobal': false
                    });
                }
            }
            state.LRPFilters.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
        },
        setLRPReportData (state, payload) {
            let curIndex = state.LRPReportData.findIndex((obj => obj.reportId === payload.reportId));
            if (curIndex !== -1) {
                state.LRPReportData[curIndex] = payload;    
            } else {
                state.LRPReportData.push(payload);
            }
        },
        updateLRPReportData (state, payload) {
            state.LRPReportData[payload.index] = payload.data;
        },
        setChartSelection (state, payload) {
            state.chartSelection[payload.reportId] = payload.data;
        }
    },
    actions: {
        // category list
        setCategoryList ({ commit }, payload) {
            commit('setCategoryList', payload);
        },
        setGlobalFilters ({ commit }, payload) {
            commit('setGlobalFilters', payload);
        },
        setLRPFilterOptions ({ commit }, payload) {
            commit('setLRPFilterOptions', payload);
        },
        setReportFilters ({ commit }, payload) {
            commit('setReportFilters', payload);
        },
        setLRPReportData ({ commit }, payload) {
            commit('setLRPReportData', payload);
        },
        updateLRPReportData ({ commit }, payload) {
            commit('updateLRPReportData', payload);
        },
        setChartSelection ({ commit }, payload) {
            commit('setChartSelection', payload);
        }
    }
});
