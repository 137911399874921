<template>
    <div id="app" class="appClass" :class="{'chart-only': chartOnly}" v-if="catList">
        <router-view/>
        <div id="export-div" class="sr-only"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
    name: 'App',
    data () {
        return {
            chartOnly: this.$route.query.chartOnly,
            catList: null
        };
    },
    async mounted () {
        if ((document.location.search && document.location.search.indexOf("chartOnly") !== -1 )) {
            $(".appClass").addClass("chart-only");
        }
        await this.getCategoryData();
    },
    methods: {
        ...mapActions([
            'setCategoryList'
        ]),
        async getCategoryData () {
            var handleData,
                url = window.serverless ? '/static/data/category.json' : (window.location.hostname === 'localhost' ?  'https://localhost:59971' :  window.location.origin +  '/reportweb') + '/api/category/get';

            /*--------------------------------------------------------------
            * Handle user service call and other calls in init services
            *-------------------------------------------------------------*/
            handleData = (data) => {
                this.catList = data;
                this.setCategoryList(data);
            };

            await axios.get(url)
                .then(response => {
                  handleData(response.data);
                });
        }
    }
};
</script>

<style lang="scss">
@import 'scss/main';
body {
    background-color:  #fff;
}
</style>
