<div class="widget" :id="'report-' + reportId" v-if="data"
  :class="(data && data.inProgress) || !hasChart ? 'in-progress' : ''">
  <div class="widget-header" v-if="!chartOnly">
    <h2 class="widget-title">{{title}}</h2>
    <!-- <a :href="'report/' + reportId" target="_blank" class="widget-button widget-accent-button widget-button-popout" v-if="hasChart" title="Popout"> -->
    <a :href="'report/' + reportId" target="_blank" class="widget-button widget-accent-button widget-button-popout"
      title="Popout" @click.prevent="expandReport()">
      <span class="sr-only">Expand</span>
      <i class="fas fa-external-link-alt"></i>
    </a>
    <!-- <div v-if="!data || !data.data || data.inProgress" class="widget-in-progress">(Development in Progress)</div> -->
    <div class="report-id" v-if="!miniMode" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true"
      v-bind:title="'<b>Report Categor' + (currentReportCategories.length === 1 ? 'y' : 'ies') + ':</b><br><ul>' + currentReportCategories.join('') + '</ul>'">
      NIH {{reportTypeName}} Report ID: {{reportId}}</div>
    <div class="btn-group btn-group-share" v-if="!miniMode">
      <button class=" button-with-svg widget-button widget-accent-button widget-button-share ms-2"
        :class="shareText === shareTextCopied ? 'highlight' : ''" type="button" :id="'dropdownMenuShare' + reportId"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="btn-text pe-2">{{shareText}}</span>
        <i class="fas fa-link widget-button-icon"></i>

      </button>
      <div class="dropdown-menu share-options-panel" :aria-labelledby="'dropdownMenuShare' + reportId">
        <a class="dropdown-item"
          :href="'mailto:?subject=NIH%20' + encodeURIComponent(reportTypeName) + '%20-%20' + encodeURIComponent(title) + '&body=NIH%20' + encodeURIComponent(reportTypeName) + '%0A' + encodeURIComponent(title) + '%0A' + getReportURL()"
          @click="shareReportEmail()">Email</a>
        <button class="dropdown-item" href @click="copyReportLink()">Copy Link</button>
      </div>
    </div>
    <div class="widget-menu" v-if="!miniMode">
      <div class="widget-buttons row">
        <div class="col my-1 ps-0">
          <LRP-filters v-if="LRPReport" :generalFilters="false" :isExtramuralCategory="isExtramuralCategory" :reportId="reportId" :availableFilters="availableFilters"></LRP-filters>

          <div class="btn-group widget-button-dataset secondary-dateset my-1" v-if="activeDataSetSecondary">
            <button class="button-with-svg widget-button widget-button-export" type="button"
              :id="'dropdownMenuExportSecondary' + reportId" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              {{activeDataSetSecondary}}
              <i class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
            </button>
            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuExport' + reportId">
              <a v-for="(dataSet, index) in data.dataSetsSecondary" :key="reportId + 'dataset-' + index"
                class="dropdown-item" :class="dataSet === activeDataSetSecondary ? 'active' : ''" href
                @click.prevent="setDataSet(dataSet, 'secondary')">{{dataSet}}</a>
            </div>
          </div>
          <div class="btn-group widget-button-dataset my-1" v-if="activeDataSet">
            <button class="button-with-svg widget-button widget-button-export" type="button"
              :id="'dropdownMenuExport' + reportId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{activeDataSet}}
              <i class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
            </button>
            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuExport' + reportId">
              <a v-for="(dataSet, index) in data.dataSets" :key="reportId + 'dataset-' + index" class="dropdown-item"
                :class="dataSet === activeDataSet ? 'active' : ''" href
                @click.prevent="setDataSet(dataSet, 'primary')">{{dataSet}}</a>
            </div>
          </div>
        </div>
        <div class="col-auto my-1 pe-0">
          <button class="widget-button my-1" title="Data Table" data-bs-toggle="modal"
            :data-bs-target="'#report-' + this.reportId + '-modal'" v-if="!$route.params.id && hasChart && data.defaultView != 'Table' && !noData"
            @click="clickDataView()" :id="'btnDataTable' + this.reportId">
            <span class="btn-text pe-2">Data</span>
            <i class="fas fa-table widget-button-icon"></i>
          </button>
          <button class="widget-button my-1" title="Data Table" v-if="$route.params.id && hasChart && !alwaysShowTable"
            @click.prevent="toggleViewMode()">
            {{viewModePrev}}
            <span v-if="viewModePrev === 'data'">
              <i class="fas fa-table widget-button-icon"></i>
            </span>
            <span v-if="viewModePrev === 'visualization'">
              <i class="fas fa-signal widget-button-icon"></i>
            </span>
          </button>
          
          <button class="widget-button my-1" title="Chart" data-bs-toggle="modal"
            :data-bs-target="'#report-' + this.reportId + '-modal'" v-if="!$route.params.id && data.defaultView == 'Table' && !noData"
            @click="clickChartView()" :id="'btnDataTable' + this.reportId">
            <span class="btn-text pe-2">Chart</span>
            <i class="fas fa-chart-bar widget-button-icon"></i>
          </button>

          <div class="btn-group my-1">
            <button class="button-with-svg widget-button widget-button-export ms-2" type="button"
              :id="'dropdownMenuExport' + reportId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              v-if="!noData">
              <span class="btn-text pe-1">Export</span>
              <i class="fas fa-download widget-button-icon ps-1"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right widget-level"
              :aria-labelledby="'dropdownMenuExport' + reportId">
              <a class="dropdown-item" href @click.prevent="exportChart()" v-if="exportableChart">Export Chart as Image</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="noData" class="noData">
    
    <div>Current filter selection <template v-if="activeDataSet && activeDataSetSecondary">of <span>{{activeDataSetSecondary}}</span> and <span>{{activeDataSet}}</span></template> does not
      have any data.</div>
    <div>Please try another combination.</div>
  </div>
  <div class="report-help-text" v-if="data.helpText && !miniMode">{{data.helpText}}</div>

  <template v-if="!LRPReport">
    <div class="chart-wrapper" :id="'report-' + this.reportId + '-chart-wrapper'" v-show="viewMode === 'visualization'"
      v-if="!noData">
      <button v-if="showResetButton" class="widget-button chart-reset-btn" @click.prevent="resetChart()">Reset</button>
      <chart v-if="hasChart" :chartOptions="chartOptions" :chartAxisLabels="chartAxisLabels"
        :sidebarVisible="$parent.$parent.sidebarVisible" :miniMode="miniMode" ref="chart" />
    </div>
  </template>
  <template v-else>
   <div class="chart-container" v-if="data.tabs && ( ($route.path.indexOf('category') != -1 && data.defaultView != 'Table') ||$route.path.indexOf('report') != -1)">
    <template v-if="data.tabs.length > 1">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation" v-for="(tab, i) in data.tabs">
              <button class="nav-link" data-bs-toggle="tab" :data-bs-target="'#tab'+reportId+i" role="tab" aria-selected="false" :class="i+1 == globalFilters['chartBy']  || (!globalFilters['chartBy'] && i ==0) ? 'active' : ''" @click="tabChanged(i)">{{tab.title}}</button>
            </li>
        </ul>
        <div class="tab-content p-2 border border-top-0 rounded" style="min-height: 300px;">
          <div  v-for="(tab, i) in data.tabs" :data-tabId="i" :id="'tab'+reportId+i" class="tab-pane fade" :class=" i+1 == globalFilters['chartBy'] || (!globalFilters['chartBy'] && i ==0)  ? 'active show' : ''">
            <LRP-Chart :tabdata="tab" :id="'-' + reportId + '-' + i" :reportId="reportId"/>
          </div>
        </div>
      </template>
      <div v-else-if="data.tabs.length == 1">
        <div class="tab-pane active p-2" data-tabId="0"  v-if="!noData">
          <LRP-Chart :tabdata="data.tabs[0]" :id="'-' + reportId + '-0'" :reportId="reportId"/>
        </div>
      </div>
  </div>
  </template>

  <div class="modal fade" :id="'report-' + this.reportId + '-modal'" tabindex="-1" role="dialog"
    :aria-labelledby="'report-' + this.reportId + '-modal-label'" aria-hidden="true"
    v-if="!$route.params.id && !miniMode && !chartOnly">
  <div class="modal-dialog" role="document"
      :style="data.defaultView == 'Table' ? 'width:350px' : tableData && !!tableData[0] ? 'width:' + (tableData[0].length * 150) + 'px;' : ''">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" :id="'report-' + this.reportId + '-modal-label'">{{title}}</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" id="report-modal">
          <div class="widget-buttons row">
            <div class="col my-1 ps-0">
              <LRP-filters  v-if="LRPReport" :generalFilters="false" :isExtramuralCategory="isExtramuralCategory" :reportId="reportId" :availableFilters="availableFilters"></LRP-filters>
              
              <div class="btn-group widget-button-dataset secondary-dateset my-1" v-if="activeDataSetSecondary">
                <button class="button-with-svg widget-button widget-button-export" type="button"
                  :id="'dropdownMenuExportSecondary' + reportId" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false" :disabled="!hasChart">
                  {{activeDataSetSecondary}}
                  <i class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
                </button>
                <div class="dropdown-menu" :aria-labelledby="'dropdownMenuExport' + reportId">
                  <a v-for="(dataSet, index) in data.dataSetsSecondary" :key="reportId + 'dataset-modal-' + index"
                    class="dropdown-item" :class="dataSet === activeDataSetSecondary ? 'active' : ''" href
                    @click.prevent="setDataSet(dataSet, 'secondary')">{{dataSet}}</a>
                </div>
              </div>

              <div class="btn-group widget-button-dataset my-1" v-if="activeDataSet">
                <button class="button-with-svg widget-button widget-button-export" type="button"
                  :id="'dropdownMenuExport' + reportId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  :disabled="!hasChart">
                  {{activeDataSet}}
                  <i class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
                </button>
                <div class="dropdown-menu" :aria-labelledby="'dropdownMenuExport' + reportId">
                  <a v-for="(dataSet, index) in data.dataSets" :key="reportId + 'dataset-modal-' + index"
                    class="dropdown-item" :class="dataSet === activeDataSet ? 'active' : ''" href
                    @click.prevent="setDataSet(dataSet, 'primary')">{{dataSet}}</a>
                </div>
              </div>
            </div>
          <div class="col-auto pe-0 my-1">
              <div class="btn-group float-end my-1">
                <button class="button-with-svg widget-button widget-button-export ms-2" type="button"
                  :id="'dropdownMenuExport-modal' + reportId" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <span class="btn-text pe-1">Export</span>
                  <i class="fas fa-download widget-button-icon ps-1"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownMenuExport-modal' + reportId">
                  <a class="dropdown-item" href @click.prevent="exportChart()" v-if="exportableChart">Export Chart as Image</a>
                </div>
              </div>
          </div> 

            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button> -->
          </div>

          <div class="chart-container" v-if="data.tabs && data.defaultView !== 'Chart'">
            <template v-if="data.tabs.length > 1">
              <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item" role="presentation" v-for="(tab, i) in data.tabs">
                    <button class="nav-link" data-bs-toggle="tab" :data-bs-target="'#tab'+reportId+i" role="tab" aria-selected="false" :class="i+1 == globalFilters['chartBy']  || (!globalFilters['chartBy'] && i ==0) ? 'active' : ''" @click="tabChanged(i)">{{tab.title}}</button>
                  </li>
              </ul>
              <div class="tab-content p-2 border border-top-0 rounded" style="min-height: 300px;">
                <div  v-for="(tab, i) in data.tabs" :data-tabId="i" :id="'tab'+reportId+i" class="tab-pane fade" :class=" i+1 == globalFilters['chartBy'] || (!globalFilters['chartBy'] && i ==0)  ? 'active show' : ''">
                  <LRP-Chart :tabdata="tab" :id="'-' + reportId + '-' + i" :reportId="reportId" :isResize="true"/>
                </div>
              </div>
            </template>
            <div v-else-if="data.tabs.length == 1">
              <div class="tab-pane active p-2" data-tabId="0"  v-if="!noData">
                <LRP-Chart :tabdata="data.tabs[0]" :id="'-' + reportId + '-0'" :reportId="reportId" :isResize="true" ref="lrpChart"/>
              </div>
            </div>
          </div>

          <div class="report-help-text report-data-help-text" v-if="data.dataHelpText">{{data.dataHelpText}}</div>
          <data-table v-if="!LRPReport && tableData && tableData.data && hasChart" :tableData="tableData" :exportData="exportData"
            :activeDataSet="activeDataSet" :activeDataSetSecondary="activeDataSetSecondary" ref="dataTable">
          </data-table>
          <div :id="'report-' + reportId + '-main'"  v-else-if="LRPReport && dataItems && hasChart" class="overflow-auto">
            <b-table striped hover :items="dataItems" :fields="tableFields" v-if="data" :busy="loadWidgetData"
                  stacked="sm"
                  class="bg-white mt-1"
                  show-empty
              >
                  <template #table-busy>
                      <div class="text-center  my-2">
                          <b-spinner class="align-middle"></b-spinner>
                      </div>
                  </template>
                  <template v-slot:thead-top slot-scope="data" v-if="headerRowFields && headerRowFields.length > 0">
                    <tr class="top-table-headers">
                      <template v-for="(cols, index) in headerRowFields">
                        <th v-if="cols && index == 0"><span class="sr-only">Data</span></th>
                        <b-th :colspan="cols.colSpan" class="text-center" v-if="cols">{{cols.label}}</b-th>
                      </template>
                    </tr>
                  </template>
                  <template #cell()="data">
                    <span v-html="data.value"></span>
                  </template>
                  <template v-slot:custom-foot v-if="tableFooterFields && tableFooterFields.length > 0 && footerItems.length > 0">
                    <tr>
                      <template v-for="(cols, index) in tableFooterFields">
                        <b-td :colspan="cols.colSpan" :class="cols.class"  v-if="cols">{{footerItems[0][cols.key]}}</b-td>
                      </template>
                    </tr>
                  </template>
              </b-table>
              
      <div :id="'report-' + reportId + '-data'" class="d-none">
        <b-table striped hover :items="exportDataItems" :fields="tableFields"
              stacked="sm"
              class="bg-white mt-1"
              show-empty
              :busy="loadWidgetData"
      >
          <template #table-busy>
              <div class="text-center  my-2">
                  <b-spinner class="align-middle"></b-spinner>
              </div>
          </template>
          <template v-slot:thead-top slot-scope="data" v-if="headerRowFields && headerRowFields.length > 0">
            <tr class="top-table-headers">
              <template v-for="(cols, index) in headerRowFields">
                <th v-if="cols && index == 0"><span class="sr-only">Data</span></th>
                <b-th :colspan="cols.colSpan" class="text-center" v-if="cols">{{cols.label}}</b-th>
              </template>
            </tr>
          </template>
          <template #cell()="data">
            <span v-html="data.value"></span>
          </template>
          <template v-slot:custom-foot v-if="tableFooterFields && tableFooterFields.length > 0 && exportFooterItems.length > 0">
            <tr>
              <template v-for="(cols, index) in tableFooterFields">
                <b-td :colspan="cols.colSpan" :class="cols.class" v-if="cols">{{exportFooterItems[0][cols.key]}}</b-td>
              </template>
            </tr>
          </template>
          
      </b-table>
      </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="report-help-text report-data-help-text"
    v-if="data.dataHelpText && (tableData && tableData.data) && ($route.params.id || !hasChart) && !chartOnly"
    v-show="viewMode === 'data' || !hasChart || alwaysShowTable">{{data.dataHelpText}}</div>

    <template v-if="!LRPReport">
      <data-table
        v-if="(tableData && tableData.data) && ($route.params.id || !hasChart) && !miniMode && !noData && !chartOnly"
        v-show="viewMode === 'data' || !hasChart || alwaysShowTable" :tableData="tableData" :exportData="exportData"
        :activeDataSet="activeDataSet" :activeDataSetSecondary="activeDataSetSecondary" ref="dataTable"></data-table>
    </template>
    <div :id="'report-' + reportId + '-main'" style="overflow:auto;"  v-else>      
    <div class="mt-3" v-if="dataItems && ($route.params.id || !hasChart) && !miniMode && !noData && !chartOnly"
    v-show="viewMode === 'data' || !hasChart || alwaysShowTable">
      <b-table striped hover :items="dataItems" :fields="tableFields"
              stacked="sm"
              class="bg-white mt-1"
              show-empty
              :busy="loadWidgetData"
      >
          <template #table-busy>
              <div class="text-center  my-2">
                  <b-spinner class="align-middle"></b-spinner>
              </div>
          </template>
          <template v-slot:thead-top slot-scope="data" v-if="headerRowFields && headerRowFields.length > 0">
            <tr class="top-table-headers">
              <template v-for="(cols, index) in headerRowFields">
                <th v-if="cols && index == 0"><span class="sr-only">Data</span></th>
                <b-th :colspan="cols.colSpan" class="text-center" v-if="cols">{{cols.label}}</b-th>
              </template>
            </tr>
          </template>
          <template #cell()="data">
            <span v-html="data.value"></span>
          </template>
          <template v-slot:custom-foot v-if="tableFooterFields && tableFooterFields.length > 0 && footerItems.length > 0">
            <tr>
              <template v-for="(cols, index) in tableFooterFields">
                <b-td :colspan="cols.colSpan" :class="cols.class" v-if="cols">{{footerItems[0][cols.key]}}</b-td>
              </template>
            </tr>
          </template>
          
      </b-table>
      <div :id="'report-' + reportId + '-data'"  class="d-none">
        <b-table striped hover :items="exportDataItems" :fields="tableFields"
              stacked="sm"
              class="bg-white mt-1"
              show-empty
              :busy="loadWidgetData"
      >
          <template #table-busy>
              <div class="text-center  my-2">
                  <b-spinner class="align-middle"></b-spinner>
              </div>
          </template>
          <template v-slot:thead-top slot-scope="data" v-if="headerRowFields && headerRowFields.length > 0">
            <tr class="top-table-headers">
              <template v-for="(cols, index) in headerRowFields">
                <th v-if="cols && index == 0"><span class="sr-only">Data</span></th>
                <b-th :colspan="cols.colSpan" class="text-center" v-if="cols">{{cols.label}}</b-th>
              </template>
            </tr>
          </template>
          <template #cell()="data">
            <span v-html="data.value"></span>
          </template>
          <template v-slot:custom-foot v-if="tableFooterFields && tableFooterFields.length > 0 && exportFooterItems.length > 0">
            <tr>
              <template v-for="(cols, index) in tableFooterFields">
                <b-td :colspan="cols.colSpan" :class="cols.class" v-if="cols">{{exportFooterItems[0][cols.key]}}</b-td>
              </template>
            </tr>
          </template>
          
      </b-table>
      </div>
    </div>
    </div>

  <footnotes ref="footnotes" v-if="!miniMode && !chartOnly" :reportFootNotes="footnotes" />
  <div class="last-updated" v-if="lastUpdated && !miniMode && !chartOnly">Last Updated: {{lastUpdated}}</div>
</div>