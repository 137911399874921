<template>
    <div id="about-lrp" class="p-3">
        <h3>Welcome to the NIH Extramural Loan Repayment Programs Dashboard</h3>

        <div class="mt-3">
            This dashboard is designed to provide easier access and more flexible trend display information using LRP data. As information is compiled, more data will be added from both past and future fiscal years.
            <div class="mt-2">
            Dashboard display and performance is optimized with the latest version of Chrome.</div>
        </div>

        <h4 class="mt-3">Definitions</h4>
        <table class="table table-striped">
            <thead class="table-secondary">
                <tr>
                    <th class="py-2 bg-secondary text-white">Term</th>
                    <th class="py-2 bg-secondary text-white">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ICs</td>
                    <td>NIH Institutes and Centers</td>
                </tr>
                <tr>
                    <td>LRP</td>
                    <td>Loan Repayment Programs</td>
                </tr>
                <tr>
                    <td>FY</td>
                    <td>Fiscal Year</td>
                </tr>
                <tr>
                    <td>New Award</td>
                    <td>First Award, which is a two-year contract</td>
                </tr>
                <tr>
                    <td>Renewal Award</td>
                    <td>Subsequent award, which may be a one- or two-year contract. Individual recipients can continue to apply for, and receive, competitive renewal awards until their entire qualified educational debt is repaid.</td>
                </tr>
                <tr>
                    <td>M</td>
                    <td>Mean</td>
                </tr>
                <tr>
                    <td>n</td>
                    <td>Number/Count</td>
                </tr>
                <tr>
                    <td>SD</td>
                    <td>Standard Deviation</td>
                </tr>
            </tbody>
        </table>

        <h4 class="mt-4">NIH Institutes</h4>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class=" bg-secondary text-white">Acronym</th>
                    <th class=" bg-secondary text-white">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>NCI</td>
                    <td>National Cancer Institute</td>
                </tr>
                <tr>
                    <td>NEI</td>
                    <td>National Eye Institute</td>
                </tr>
                <tr>
                    <td>NHLBI</td>
                    <td>National Heart, Lung, and Blood Institute</td>
                </tr>
                <tr>
                    <td>NHGRI</td>
                    <td>National Human Genome Research Institute</td>
                </tr>
                <tr>
                    <td>NIA</td>
                    <td>National Institute on Aging</td>
                </tr>
                <tr>
                    <td>IAAA</td>
                    <td>National Institute on Alcohol Abuse and Alcoholism</td>
                </tr>
                <tr>
                    <td>NIAID</td>
                    <td>National Institute of Allergy and Infectious Diseases</td>
                </tr>
                <tr>
                    <td>NIAMS</td>
                    <td>National Institute of Arthritis and Musculoskeletal and Skin Diseases</td>
                </tr>
                <tr>
                    <td>NIBIB</td>
                    <td>National Institute of Biomedical Imaging and Bioengineering</td>
                </tr>
                <tr>
                    <td>NICHD</td>
                    <td>Eunice Kennedy Shriver National Institute of Child Health and Human Development</td>
                </tr>
                <tr>
                    <td>NIDCD</td>
                    <td>National Institute on Deafness and Other Communication Disorders</td>
                </tr>
                <tr>
                    <td>NIDCR</td>
                    <td>National Institute of Dental and Craniofacial Research</td>
                </tr>
                <tr>
                    <td>NIDDK</td>
                    <td>National Institute of Diabetes and Digestive and Kidney Diseases</td>
                </tr>
                <tr>
                    <td>NIDA</td>
                    <td>National Institute on Drug Abuse</td>
                </tr>
                <tr>
                    <td>NIEHS</td>
                    <td>National Institute of Environmental Health Sciences</td>
                </tr>
                <tr>
                    <td>NIGMS</td>
                    <td>National Institute of General Medical Sciences</td>
                </tr>
                <tr>
                    <td>NIMH</td>
                    <td>National Institute of Mental Health</td>
                </tr>
                <tr>
                    <td>NIMHD</td>
                    <td>National Institute on Minority Health and Health Disparities</td>
                </tr>
                <tr>
                    <td>NINDS</td>
                    <td>National Institute of Neurological Disorders and Stroke</td>
                </tr>
                <tr>
                    <td>NINR</td>
                    <td>National Institute of Nursing Research</td>
                </tr>
                <tr>
                    <td>NLM</td>
                    <td>National Library of Medicine</td>
                </tr>
            </tbody>
        </table>
        
        <h4 class="mt-4">NIH Centers and Offices</h4>
        <table  class="table table-striped">
            <thead>
                <tr>
                    <th class=" bg-secondary text-white">Acronym</th>
                    <th class=" bg-secondary text-white">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>FIC</td>
                    <td>Fogarty International Center</td>
                </tr>
                <tr>
                    <td>NCATS</td>
                    <td>National Center for Advancing Translational Sciences (established 2011)</td>
                </tr>
                <tr>
                    <td>NCCIH</td>
                    <td>National Center for Complementary and Integrative Health (formerly NCCAM = National Center for Complementary and Comparative Medicine)</td>
                </tr>
                <tr>
                    <td>NCRR</td>
                    <td>National Center for Research Resources (discontinued 2011)</td>
                </tr>
                <tr>
                    <td>OD</td>
                    <td>Office of the NIH Director</td>
                </tr>
            </tbody>
        </table>

        <h3 class="mt-4">Note</h3>
        <div class="mb-2">Not all NIH ICs participate in the LRP every FY.</div>

        <div class="mb-2">Dashboard numbers are the result of a consistent methodology established in FY 2017. Each FY's data is compiled on approximately December 1st, two months following the close of that FY.</div>
        <ul class="list-unstyled ps-5">
            <li>For example, FY 2017 ended on September 30th, 2017, and that data was compiled on December 1st, 2017 and added to the LRP dashboard later in the month.</li>
            <li>Data reports using other sources or methodologies may show slight discrepancies.</li>
        </ul>

        <div class="mb-2">The LRP was changed in FY 2020 as a result of the 21st Century Cures Act. Those changes include:</div>
        <ul class="list-unstyled ps-5">
            <li>The maximum allowable LRP award was increased from $35,000 to $50,000 per year (additional 39% federal tax payment did not change).</li>
            <li>All NIH ICs were allowed to provide funding under the Health Disparities Research LRP. Previously, only NIMHD was allowed to fund through this specific LRP.</li>
            <li>NIMHD remains the sole funding IC for the Clinical Research for Researchers from Disadvantaged Backgrounds LRP.</li>
            <li>The prohibition against changing LRPs for renewal awards, without starting again with a new award, was lifted.</li>
        </ul>
        <div class="mb-2">For policy reasons, the LRP filter that can show individual LRP data is not available in many widgets.</div>

        <div>In most cases, data counts less than eleven (11) are not displayed for policy reasons.</div>
    </div>
</template>

<script>
export default {
    name: 'aboutLRP'
};
</script>

<style lang="scss" scoped>
table th, table td {
    text-align: left;
}
table td:first-child {
    background-color: transparent;
}
</style>
