import { mapGetters, mapActions } from 'vuex';
import TableExport from 'tableexport';
import 'echarts/lib/component/tooltip';
import { isProxy, toRaw  } from 'vue';
import bus from '@/js/event-bus.js';

// import globalMixins from 'js/globalMixins';
export default {
    props: [
        // 'widgetPrefs',
        // 'widgetInfo',
        // 'widgetId'
    ],
    // mixins: [globalMixins],
    computed: {
        ...mapGetters([
            'categoryList',
            'LRPReportData'
        ])
    },
    data () {
        return {
            nameGapDefault: {
                x: 28,
                y: 1000,
                y2: 1000
            },
            formatTimer: null,
            isFormatted: false,
            isReformatted: false,
            axisLabelSize: {
                x: 0,
                y: 0,
                y2: 0
            },
            axisFormat: {
                x: null,
                y: null,
                y2: null
            },
            yFormat: null,
            showResetButton: false,
            tooltipName: null,
            tooltipSeriesId: null,
            tooltipdataIndex: null,
            exportTitleLines: 0,
            loadWidgetData: true,
            exportableChart: true
        };
    },
    mounted () {
    },
    methods: {
         ...mapActions([
             'setLRPReportData',
        //     'removeWidgetFromLayout',
        //     'setSelectedPortfolio',
        //     // 'setSelectedSavePortfolio',
        //     'setShowSavePortfolioModal'
        ]),
        initWidget () {
            this.loadWidgetData = true;

            this.getData();
        },

        doneLoading () {
            this.init = true;
        },

        getData () {
            let reportId = Number(this.reportId),
                objIndex = this.reportFilters.findIndex((obj => obj.reportId === this.reportId)),
                queryString = (objIndex >= 0 ? $.param(this.reportFilters[objIndex].filters) : $.param(this.globalFilters)),
                appURL = (window.location.hostname === 'localhost' && this.LRPReport ? 'https://localhost:59971' : (this.LRPReport ? window.location.origin +  '/reportweb': window.baseURL)),
                url =  this.LRPReport ? '/api/databook/GetWidgetsByFilter?ids=' +  this.reportId + (queryString ? '&' + queryString : '') : '/static/data/report-' + reportId + '.json';

            customAjax({
                service: {
                    name: 'report',
                    url: appURL + url,
                    type: 'get'
                },
                callback: this.handleData
            });
        },

        parse302Data (responseData) {
            // var subSet = [],
            //     groupByIcArr = [],
            //     groupByFYArr = [],
            //     groupByICs = [],
            //     prevIC = '';
            var rec,
                // tempArr = [],
                subRec,
                tempHeader;

            // //$.each(responseData.data.chartData, function (cnt, rec) {
            // for (let i = 0; i < 75; i++) {
            //     fullArr.push([i, 0, 0, 0]);
            // }
            for (let cnt = 0; cnt < responseData.data.chartData.length; cnt++) {
                rec = responseData.data.chartData[cnt];

                for (let i = 0; i < rec.length; i++) {
                    subRec = rec[i];
                    if (subRec.length > 0) {
                        tempHeader = subRec[0];
                        subRec.shift();
                        for (let j = 0; j <= 75; j++) {
                            if (!subRec[j] || j !== subRec[j][0]) {
                                subRec.splice(j, 0, [j, 0, 0, 0]);
                            }
                        }
                        subRec.splice(0, 0, tempHeader);
                    }
                }
                // if (rec.FY === '2018') {
                //     if (prevIC === '') {
                //         prevIC = rec.Admin_IC;
                //     }
                //     if (prevIC !== rec.Admin_IC && prevIC !== '') {
                //         groupByIcArr.push(copyData(groupByICs));
                //         groupByICs = [];
                //         prevIC = rec.Admin_IC;
                //     }
                //     subSet = [];
                //     if (groupByICs.length === 0) {
                //         // subSet.push("Year");
                //         // subSet.push("Institute");
                //         subSet.push("Percentile");
                //         subSet.push("R56 Applications");
                //         subSet.push("Awarded Applications");
                //         subSet.push("Unawarded Applications");
                //         groupByICs.push(subSet);
                //         subSet = [];
                //     }
                //     // subSet.push(parseInt(rec.FY));
                //     // subSet.push((rec.Admin_IC));
                //     subSet.push(parseInt(rec.IRG_Percentile));
                //     subSet.push(parseInt(rec.number_R56_awarded));
                //     subSet.push(parseInt(rec.number_R01e_awarded));
                //     subSet.push(parseInt(rec.number_not_awarded));
                //     groupByICs.push(subSet);
                // }
                // });
            }
            // groupByIcArr.push(copyData(groupByICs));
            // groupByFYArr.push(copyData(groupByIcArr));
            $('#single-report-view').append('<textarea id="dummyTextArea" rows="135" cols="100">');
            setTimeout(() => {
                $('#dummyTextArea').html(JSON.stringify(responseData.data.chartData));
            }, 101);
        },

        setDataSet (dataSet, setType) {
            this.unSelectedLegends = [];
            if (setType === 'primary') {
                this.activeDataSet = dataSet;
                if (this.data.allData.chartData) {
                    this.data.data = copyData(this.data.allData.chartData[this.data.dataSets.indexOf(dataSet)]);
                    if (this.data.allData.tableData) {
                        this.tableData.data = copyData(this.data.allData.tableData[this.data.dataSets.indexOf(dataSet)]);
                    } else {
                        this.tableData = copyData(this.data);
                    } 
                } else {
                    this.data.data = copyData(this.data.allData[this.data.dataSets.indexOf(dataSet)]);
                    this.tableData = copyData(this.data);
                }
            } else {
                this.activeDataSetSecondary = dataSet;
            }

            if (this.data.dataSetsSecondary) {
                this.data.data = copyData(this.data.allData.chartData[this.data.dataSets.indexOf(this.activeDataSet)][this.data.dataSetsSecondary.indexOf(this.activeDataSetSecondary)]);
                this.tableData.data = copyData(this.data.allData.tableData[this.data.dataSets.indexOf(this.activeDataSet)][this.data.dataSetsSecondary.indexOf(this.activeDataSetSecondary)]);
                if (this.$route.path.indexOf('/category/') === -1) {
                    var qStr = {};

                    qStr[this.data.dataSetSecondaryLabel] = this.activeDataSetSecondary;
                    qStr[this.data.dataSetLabel] = this.activeDataSet;
                    
                    this.$router.push({
                        path: '/report/' + this.reportId, query:  qStr
                    }).catch(() => { });
                }
                // //this.tableData = copyData(this.data);
            }

            if (this.tableData && this.tableData.data && this.tableData.data.exportData) {
                this.exportData = copyData(this.tableData);
                this.exportData.data = copyData(this.tableData.data.exportData);
            } else {
                this.exportData = copyData(this.tableData);
            }
            // if (this.data.allColumnFormats) {
            //     this.data.columnFormat = copyData(this.data.allColumnFormats[this.data.dataSets.indexOf(dataSet)]);

            //     if (this.tableData) {
            //         this.tableData.columnFormat = copyData(this.data.allColumnFormats[this.data.dataSets.indexOf(dataSet)]);
            //     }
            // }

            if (this.data.data.length > 0 || (this.data.data.tableData && this.data.data.tableData.length > 0)) {
                if (this.hasChart) {
                    if (this.noData) {
                        this.setDefaultGraphOptions();
                    }
                    this.noData = false;
                    this.$nextTick(() => {
                        this.resetFormatter();
                        this.$nextTick(() => {
                            // this.$refs.chart.buildChart(this.$refs.chart.chartId);
                            this.$refs.chart.destroyChart();
                            this.$nextTick(() => {
                                // setTimeout(() => {
                                this.$refs.chart.initChart(this.$refs.chart.chartId);
                                // }, 4000);
                            });
                        });
                    });
                }
                this.$nextTick(() => {
                    this.initExportTable();
                });
            } else {
                this.noData = true;
            }
        },

        handleData (responseData) {
            var self = this;
            this.reportTypeName =  !this.LRPReport ? 'Data Book' : 'LRP';
            if (responseData) {
                if (this.LRPReport && responseData.length > 0) {
                    let footerRow = [];
                    this.dataItems = [];
                    this.exportDataItems = [];
                    this.footerItems = [];
                    this.exportFooterItems = [];
                    this.headerRowFields = [];
                    this.$parent.pageLoader = false;
                    this.data = responseData[0];
                    this.widgets = responseData[0];
                    this.noData = false;
                    this.hasChart = true;
                    this.exportableChart = true;
                    this.title = this.data.reportName;
                    this.footnotes = this.data.footnotes;
                    this.widgetData = responseData[0].data;
                    this.availableFilters = this.data.availableFilter;
                    let widgetKeys = this.data.dataTable && this.data.dataTable.length > 0 ? this.data.dataTable[0].Keys : [];
                    this.tableFields = [];
                    this.tableFooterFields = [];
                    if (this.data.tabs && this.data.tabs.length == 0) {
                        this.hasChart = false;
                        this.exportableChart = false;
                    } 
                    else if (this.data.tabs && this.data.tabs.length == 0 || (this.data.tabs && this.data.tabs.length > 0 && this.data.tabs[0].charts && this.data.tabs[0].charts[0].chartType === "Card")) {
                        this.exportableChart = false;
                    } 
                    else if (this.$route.path.indexOf('/category/') != -1 && this.data.defaultView && this.data.defaultView == 'Table') {   
                        this.hasChart = false;
                    }
                    
                    let flatData = true;

                    if (this.data && this.data.dataTable && this.data.data.length > 0) {
                        this.headerRowFields = widgetKeys.map(function(value) {
                            if (value.Type == 'header-row') {
                                return {key: '', label:value.Display, colSpan: value.format.replace('cols',''), format:value.format};
                            }
                        });
                        let cssClass = "";
                        for (let i = 0; i < widgetKeys.length; i++  ) {
                            if (widgetKeys[i].Type == 'footer') {
                                if(widgetKeys[i].format === "") {
                                    cssClass = "text-start datatable-footer";
                                } else {
                                    cssClass = "datatable-footer";                                    
                                }
                                this.tableFooterFields.push({key: widgetKeys[i].Id.toString(), format: widgetKeys[i].format, label: widgetKeys[i].Display, class:cssClass, format:widgetKeys[i].format});
                            }
                            else if (widgetKeys[i].Type == 'header-x') {
                                this.tableFields.push({key: 'type', label: '', format:''});
                                flatData = false;
                                let formatType;

                                let cols = this.widgetData.map(function(value) {
                                    for (let key in value) {
                                        if (value.hasOwnProperty(key)) {
                                            formatType = widgetKeys.filter(wk => wk.Id === key).format; 
                                        }
                                    }
                                    return {key: value[widgetKeys[i].Id].toString(), label:value[widgetKeys[i].Id], format:widgetKeys[i+1].format};
                                });
                                this.tableFields = this.tableFields.concat(cols);
                                break;
                            } else {
                                cssClass = "";
                                if (widgetKeys[i].format == "") {
                                    cssClass = "text-start";
                                }
                                this.tableFields.push({key: widgetKeys[i].Id.toString(), label: widgetKeys[i].Display, class:cssClass, format:widgetKeys[i].format});
                            }
                        }
                       
                        if (this.tableFooterFields.length > 0) {
                            footerRow = this.widgetData.splice(-1); 
                        }
                        /* If data is not matching with data table columns then transform it to map to data table columns    */
                        if (flatData) { // no. of FY columns === total number of records 
                            this.widgetData.forEach(element => {
                                let rowObj = {},
                                    expRowObj = {};
                                for (let i = 0; i < widgetKeys.length; i++  ) {
                                    if (widgetKeys[i].Type.toLowerCase() !== 'footer') {
                                        rowObj[widgetKeys[i].Id] = this.formatLRPData(element[widgetKeys[i].Id], widgetKeys[i].format, false);
                                        expRowObj[widgetKeys[i].Id] = this.formatLRPData(element[widgetKeys[i].Id], widgetKeys[i].format, true);
                                    }
                                }
                                this.dataItems.push(rowObj);
                                this.exportDataItems.push(expRowObj);
                            });
                            footerRow.forEach(element => {
                                let rowObj = {},
                                    expRowObj = {};
                                for (let i = 0; i < widgetKeys.length; i++  ) {
                                    if (widgetKeys[i].Type.toLowerCase() === 'footer') {
                                        rowObj[widgetKeys[i].Id] = this.formatLRPData(element[widgetKeys[i].Id], widgetKeys[i].format, false);
                                        expRowObj[widgetKeys[i].Id] = this.formatLRPData(element[widgetKeys[i].Id], widgetKeys[i].format, true);
                                    }
                                }
                                this.footerItems.push(rowObj);
                                this.exportFooterItems.push(expRowObj);
                            });
                        } else {
                            for (let i = 1; i < widgetKeys.length; i++  ) {
                                let rowObj = {};
                                let expRowObj = {};
                                
                                this.widgetData.forEach(element => {
                                    if (widgetKeys[i].Type.toLowerCase() === 'row') {
                                        if (widgetKeys[i].helpText) {
                                            rowObj["type"] = `<span class="text-nowrap">` +  widgetKeys[i].Display + `<span data-bs-toggle="tooltip" data-bs-title="` + widgetKeys[i].helpText + `"> <i class="fas fa-question-circle"></i></span></span>`;
                                            expRowObj["type"] = `<span class="text-nowrap">` +  widgetKeys[i].Display + `<span data-bs-toggle="tooltip" data-bs-title="` + widgetKeys[i].helpText + `"> <i class="fas fa-question-circle"></i></span></span>`;
                                        } else {
                                            rowObj["type"] = widgetKeys[i].Display;
                                            expRowObj["type"] = widgetKeys[i].Display
                                        }
                                    }
                                    rowObj[element[widgetKeys[0].Id.toString()]] = this.formatLRPData(element[widgetKeys[i].Id], widgetKeys[i].format, false);
                                    expRowObj[element[widgetKeys[0].Id.toString()]] = this.formatLRPData(element[widgetKeys[i].Id], widgetKeys[i].format, true);
                                    
                                });
                                this.dataItems.push(rowObj);
                                this.exportDataItems.push(expRowObj);
                            }
                        }
                        if ($("#report-" + this.reportId + "-modal").hasClass('show')) {
                            this.setUpExport(true);
                        } else {
                            this.setUpExport(false);
                        }
                    } 
                    else {
                        this.noData = true;
                    }
                    let payload = {'reportId': this.reportId, 'data': responseData[0]};
                    this.setLRPReportData(payload);
                    this.loadWidgetData = false;
                    setTimeout(() => {
                        $("table.table.b-table").removeClass("table b-table table-hover b-table-stacked-sm table-striped");
                        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                            return new bootstrap.Tooltip(tooltipTriggerEl)
                        })
                        
                    }, 101)
                    if (this.redrawChart && this.hasChart) {
                        this.redrawChart = false;
                        bus.$emit('lrpreport:redraw', this.reportId);
                    }
                } else if (!!responseData.data){
                    this.data = copyData(responseData);

                    if (this.data && this.data.dataSets) {
                        this.activeDataSet = copyData(this.data.dataSets[0]);
                        if (this.$route.query && this.data.dataSetLabel && this.$route.query[this.data.dataSetLabel]) {
                            this.activeDataSet = this.$route.query[this.data.dataSetLabel];
                        }
                        if (this.data.dataSetsSecondary) {
                            this.activeDataSetSecondary = copyData(this.data.dataSetsSecondary[0]);
                            if (this.$route.query && this.data.dataSetSecondaryLabel && this.$route.query[this.data.dataSetSecondaryLabel]) {
                                this.activeDataSetSecondary = this.$route.query[this.data.dataSetSecondaryLabel];
                            }
                        }
                        if (!responseData.data.chartData && !responseData.data.tableData) {
                            this.data.allData = copyData(responseData.data);
                            this.data.data = copyData(responseData.data[this.data.dataSets.indexOf(this.activeDataSet)]);
                            if (this.data.dataSetsSecondary) {
                                if (!this.activeDataSetSecondary) {
                                    this.activeDataSetSecondary = copyData(this.dataSetsSecondary.dataSets[0]);
                                }
                                this.data.data = copyData(responseData.data[this.data.dataSets.indexOf(this.activeDataSet)][this.data.dataSetsSecondary.indexOf(this.activeDataSetSecondary)]);
                            }
                        }
                    }

                    if (responseData && responseData.data && (responseData.data.chartData || responseData.data.tableData)) {
                        if (responseData.data.chartData) {
                           this.data.allData = copyData(responseData.data);

                            this.data.data = copyData(responseData.data.chartData);
                            if (this.activeDataSet) {
                                this.data.data = copyData(responseData.data.chartData[this.data.dataSets.indexOf(this.activeDataSet)]);
                                if (this.data.dataSetsSecondary) {
                                    this.data.data = copyData(responseData.data.chartData[this.data.dataSets.indexOf(this.activeDataSet)][this.data.dataSetsSecondary.indexOf(this.activeDataSetSecondary)]);
                                }
                            }
                            this.data.columnFormat = copyData(responseData.columnFormat.chartData);
                        }

                        if (responseData.data.tableData) {
                            this.tableData = copyData(responseData);
                            this.tableData.data = copyData(responseData.data.tableData);
                            if (this.activeDataSet) {
                                this.tableData.data = copyData(responseData.data.tableData[this.data.dataSets.indexOf(this.activeDataSet)]);
                                if (this.data.dataSetsSecondary) {
                                    this.tableData.data = copyData(responseData.data.tableData[this.data.dataSets.indexOf(this.activeDataSet)][this.data.dataSetsSecondary.indexOf(this.activeDataSetSecondary)]);
                                }
                            }
                            this.tableData.columnFormat = copyData(responseData.columnFormat.tableData);
                        }

                        // if (this.data && this.data.dataSets) {
                        //     this.data.allData = copyData(responseData.data.chartData);
                        //     this.data.data = copyData(responseData.data.chartData[0]);
                        //     this.activeDataSet = copyData(this.data.dataSets[0]);
                        //     this.data.columnFormat = copyData(responseData.columnFormat.chartData[0]);

                        //     if (!this.chartAxesLabels && this.data && !!this.data.chartAxesLabels) {
                        //         this.chartAxesLabels = this.data.chartAxesLabels[0];
                        //     }
                        // }
                    } else {
                        this.tableData = copyData(this.data);

                        // if (this.data && this.data.dataSets) {
                        //     this.data.allData = copyData(responseData.data);
                        //     this.data.data = copyData(responseData.data[0]);
                        //     this.activeDataSet = copyData(this.data.dataSets[0]);
                        //     if (responseData.columnFormat && responseData.columnFormat.chartData && responseData.columnFormat.chartData[0]) {
                        //         this.data.allColumnFormats = copyData(responseData.columnFormat.chartData);
                        //         this.data.columnFormat = copyData(responseData.columnFormat.chartData[0]);
                        //     }
                        // }
                    }


                    if (responseData && responseData.data && responseData.data.exportData) {
                        this.exportData = copyData(responseData);
                        this.exportData.data = copyData(responseData.data.exportData);
                        // this.exportData.columnFormat = copyData(responseData.columnFormat.exportData);
                    } else {
                        this.exportData = copyData(this.tableData);
                    }

                    if (!this.chartAxesLabels && this.data && !!this.data.chartAxesLabels) {
                        this.chartAxesLabels = this.data.chartAxesLabels;
                    }

                    if (!this.title && this.data.title) {
                        this.title = this.data.title;
                    } else if (!this.title) {
                        $.each(this.categoryList, function (i, obj) {
                            if (obj.Reports.length > 0) {
                                $.each(obj.Reports, function (j, subObj) {
                                    if (subObj.ReportId === self.reportId) {
                                        self.title = subObj.ReportName;
                                        return false;
                                    }
                                });
                            } else if (obj.Categories.length > 0) {
                                $.each(obj.Categories, function (j, subObj) {
                                    $.each(subObj.Reports, function (j, repObj) {
                                        if (repObj.ReportId === self.reportId) {
                                            self.title = repObj.ReportName;
                                            return false;
                                        }
                                    });
                                    if (self.title) {
                                        return false;
                                    }
                                });
                            }
                            if (self.title) {
                                return false;
                            }
                        });
                    }

                    if (!this.lastUpdated && this.data.lastUpdated) {
                        this.lastUpdated = this.data.lastUpdated;
                    }

                    this.hasChart = !!this.data.chartType;
                    this.exportableChart = this.hasChart;

                    if (!this.footnotes || (this.footnotes && this.footnotes.length === 0)) {
                        this.footnotes = this.data.footnotes;
                    }

                    // if (this.chartAxesLabels && this.chartAxesLabels.x === null && this.chartAxesLabels.y === null) {
                    //     if (!!this.data.chartAxesLabels) {
                    //         this.chartAxesLabels = this.data.chartAxesLabels;
                    //     }
                    // }

                    if (this.data.data.length > 0 || (this.data.data.tableData && this.data.data.tableData.length > 0)) {
                        if (this.hasChart) {
                            // delay chart rendering if sidebar is currently animating
                            // as the chart will render at the wrong size otherwise
                            setTimeout(() => {
                                this.setDefaultGraphOptions();

                                if (this.miniMode && this.$parent.sizeChart) {
                                    // set Mini charts on homepage to all be same size
                                    setTimeout(() => {
                                        // timeout to wait for charts to render
                                        this.$parent.sizeChart();
                                    }, 1);
                                }
                            }, this.$parent.$parent.sidebarAnimating ? 301 : 1);
                        }

                        this.setUpExport(false);
                    } else {
                        this.noData = true;
                    }
                }
            } else if (!this.$route.params.categoryId && !this.$route.params.page) {
				gtag('event','Error', {
					'event_category': 'Report Not Found',
					'event_label':  this.reportId,
					'value':  this.reportId
				});	

                document.title = this.$parent.$parent.pageTitle;
                this.$router.push({
                    path: '/page/report-not-found'
                }).catch(() => { });
            }

            this.$nextTick(() => {
                // init tooltips for report categories
                //$(this.$el).find('[data-bs-toggle="tooltip"]').tooltip();
                var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                    return new bootstrap.Tooltip(tooltipTriggerEl)
                })
            });

            this.loadWidgetData = false;
            if (this.$parent.widgetLoaded) {
                this.$parent.widgetLoaded();
            }
        },

        formatLRPData (data, format, isExport) {
            switch (format.toLowerCase()) {
                case 'number':
                    return window.filters.addCommas(data) || '*'
                case 'number-na':
                    return  window.filters.addCommas(data) || 'N/A'
                case '%':
                    return  window.filters.formatPercentage(data, format)
                case '%-na':
                    return  data ? window.filters.formatPercentage(data, format) : 'N/A';
                case '$':
                    return  !isExport ? window.filters.formatCurrency(data, format) : data
                case '$m':
                    return  '$' + data + 'M';
                case 'range':
                    return  window.filters.appendNumberString(data, format)
                case 'years':
                    return  window.filters.addCommas(data);
                default: 
                return data;
            }
        },
 
        getChartColumnIndexes (axis) {
            let axisValue = this.data.chartAxes[axis],
                headerIndex,
                tempIndex,
                i;

            if (Array.isArray(axisValue)) {
                headerIndex = [];

                for (i = 0; i < axisValue.length; i++) {
                    tempIndex = this.data.data[0].indexOf(axisValue[i]);
                    headerIndex.push(tempIndex);
                }
            } else {
                headerIndex = [this.data.data[0].indexOf(axisValue)];
            }

            return headerIndex;
        },

        getChartDataFor (chartType) {
            let shiftedData,
                returnData = [],
                i;

            if (this.data) {
                switch (chartType) {
                    case 'line':
                    case 'area':
                    case 'bar':
                    case 'stackedBar':
                        for (i = 1; i < this.data.data.length; i++) {
                            shiftedData = copyData(this.data.data[i]);
                            shiftedData.shift();

                            returnData.push({
                                name: this.data.data[i][0],
                                data: shiftedData
                            });
                        }
                        break;
                    case 'pie':
                        for (i = 1; i < this.data.data.length; i++) {
                            if (this.data.data[i][1].toString().toLowerCase() !== 'd') {
                                returnData.push({
                                    name: this.data.data[i][0],
                                    value: this.data.data[i][1]
                                    // value: window.filters.globalChartTooltipFormat(this.data.data[i][1], null, this.data.columnFormat[1])
                                });
                            }
                        }
                        break;
                }

                return returnData;
            } else {
                console.error('no data for', this.widgetName);
                return false;
            }
        },

        isYearHeaders () {
            let i,
                match;

            if (this.data && this.data.data[0] && this.data.data[0].length > 2) {
                for (i = 2; i < this.data.data[0].length; i++) {
                    if (this.data.data[0][i].toString().length !== 4) {
                        return false;
                    }
                    if (Number(this.data.data[0][i]) !== Number(this.data.data[0][i - 1]) + 1) {
                        return false;
                    }
                }

                return true;
            } else {
                match = this.data.data[0][1].match(/\d\d\d\d/g);

                if (match && match.length === 1 && match[0].length === 4) {
                    return true;
                }
            }

            return false;
        },

        // getChartLegend () {
        setChartLegend () {
            let i;

            if (this.data) {
                // reset legend arrays,
                // prevents duplication after exporting chart image
                this.legend = {
                    y: [],
                    y2: [],
                    all: []
                };

                if (this.isYearHeaders()) {
                    for (i = 1; i < this.data.data.length; i++) {
                        if (!this.isDataTableOnlyColumn(this.data.data[i][0])) {
                            this.legend.y.push({ name: this.data.data[i][0] });
                        }
                    }
                } else {
                    if (this.data.chartAxes) {
                        if (Array.isArray(this.data.chartAxes.y)) {
                            for (i = 0; i < this.data.chartAxes.y.length; i++) {
                                this.legend.y.push({ name: this.data.chartAxes.y[i] });
                            }
                        } else {
                            this.legend.y.push({ name: this.data.chartAxes.y });
                        }

                        if (Array.isArray(this.data.chartAxes.y2)) {
                            for (i = 0; i < this.data.chartAxes.y2.length; i++) {
                                this.legend.y2.push({ name: this.data.chartAxes.y2[i] });
                            }
                        } else if (this.data.chartAxes.y2 !== null && this.data.chartAxes.y2 !== undefined) {
                            this.legend.y2.push({ name: this.data.chartAxes.y2 });
                        }
                    } else {
                        for (i = 1; i < this.data.data[0].length; i++) {
                            if (!this.isDataTableOnlyColumn(this.data.data[0][i])) {
                                this.legend.y.push({ name: this.data.data[0][i] });
                            }
                        }
                    }
                }

                // change legend symbol to filled circle for line charts
                if (this.data.chartType) {
                    if (this.data.chartType === 'line' || this.data.chartType === 'area') {
                        for (i = 0; i < this.legend.y.length; i++) {
                            this.legend.y[i].icon = 'circle';
                        }
                    } else if (this.data.chartType === 'bar/line' || this.data.chartType === 'stackedBar/line' || this.data.chartType === 'bar') {
                        for (i = 0; i < this.legend.y2.length; i++) {
                            this.legend.y2[i].icon = 'circle';
                        }
                    }
                }

                this.legend.all = this.legend.all.concat(this.legend.y);
                this.legend.all = this.legend.all.concat(this.legend.y2);
            }
        },

        getChartAxis (axis, curChartType) {
            var categories = [],
                i;

            if (this.data) {
                if ((!this.isYearHeaders() && axis === 'x') || ((this.isYearHeaders() || (curChartType && curChartType === 'horizontal')) && axis === 'y')) {
                    for (i = 1; i < this.data.data.length; i++) {
                        if (!this.isTotalColumn(this.data.data[i][0])) {
                            categories.push(this.data.data[i][0]);
                        }
                    }
                } else {
                    for (i = 1; i < this.data.data[0].length; i++) {
                        categories.push(this.data.data[0][i]);
                    }
                }

                return categories;
            } else {
                console.error('no data for', this.widgetName);
                return false;
            }
        },

        isTotalColumn (header) {
            if (header !== null && header !== undefined) {
                header = header.toString().toLowerCase();

                if (header.indexOf('combined') !== -1 || header.indexOf('total') !== -1) {
                    return true;
                }
            }

            return false;
        },

        isDataTableOnlyColumn (header) {
            if (header !== null && header !== undefined) {
                header = header.toString();

                //if (header.indexOf('combined') !== -1 || header.indexOf('total') !== -1) {
                //    return true;
                //}
                if (this.data.chartType !== 'compoundBar/area') {
                    if (this.data.chartAxes.y && this.data.chartAxes.y[0]) {
                        if (this.data.chartAxes.y.indexOf(header) === -1) {
                            return true;
                        }
                    }
                } else {
                    header = header.toString().toLowerCase();
                    if (header.indexOf('combined') !== -1 || header.indexOf('total') !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getMultiSeriesData (indecies) {
            let selectedData = [],
                shiftedData,
                dataLength = this.data.data.length,
                isTotal = false;

            this.showResetButton = true;

            if (this.selectedBarIndices.length === this.data.data.length - 2) {
                if (!this.data.alternateChartType || (this.data.alternateChartType && this.data.alternateChartType !== 'line')) {
                    indecies = [];
                    isTotal = true;
                }

                this.showResetButton = false;
            }

            for (let i = 1; i < dataLength; i++) {
                if (indecies.indexOf(i) !== -1) {
                    shiftedData = copyData(this.data.data[i]);
                    shiftedData.splice(0, 1);

                    selectedData.push({
                        id: 'bottomChart' + i,
                        name: this.data.data[i][0],
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        stack: (this.data.alternateChartType && this.data.alternateChartType === 'line') ? null : 'areaStack',
                        areaStyle: (this.data.alternateChartType && this.data.alternateChartType === 'line') ? null : {},
                        itemStyle: {
                            color: isTotal ? this.chartOptions.color[0] : this.chartOptions.color[i],
                            borderWidth: this.chartOptions.normal.itemStyle.borderWidth
                        },
                        symbolSize: this.chartOptions.symbolSize,
                        data: shiftedData
                    });
                } else {
                    selectedData.push({
                        id: 'bottomChart' + i,
                        name: this.data.data[i][0],
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        stack: (this.data.alternateChartType && this.data.alternateChartType === 'line') ? null : 'areaStack',
                        data: null
                    });
                }
            }

            if (indecies.length === 0) {
                shiftedData = copyData(this.data.data[dataLength - 1]);
                shiftedData.splice(0, 1);

                selectedData.push({
                    id: 'bottomChartTotal',
                    name: this.data.data[dataLength - 1][0],
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    stack: 'areaStack',
                    areaStyle: {},
                    itemStyle: {
                        color: this.chartOptions.color[0],
                        borderWidth: this.chartOptions.normal.itemStyle.borderWidth
                    },
                    symbolSize: this.chartOptions.symbolSize,
                    data: shiftedData
                });
            } else {
                selectedData.push({
                    id: 'bottomChartTotal',
                    name: 'total',
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    stack: 'areaStack',
                    data: null
                });
            }

            return selectedData;
        },

        getChartSeries () {
            let series = [],
                seriesData = [],
                multiSeriesData = [],
                actualChartType,
                stacked = false,
                axis,
                indexes = {
                    x: null,
                    y: null,
                    y2: null
                },
                i,
                i2,
                j;

            switch (this.data.chartType) {
                // case 'bar/line':
                //     break;
                case 'area':
                    actualChartType = 'line';
                    stacked = true;
                    break;
                case 'stackedBar':
                    actualChartType = 'bar';
                    stacked = true;
                    break;
                case 'bar/horizontal':
                    actualChartType = 'bar';
                    stacked = false;
                    break;
                case 'stackedBar/line':
                    // actualChartType = 'bar';
                    stacked = true;
                    break;
                default:
                    actualChartType = this.data.chartType.toString();
                    break;
            }

            if (this.data && this.data.chartType === 'compoundBar/area') {
                // for (i = 1; i < this.data.data[0].length; i++) {
                seriesData = [];

                for (i = 1; i < this.data.data.length; i++) {
                    if (!this.isDataTableOnlyColumn(this.data.data[i][0])) {
                        seriesData.push({
                            name: this.data.data[i][0],
                            // name: this.selectedName,
                            value: this.data.data[i][this.selectedIndex ? this.selectedIndex : this.data.data[0].length - 1] || null,
                            itemStyle: {
                                color: this.selectedBarIndices.indexOf(i) !== -1 ? this.chartOptions.color[i] : 'rgba(0,0,0,0.15)'
                            }
                        });
                    }
                }

                multiSeriesData = this.getMultiSeriesData(this.selectedBarIndices);

                series.push({
                    id: 'topChart',
                    name: this.selectedName || this.data.data[0][this.data.data[0].length - 1],
                    type: 'bar',
                    data: seriesData,
                });

                if (!this.miniMode) {
                    series = series.concat(multiSeriesData);
                }

                return series;
            }

            if (this.data && (this.data.chartType === 'line' || this.data.chartType === 'bar' || this.data.chartType === 'bar/line' || this.data.chartType === 'stackedBar/line')) {
                for (axis in indexes) {
                    indexes[axis] = this.getChartColumnIndexes(axis);

                    if (axis === 'y' || axis === 'y2') {
                        for (i = 0; i < indexes[axis].length; i++) {
                            seriesData = [];

                            for (i2 = 1; i2 < this.data.data.length; i2++) {
                                seriesData.push(this.data.data[i2][indexes[axis][i]]);
                            }

                            series.push({
                                name: this.data.data[0][i + 1],
                                // name: this.chartAxesLabels ? this.chartAxesLabels[axis] : this.data.data[0][i],
                                // type: axis === 'y' ? 'bar' : 'line',
                                type: this.data.chartType === 'line' ? 'line' : (axis === 'y' ? 'bar' : 'line'),
                                stack: stacked ? 'stack' : null,
                                areaStyle: this.data.chartType === 'area' ? {} : null,
                                symbolSize: this.chartOptions.symbolSize,
                                normal: {
                                    itemStyle: {
                                        borderWidth: this.chartOptions.normal.itemStyle.borderWidth
                                    },
                                    lineStyle: {
                                        width: this.chartOptions.normal.lineStyle.width
                                    }
                                },
                                data: seriesData
                            });
                        }
                    }
                }

                return series;
            }

            if (this.data && this.data.chartType === 'bar/horizontal') {
                for (axis in indexes) {
                    indexes[axis] = this.getChartColumnIndexes(axis);

                    if (axis === 'x') {
                        for (i = 0; i < indexes[axis].length; i++) {
                            seriesData = [];

                            for (i2 = 1; i2 < this.data.data.length; i2++) {
                                seriesData.push(this.data.data[i2][indexes[axis][i]]);
                            }

                            series.push({
                                name: this.data.data[0][i + 1],
                                type: this.data.chartType === 'line' ? 'line' : (axis === 'x' ? 'bar' : 'line'),
                                stack: stacked ? 'stack' : null,
                                areaStyle: this.data.chartType === 'area' ? {} : null,
                                symbolSize: this.chartOptions.symbolSize,
                                normal: {
                                    itemStyle: {
                                        borderWidth: this.chartOptions.normal.itemStyle.borderWidth
                                    },
                                    lineStyle: {
                                        width: this.chartOptions.normal.lineStyle.width
                                    }
                                },
                                label: {
                                    show: true,
                                    formatter: (value) => {
                                        return Math.round(this.data.data[value.dataIndex + 1][value.seriesIndex + 2]) + '%';
                                    },
                                    position: 'right'
                                },
                                data: seriesData
                            });
                        }
                    }
                }

                return series;
            }

            if (this.data && this.data.chartType !== 'bar/line' || this.data && this.data.chartType !== 'stackedBar/line' || this.data && this.data.chartType !== 'bar') {
                let chartAxesY;

                if (this.data.chartAxes.y) {
                    chartAxesY = this.data.chartAxes.y;
                } else if (this.activeDataSet && this.data.chartAxes[0] && this.data.chartAxes[0].y) {
                    chartAxesY = this.data.chartAxes[this.data.dataSets.indexOf(this.activeDataSet)].y;
                }

                for (j = 0; j < chartAxesY.length; j++) {
                    seriesData = [];
                    i = this.data.data[0].indexOf(chartAxesY[j]);

                    if (!this.isDataTableOnlyColumn(this.data.data[0][i])) {
                        if (chartAxesY[0]) {
                            if (chartAxesY.indexOf(this.data.data[0][i]) !== -1) {
                                for (i2 = 1; i2 < this.data.data.length; i2++) {
                                    if (!this.isTotalColumn(this.data.data[i2][0])) {
                                        seriesData.push(this.data.data[i2][i]);
                                    }
                                }
                            }
                        } else {
                            for (i2 = 1; i2 < this.data.data.length; i2++) {
                                if (!this.isTotalColumn(this.data.data[i2][0])) {
                                    seriesData.push(this.data.data[i2][i]);
                                }
                            }
                        }
                    }

                    series.push({
                        name: this.data.data[0][i],
                        // name: this.chartAxesLabels ? this.chartAxesLabels.y : this.data.data[0][i],

                        // name: this.chartAxesLabels ? this.chartAxesLabels.y : null,
                        // nameLocation: 'center',
                        // nameTextStyle: {
                        //     fontWeight: 'bold'
                        // },
                        // nameGap: this.nameGapDefault.y,

                        type: actualChartType,
                        stack: stacked ? 'stack' : null,
                        areaStyle: this.data.chartType === 'area' ? {} : null,
                        data: seriesData,
                        symbolSize: this.chartOptions.symbolSize
                    });
                }

                return series;
            } else {
                console.error('no data for', this.widgetName);
                return false;
            }
        },

        setDefaultGraphOptions () {
            this.chartOptions = copyData(sharedSpecs.eCharts);

            // if report data has custom colors, assign them now
            if (this.data && this.data.color) {
                this.chartOptions.color = this.data.color;
            }

            this.$nextTick(() => {
                if (this.$refs.chart) {
                    this.$refs.chart.initChart();
                }
            });
        },

        formatter (value, axis, isExport) {
            let formattedValue = window.filters.chartAxisFormat(value, null, this.axisFormat[axis]),
                textFormat,
                textWidth,
                chart = isExport ? 'exportChart' : 'eChart',
                chartOptions = isExport ? 'exportChartOptions' : 'chartOptions',

                formatLabels = () => {
                    if (this.$refs.chart && this.$refs.chart[chart]) {
                        this.$nextTick(() => {
                            this.$refs.chart[chart].setOption(this[chartOptions]);
                        });
                    }

                    if (isExport) {
                        this.isFormattedExport = true;
                    } else {
                        // this.isFormatted = true;
                        this.isReformatted = true;
                    }
                },

                resetLabels = () => {
                    this.isFormatted = true;

                    this[chartOptions].xAxis[0].nameTextStyle.color = null;
                    this[chartOptions].yAxis[0].nameTextStyle.color = null;
                    if (!!this[chartOptions].yAxis[1]) {
                        this[chartOptions].yAxis[1].nameTextStyle.color = null;
                    }

                    if (this.$refs.chart && this.$refs.chart[chart]) {
                        this.$nextTick(() => {
                            this.$refs.chart[chart].setOption(this[chartOptions]);
                        });
                    }
                };

            // check this.isFormatted otherwise this will constantly run in the eCharts draw loop
            if (!this.isFormatted || !this.isReformatted || (isExport && !this.isFormattedExport)) {
                textFormat = 'normal 12px Roboto';
                textWidth = window.getTextWidth(formattedValue, textFormat);

                if (textWidth > this.axisLabelSize[axis]) {
                    this.axisLabelSize[axis] = Math.ceil(textWidth);
                }

                if (axis === 'x') {
                    this[chartOptions].xAxis[0].nameGap = this.axisLabelSize[axis] + 16;
                } else if (axis === 'y') {
                    this[chartOptions].yAxis[0].nameGap = this.axisLabelSize[axis] + 16;
                    if (Array.isArray(this[chartOptions].grid)) {
                        this[chartOptions].grid[0].left = this.axisLabelSize[axis] + 32;
                    } else {
                        this[chartOptions].grid.left = this.axisLabelSize[axis];

                        // set minimum spacing to prevent bug with labels being cut off. should look into to actual root of the issue if time allows.
                        if (this[chartOptions].grid.left < 20) {
                            this[chartOptions].grid.left = 20;
                        }
                    }
                } else if (axis === 'y2') {
                    this[chartOptions].yAxis[1].nameGap = this.axisLabelSize[axis] + 16;
                    if (Array.isArray(this[chartOptions].grid)) {
                        this[chartOptions].grid[1].left = this.axisLabelSize[axis] + 32;
                    }
                }

                if (!this[chartOptions].yAxis[0].nameTextStyle) {
                    this[chartOptions].yAxis[0].nameTextStyle = {};
                }

                if (!this.isFormatted) {
                    // make labels invisible the first time before moving
                    this[chartOptions].xAxis[0].nameTextStyle.color = 'rgba(0,0,0,0)';
                    this[chartOptions].yAxis[0].nameTextStyle.color = 'rgba(0,0,0,0)';
                    if (!!this[chartOptions].yAxis[1]) {
                        if (!this[chartOptions].yAxis[1].nameTextStyle) {
                            this[chartOptions].yAxis[1].nameTextStyle = {};
                        }

                        this[chartOptions].yAxis[1].nameTextStyle.color = 'rgba(0,0,0,0)';
                    }
                }

                if (this.formatTimer) {
                    clearTimeout(this.formatTimer);
                }

                // if (isExport) {
                //      formatLabels();
                //      resetLabels();
                // } else {
                if (this.$refs.chart && this.$refs.chart.eChart) {
                    this.formatTimer = setTimeout(() => {
                        formatLabels();
                        // this.$refs.chart.eChart.setOption(this.chartOptions, true);
                        // this.isFormatted = true;

                        if (!this.isFormatted || (isExport && !this.isFormattedExport)) {
                            if (isExport) {
                                resetLabels();
                            } else {
                                setTimeout(() => {
                                    resetLabels();
                                    // this.chartOptions.xAxis[0].nameTextStyle.color = null;
                                    // this.chartOptions.yAxis[0].nameTextStyle.color = null;
                                    // this.chartOptions.yAxis[1].nameTextStyle.color = null;
                                    // this.$refs.chart.eChart.setOption(this.chartOptions, true);
                                }, 800);
                            }
                        }
                    }, 10);
                }
                // }
            }

            return formattedValue;
        },

        resetFormatter () {
            // this.isFormatted = false;
            this.isReformatted = false;
            this.axisLabelSize = {
                x: 0,
                y: 0,
                y2: 0
            };
        },

        // getFirstColumnData () {
        getColumnData (index) {
            var categories = [],
                i;

            for (i = 1; i < this.data.data.length; i++) {
                // if (!this.isTotalColumn(this.data.data[i][index])) {
                if (!this.isTotalColumn(this.data.data[i][index])) {
                    categories.push(this.data.data[i][index]);
                }
            }

            return categories;
        },

        getRowData (index) {
            var rowData = [],
                i;

            // start at index 1 to skip left header column
            for (i = 1; i < this.data.data.length; i++) {
                rowData.push(this.data.data[index][i]);
            }

            return rowData;
        },

        getHeaderRowData () {
            var rowData = [],
                i;

            // start at index 1 to skip left header column
            for (i = 1; i < this.data.data[0].length; i++) {
                rowData.push(this.data.data[0][i]);
            }

            return rowData;
        },

        getHeader (index) {
            return this.data.data[0][index];
        },

        getSelectedNames (indecies) {
            let names = [],
                i;

            for (i = 0; i < indecies.length; i++) {
                names.push(this.data.data[indecies[i]][0]);
            }

            names.sort();

            return names;
        },

        dataValueSameSame (data, data2) {
            if (data.length === data2.length) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].value !== data2[i].value) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        },

        setDefaultGraphOptionsFor (chartType, isExport) {
            let chartData = this.getChartDataFor(chartType),
                chartOptions = isExport ? 'exportChartOptions' : 'chartOptions',
                series = [],
                seriesData = [],
                stacked = false,
                axis,
                yDefaultColumn = Array.isArray(this.data.chartAxes.y) ? this.data.chartAxes.y[0] : this.data.chartAxes.y,
                indexes = {
                    x: null,
                    y: null,
                    y2: null
                },
                i,
                i2;

            this.exportChartOptions = copyData(this.chartOptions);

            if (this.data.columnFormat) {
                this.yFormat = this.data.columnFormat[this.data.data[0].indexOf(yDefaultColumn)];
            }

            if (chartType.indexOf('stacked') !== -1) {
                stacked = true;
            }

            this[chartOptions].tooltip.formatter = (params) => {
                let tooltip = [],
                    paramsFormatter,
                    legendName,
                    i,
                    toolTipLegendLength = chartType == 'line' || chartType == 'bar' || chartType == 'area' || chartType == 'stackedBar/line' || chartType == 'stackedBar' || chartType == 'bar/line' ? this[chartOptions].series.length : (chartType == 'bar/horizontal' ? this.data.data[0].length : params.length);

                if (params && params.name) {
                    this.tooltipName = params.name;
                }

                if (params.componentSubType === 'pie') {
                    if (this.data.columnFormat) {
                        // paramsFormatter = this.data.columnFormat[this.data.data[0].indexOf(this.legend.all[params.dataIndex])];
                        // paramsFormatter = this.data.columnFormat[this.data.data[0].indexOf(params.name)];
                        paramsFormatter = this.data.columnFormat[1];
                    } else {
                        paramsFormatter = null;
                    }

                    tooltip.push('<strong>' + this.data.data[0][1] + '</strong>');

                    tooltip.push('<br>' + params.marker + params.name + ': ' + window.filters.globalChartTooltipFormat(params.value, null, paramsFormatter));
                } if (chartType === 'bar/horizontal') { 
                    tooltip.push('<strong>' + params.name + '</strong>');

                    for (i = 1; i < toolTipLegendLength-1; i++) {
                        if (this.data.columnFormat) {
                            if (this.legend && this.legend.all && this.legend.all[i-1])
                                legendName = this.legend.all[i-1].name ? this.legend.all[i-1].name : this.legend.all[i-1];
                            else
                                legendName = '';
                            // get column format for legend name
                            // need to check index of name against
                            // column list as not all columns are
                            // displayed in tooltip
                            paramsFormatter = this.data.columnFormat[this.data.data[0].indexOf(legendName)];
                        } else {
                            paramsFormatter = null;
                        }

                        tooltip.push('<br>' + this.data.data[0][i] + ': ' + window.filters.globalChartTooltipFormat(this.data.data[params.dataIndex+1][i], null, paramsFormatter));
                    }
                    tooltip.push('<br>' + this.data.data[0][toolTipLegendLength-1] + ': ' + Math.round(this.data.data[params.dataIndex+1][toolTipLegendLength-1]) + '%');
                } else {
                    tooltip.push('<strong>' + params.name + '</strong>');

                    let curSeriesData = [];
                    for (i = 0; i < toolTipLegendLength; i++) {
                        curSeriesData = this[chartOptions].series[i].data.filter(val => val != undefined);
                        if (curSeriesData.length > 0) {
                            if (this.data.columnFormat) {
                                if (this.legend && this.legend.all && this.legend.all[i])
                                    legendName = this.legend.all[i].name ? this.legend.all[i].name : this.legend.all[i];
                                else
                                    legendName = '';
                                // get column format for legend name
                                // need to check index of name against
                                // column list as not all columns are
                                // displayed in tooltip
                                paramsFormatter = this.data.columnFormat[this.data.data[0].indexOf(legendName)];
                            } else {
                                paramsFormatter = null;
                            }

                            tooltip.push('<br>' + this[chartOptions].series[i].name + ': ' + window.filters.globalChartTooltipFormat(this[chartOptions].series[i].data[params.dataIndex], null, paramsFormatter));
                        }
                    }
                }

                return tooltip.join('');

            };

            // this.legend = this.getChartLegend();
            this.setChartLegend();

            if (this.data && this.data.chartAxes && this.data.chartAxes.y && this.data.columnFormat) {
                if (this.data.chartAxes.y[0]) {
                    if (!Array.isArray(this.data.columnFormat[0])) {
                        this.axisFormat.y = this.data.columnFormat[this.data.data[0].indexOf(this.data.chartAxes.y[0])];

                        // added below to fix chart 226
                        this.axisFormat.y2 = this.data.columnFormat[this.data.data[0].indexOf(this.data.chartAxes.y[0])];
                    } else {
                        if (this.activeDataSet) {
                            let dataIndex = this.data.dataSets.indexOf(this.activeDataSet);

                            this.axisFormat.y = this.data.columnFormat[dataIndex][this.data.data[0].indexOf(this.data.chartAxes.y[0])];
                            this.axisFormat.y2 = this.data.columnFormat[dataIndex][this.data.data[0].indexOf(this.data.chartAxes.y2[0])];
                        } else {
                            this.axisFormat.y = this.data.columnFormat[0][this.data.data[0].indexOf(this.data.chartAxes.y[0])];
                            this.axisFormat.y2 = this.data.columnFormat[0][this.data.data[0].indexOf(this.data.chartAxes.y2[0])];
                        }
                    }


                } else {
                    this.axisFormat.y = this.data.columnFormat[this.data.data[0].indexOf(this.data.chartAxes.y)];
                }
            }

            //type specific options
            switch (chartType) {
                case 'bar/line':
                case 'stackedBar/line': {
                    this[chartOptions].legend = {
                        show: false
                    };

                    this[chartOptions].xAxis = [
                        {
                            type: 'category',
                            data: this.getChartAxis('x'),
                            name: this.chartAxesLabels ? this.chartAxesLabels.x : this.data.chartAxes.x,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.x,
                            axisLabel: {
                                rotate: this.data.rotateLabels && this.data.rotateLabels.x ? 90 : null,
                                formatter: this.data.rotateLabels && this.data.rotateLabels.x ? (value) => {
                                    return this.formatter(value, 'x', isExport);
                                } : null
                            }
                        }
                    ];

                    this[chartOptions].yAxis = [];

                    for (axis in indexes) {
                        let cacheAxis = axis.toString();

                        // this.axisFormat[axis] = null;

                        indexes[axis] = this.getChartColumnIndexes(axis);

                        if (axis === 'y' || axis === 'y2') {
                            for (i = 0; i < indexes[axis].length; i++) {
                                seriesData = [];

                                for (i2 = 1; i2 < this.data.data.length; i2++) {
                                    seriesData.push(this.data.data[i2][indexes[axis][i]]);
                                }

                                series.push({
                                    name: this.data.data[0][indexes[axis][i]],
                                    // name: this.chartAxesLabels ? this.chartAxesLabels[axis] : this.data.data[0][indexes[axis][i]],
                                    type: axis === 'y' ? 'bar' : 'line',
                                    yAxisIndex: axis === 'y2' ? 1 : null,
                                    stack: stacked && axis === 'y' ? 'stack' : null,
                                    areaStyle: this.data.chartType === 'area' ? {} : null,
                                    data: seriesData,
                                    symbolSize: this[chartOptions].symbolSize,
                                    itemStyle: {
                                        borderWidth: this[chartOptions].normal.itemStyle.borderWidth,
                                        color: axis === 'y2' ? this[chartOptions].color[this[chartOptions].color.length - i - 1] : this[chartOptions].color[i]
                                    },
                                    lineStyle: {
                                        color: axis === 'y2' ? this[chartOptions].color[this[chartOptions].color.length - i - 1] : this[chartOptions].color[i],
                                        width: this[chartOptions].normal.lineStyle.width
                                    }
                                });
                            }

                            if (!this.data.columnFormat) {
                                // isPercent = this.data.data[0][indexes[axis][0]].indexOf('%') !== -1;

                                this.axisFormat[axis] = '%';
                            } else {
                                this.axisFormat[axis] = this.data.columnFormat[this.data.data[0].indexOf(this.data.data[0][indexes[axis][0]])];
                            }

                            this[chartOptions].yAxis.push({
                                type: 'value',
                                // name: this.data.data[0][indexes[axis][0]],
                                name: this.chartAxesLabels ? this.chartAxesLabels[axis] : this.data.data[0][indexes[axis][0]],
                                nameLocation: 'center',
                                nameTextStyle: {
                                    fontWeight: 'bold',
                                    fontFamily: this[chartOptions].fontFamily
                                },
                                nameGap: this.nameGapDefault[axis],
                                max: (axis === 'y' && this.axisFormat[axis] === '%' && this.data.chartType.indexOf('/line') === -1) || (axis === 'y2' && this.axisFormat[axis] === '%') ? 100 : null,
                                axisLabel: {
                                    // formatter: isPercent ? '{value}%' : null
                                    formatter: (value) => {
                                        return this.formatter(value, cacheAxis, isExport);
                                    }
                                }
                            });
                        }
                    }

                    this[chartOptions].series = series;

                    break;
                }
                case 'compoundBar/area': {
                    if (this.selectedIndex === null || this.selectedIndex === undefined) {
                        if (this.data.data.chartData) {
                            this.selectedIndex = this.data.data.chartData[0].length - 1;
                        } else {
                            this.selectedIndex = this.data.data[0].length - 1;
                        }
                    }

                    if (!this.selectedBarIndices || this.selectedBarIndices.length === 0) {
                        this.selectedBarIndices = [];
                        this.allBarIndices = [];

                        for (let i = 1; i < this.data.data.length - 1; i++) {
                            this.selectedBarIndices.push(i);
                            this.allBarIndices.push(i);
                        }
                    }

                    //temp override
                    // this.selectedBarIndices = [0, 3, 7];
                    if (!this.selectedName) {
                        this.selectedName = this.data.data[0][this.data.data[0].length - 1];
                    }

                    if (!this.miniMode) {
                        this[chartOptions].title = [
                            {
                                // text: 'Awards for ' + this.selectedName,
                                text: (this.activeDataSet ? this.activeDataSet : this.data.chartAxesLabels[0].y) + ' for ' + this.selectedName,
                                top: 0,
                                left: -4,
                                // top: 5,
                                // right: 10,
                                textStyle: {
                                    // fontSize: 42,
                                    fontSize: 16,
                                    fontWeight: '600',

                                    // color: 'rgba(8,64,128,0.25)'
                                    // color: 'rgba(0,0,0,0.25)'
                                    // color: 'rgba(0,0,0,0.75)'
                                    color: '#333'
                                }
                            },
                            {
                                text: this.awardsTrendLabel(chartOptions),
                                // top: '45%',
                                top: '50%',
                                left: -4,
                                textStyle: {
                                    fontSize: 16,
                                    fontWeight: '600',
                                    color: '#333'
                                },
                                // subtext: 'test sub'
                            }
                        ];
                    }


                    this[chartOptions].legend = {
                        show: false
                    };

                    this[chartOptions].tooltip.formatter = (value) => {
                        let tooltip = [],
                            valueFormatter,
                            legendName,
                            i,
                            activeDataSetIndex,
                            currentColFormat = this.data.columnFormat ? this.data.columnFormat : null,
                            dataVal = value.length > 0 ? value[0] : value,
                            objData = value.data;

                        if (dataVal && dataVal.name) {
                            this.tooltipName = dataVal.name;
                            this.tooltipSeriesId = dataVal.seriesId;
                            this.tooltipdataIndex = dataVal.dataIndex;
                        }

                        if(isProxy(objData)){ //this If() block is not really necessary
                            objData = toRaw(value.data)
                        }
                        tooltip.push('<strong>' + dataVal.name + '</strong>');

                        if (this.data.dataSets && this.data.dataSets.length > 0) {
                            activeDataSetIndex = this.data.dataSets.indexOf(this.activeDataSet);
                            if (this.data.columnFormat && this.data.columnFormat.length > 0) {
                                currentColFormat = this.data.columnFormat[activeDataSetIndex];
                            }
                        }

                        //for (i = 0; i < value.length; i++) {
                            if (currentColFormat) {
                                if (this.tooltipSeriesId.indexOf('top') === 0) {
                                    // if top chart
                                    // legendName = this.legend.all[i].name ? this.legend.all[i].name : this.legend.all[i];
                                    legendName = value.seriesName;
                                } else {
                                    // if bottom chart
                                    legendName = this.tooltipName;
                                }

                                // get column format for legend name
                                // need to check index of name against
                                // column list as not all columns are
                                // displayed in tooltip
                                valueFormatter = currentColFormat[this.data.data[0].map(String).indexOf(legendName)];

                                // valueFormatter = this.data.columnFormat[this.data.data[0].indexOf(this.legend.all[i])];
                            } else {
                                valueFormatter = null;
                            }

                            tooltip.push('<br>' + value.marker + value.seriesName + ': ' + window.filters.globalChartTooltipFormat(value.value, null, valueFormatter));
                       // }
                        if (dataVal.dataIndex > 0 && dataVal.seriesType === 'line') {
                            // check if new series is hovered
                            if (this.selectedName !== dataVal.name) {
                                this.selectedName = dataVal.name;
                                this.selectedIndex = dataVal.dataIndex + 1;

                                // this[chartOptions].title.text = this.selectedName;

                                // update only top chart data
                                this.$nextTick(() => {
                                    this.$refs.chart.eChart.setOption({
                                        series: this.getChartSeries()[0],
                                        title: [
                                            { text: (this.activeDataSet ? this.activeDataSet : this.data.chartAxesLabels[0].y) + ' for ' + this.selectedName },
                                            { text: this.awardsTrendLabel(chartOptions) }
                                        ]
                                    });
                                });
                            }
                        }

                        return tooltip.join('');
                    };

                    if (!this.miniMode) {
                        this[chartOptions].grid = [
                            {
                                // top: 10,
                                top: 35,
                                bottom: '70%',
                                left: 50,
                                right: 15
                            },
                            {
                                // top: '53%',
                                top: '58%',
                                bottom: 50,
                                left: 50,
                                right: 15
                            }
                        ];
                    } else {
                        this[chartOptions].grid = [
                            {
                                // top: 10,
                                top: 10,
                                bottom: 85,
                                left: 10,
                                right: 10
                            }
                        ];
                    }

                    this[chartOptions].xAxis = [
                        {
                            gridIndex: 0,
                            type: 'category',
                            // boundaryGap: false,
                            // name: this.chartAxesLabels.x ? this.chartAxesLabels.x : this.data.chartAxes.x,
                            name: this.chartAxesLabels && this.chartAxesLabels[0] && this.chartAxesLabels[0].x ? this.chartAxesLabels[0].x : this.data.chartAxes.x,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.x,
                            data: this.getColumnData(0),
                            axisLabel: {
                                rotate: 90,
                                formatter: (value) => {
                                    return this.formatter(value, 'x', isExport);
                                }
                            }
                        },

                    ];

                    if (!this.miniMode) {
                        this[chartOptions].xAxis.push({
                            gridIndex: 1,
                            type: 'category',
                            boundaryGap: false,
                            // name: this.chartAxesLabels.x ? this.chartAxesLabels.x : this.data.chartAxes.x,
                            name: this.chartAxesLabels[1].x,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.x,
                            data: this.getHeaderRowData(),
                            // axisLabel: {
                            //     rotate: 90,
                            //     formatter: (value) => {
                            //         return this.formatter(value, 'x', isExport);
                            //     }
                            // }
                        });
                    }

                    this[chartOptions].yAxis = [
                        {
                            gridIndex: 0,
                            type: 'value',
                            name: this.chartAxesLabels[0].y ? this.chartAxesLabels[0].y : (this.data && this.data.dataSets && this.chartAxesLabels[this.data.dataSets.indexOf(this.activeDataSet)][0].y ? this.chartAxesLabels[this.data.dataSets.indexOf(this.activeDataSet)][0].y : null),
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.y,
                            axisLabel: {
                                formatter: (value) => {
                                    return this.formatter(value, 'y', isExport);
                                }
                            }
                        }
                    ];

                    if (!this.miniMode) {
                        this[chartOptions].yAxis.push({
                            gridIndex: 1,
                            type: 'value',
                            name: this.chartAxesLabels[1].y ? this.chartAxesLabels[1].y : (this.chartAxesLabels[this.data.dataSets.indexOf(this.activeDataSet)][1].y ? this.chartAxesLabels[this.data.dataSets.indexOf(this.activeDataSet)][1].y : null),
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.y,
                            axisLabel: {
                                formatter: (value) => {
                                    return this.formatter(value, 'y2', isExport);
                                }
                            }
                        });
                    }

                    this[chartOptions].series = this.getChartSeries();

                    // below is moved to initChart() in chart.vue

                    // if (!isExport) {
                    //     this.$refs.chart.eChart.on('click', (params) => {
                    //         if (params.seriesId === 'topChart') {
                    //             this.clickTopChart(chartOptions, params);
                    //         }
                    //     });
                    // }

                    break;
                }
                case 'bar':
                case 'stackedBar': {
                    // case 'compoundBar/area':

                    // this.axisFormat.y = this.data.columnFormat[this.data.data[0].indexOf(this.data.data[0][indexes.y[0]])];

                    this[chartOptions].legend = {
                        // x: 'left',
                        data: this.legend.all,
                        show: false
                    };
                    ////////////////////////

                    this[chartOptions].xAxis = [
                        {
                            type: 'category',
                            // boundaryGap : false,
                            name: this.chartAxesLabels ? this.chartAxesLabels.x : this.data.chartAxes.x,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.x,
                            data: this.getChartAxis('x'),
                            axisLabel: {
                                rotate: this.data.rotateLabels && this.data.rotateLabels.x ? 90 : null,
                                formatter: this.data.rotateLabels && this.data.rotateLabels.x ? (value) => {
                                    return this.formatter(value, 'x', isExport);
                                } : null
                            }
                        }
                    ];

                    this[chartOptions].yAxis = [
                        {
                            type: 'value',
                            // for stacked bars in percentage format,
                            // force max Y axis to 100%,
                            // as some data goes to 101% due to rounding
                            max: chartType === 'stackedBar' && this.axisFormat['y'] === '%' ? 100 : null,
                            name: this.chartAxesLabels ? this.chartAxesLabels.y : null,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.y,
                            axisLabel: {
                                formatter: (value) => {
                                    // return window.filters.chartAxisFormat(value, null, this.yFormat);
                                    return this.formatter(value, 'y', isExport);
                                }
                            }
                        }
                    ];

                    this[chartOptions].series = this.getChartSeries();

                    break;
                }
                case 'bar/horizontal': {
                    this[chartOptions].legend = {
                        data: this.legend.all,
                        show: false
                    };

                    this[chartOptions].grid.bottom = 25;

                    this[chartOptions].yAxis = [
                        {
                            type: 'category',
                            name: this.chartAxesLabels ? this.chartAxesLabels.y : this.data.chartAxes.y,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.y,
                            data: this.getChartAxis('y', 'horizontal'),
                            axisLabel: {
                                rotate: this.data.rotateLabels && this.data.rotateLabels.y ? 90 : null,
                                formatter: this.data.rotateLabels && this.data.rotateLabels.y ? (value) => {
                                    return this.formatter(value, 'y', isExport);
                                } : null
                            }
                        }
                    ];

                    this[chartOptions].xAxis = [
                        {
                            type: 'value',
                            max: chartType === 'stackedBar' && this.axisFormat['x'] === '%' ? 100 : null,
                            name: this.chartAxesLabels ? this.chartAxesLabels.x : null,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily,
                                align: this.$route.params.categoryId ? 'right' : 'center',
                                padding: this.$route.params.categoryId ? [5, -25, 15, 55] : 0
                            },
                            nameGap: this.$route.params.categoryId ? 15 : 22
                        }
                    ];

                    this[chartOptions].series = this.getChartSeries();

                    break;
                }
                case 'line':
                case 'area': {
                    this[chartOptions].legend = {
                        x: 'left',
                        data: this.legend.all,
                        show: false
                    };

                    this[chartOptions].xAxis = [
                        {
                            type: 'category',
                            name: this.chartAxesLabels ? this.chartAxesLabels.x : this.data.chartAxes.x,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.x,
                            boundaryGap: false,
                            data: this.getChartAxis('x')
                        }
                    ];

                    this[chartOptions].yAxis = [
                        {
                            type: 'value',
                            name: this.chartAxesLabels ? this.chartAxesLabels.y : null,
                            nameLocation: 'center',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontFamily: this[chartOptions].fontFamily
                            },
                            nameGap: this.nameGapDefault.y,
                            max: this.axisFormat.y === '%' ? 100 : null,
                            axisLabel: {
                                formatter: (value) => {
                                    // return window.filters.chartAxisFormat(value, null, this.yFormat);
                                    return this.formatter(value, 'y', isExport);
                                }
                            }

                        }
                    ];

                    this[chartOptions].series = this.getChartSeries();

                    break;
                }
                case 'pie': {
                    this[chartOptions].tooltip.trigger = 'item';
                    // this[chartOptions].tooltip.formatter = null;

                    this[chartOptions].legend = {
                        x: 'left',
                        data: this.legend.all,
                        show: false
                    };

                    // this[chartOptions].series.itemStyle.normal.label.show = this.$parent.$parent.isLargeScreen;
                    this[chartOptions].series = [{
                        name: this.title,
                        data: chartData,
                        type: 'pie',
                        // radius : ['40%', '60%'],
                        radius: ['50%', '70%'],
                        label: {
                            color: '#000',
                            fontSize: 12,
                            // position: 'inside'
                            // show: !!isExport
                            show: (this.$parent.$parent.isLargeScreen && !this.miniMode) || isExport,
                            formatter: (params) => {
                                // if (this.$parent.$parent.$parent.isLargeScreen) {
                                return '{a|' + params.name + '}' + '\n{b|' + window.filters.chartAxisFormat(params.value, null, this.data.columnFormat[1]) + '} {c|(' + params.percent + '%)}';
                                // }
                                // return '{a|' + params.name + '}' + '\n{b|' +  window.filters.globalChartTooltipFormat(params.value, null, this.data.columnFormat[1]) + '}';
                            },
                            // formatter: '{b|{b}}\n${c}',
                            rich: {
                                a: {
                                    color: '#555',
                                    // lineHeight: 22,
                                    fontWeight: 'bold'
                                    // align: 'center'
                                },
                                b: {
                                    color: '#20558a',
                                    fontWeight: 'bold',
                                    fontSize: 12
                                    // lineHeight: 33
                                },
                                c: {
                                    color: '#555',
                                    fontSize: 12
                                    // fontSize: 16,
                                    // lineHeight: 33
                                }
                            }
                        },
                        labelLine: {
                            lineStyle: {
                                color: '#ddd'
                            }
                            // show: !!isExport
                        }
                    }];

                    $(window).resize(() => {
                        if (this[chartOptions].series[0].label.show !== this.$parent.$parent.isLargeScreen) {
                            this[chartOptions].series[0].label.show = this.$parent.$parent.isLargeScreen;

                            this.$nextTick(() => {
                                if (this.$refs.chart) {
                                    this.$refs.chart.eChart.setOption({
                                        series: {
                                            label: {
                                                show: this.$parent.$parent.isLargeScreen && !this.miniMode
                                            },
                                            labelLine: {
                                                // lineStyle: {
                                                //     color: '#ddd'
                                                // }
                                                show: this.$parent.$parent.isLargeScreen && !this.miniMode
                                            },
                                            emphasis: {
                                                label: {
                                                    show: this.$parent.$parent.isLargeScreen && !this.miniMode
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });

                    break;
                }
            }

            // pre-loads images for export (image was not loading in time on first export)
            this[chartOptions].graphic = {
                elements: [
                    {
                        type: 'image',
                        style: {
                            image: window.baseURL + '/static/img/logo-hhs.png',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            // invisible: true
                        }
                    },
                    {
                        type: 'image',
                        style: {
                            image: window.baseURL + '/static/img/logo-nih-oer.png',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            // invisible: true
                        }
                    }
                ]
            };

            if (isExport) {
                let legendHeight,
                    reportLinkText = 'Data for this report are available at NIH Data Book - ' + this.getReportURL();

                this.exportChartOptions.animation = false;

                if (!this.exportChartOptions.title) {
                    this.exportChartOptions.title = [];
                }

                // this.exportChartOptions = copyData(this.chartOptions);

                this.exportChartOptions.title.push({
                    text: this.insertLineBreaks(this.title, this.$refs.chart.exportImageWidth, '600 22px Roboto'),
                    top: 0,
                    left: -4,
                    textStyle: {
                        fontSize: 22,
                        fontWeight: '600',
                        fontFamily: 'Roboto',
                        color: '#102f68'
                    }
                });

                legendHeight = 14 + (22 * this.exportTitleLines);

                if (this.activeDataSet && chartType !== 'compoundBar/area') {
                    this.exportChartOptions.title.push({
                        text: (this.activeDataSetSecondary ? this.activeDataSetSecondary + ', ' : '') + this.activeDataSet,
                        top: legendHeight + 12,
                        left: -4,
                        textStyle: {
                            fontSize: 18,
                            fontWeight: '600',
                            fontFamily: 'Roboto',
                            color: '#102f68'
                        }
                    });

                    legendHeight += 22;
                }

                this.exportChartOptions.title.push({
                    text: reportLinkText,
                    bottom: 0,
                    right: 0,
                    // left: this.$refs.chart.exportImageWidth - reportLinkWidth - 10,
                    textStyle: {
                        fontSize: 12,
                        fontWeight: '600',
                        fontFamily: 'Arial',
                        color: '#888'
                    }
                });

                // this.exportChartOptions.title.push({
                //     text: this.getReportURL(),
                //     bottom: 0,
                //     right: -4,
                //     textStyle: {
                //         fontSize: 12,
                //         fontWeight: '600',
                //         color: '#888'
                //     }
                // });

                // if more titles exist than the added chart
                // title and the footer URL; push down first
                // title to make space for chart title
                if (this.exportChartOptions.title.length > 3) {
                    // this.exportChartOptions.title[0].top = 35;
                    this.exportChartOptions.title[0].top = legendHeight + 20;
                }

                if (!!this.chartOptions.grid.top) {
                    if (Number(this.chartOptions.grid.top)) {
                        this.exportChartOptions.grid.top = Number(this.chartOptions.grid.top) + 35 + legendHeight;
                    } else {
                        this.exportChartOptions.grid.top = 45 + legendHeight;
                    }
                } else {
                    this.exportChartOptions.grid[0].top = this.chartOptions.grid[0].top + 35 + legendHeight;
                }

                if (!!this.chartOptions.grid.bottom) {
                    if (Number(this.chartOptions.grid.bottom)) {
                        this.exportChartOptions.grid.bottom = Number(this.chartOptions.grid.bottom) + 28;
                    } else {
                        this.exportChartOptions.grid.bottom = 28;
                    }
                } else {
                    this.exportChartOptions.grid[this.exportChartOptions.grid.length - 1].bottom = this.chartOptions.grid[this.exportChartOptions.grid.length - 1].bottom + 28;
                }

                if (chartType !== 'compoundBar/area' && chartType !== 'pie' && this.legend.all.length > 1) {
                    this.exportChartOptions.legend = {
                        // x: 'left',
                        data: this.legend.all,
                        show: true,
                        itemWidth: 14,
                        // top: Number(this.exportChartOptions.grid.top - (legendHeight * 1.5))
                        top: Number(this.exportChartOptions.grid.top - 26),
                    };
                }

                this.exportChartOptions.graphic = {
                    elements: [
                        {
                            type: 'image',
                            style: {
                                image: window.baseURL + '/static/img/logo-hhs.png',
                                // x: this.$refs.chart.exportImageWidth - 210,
                                x: 6,
                                y: this.$refs.chart.exportImageHeight - 37,
                                width: 32,
                                height: 32
                            }
                        },
                        {
                            type: 'image',
                            style: {
                                image: window.baseURL + '/static/img/logo-nih-oer.png',
                                // x: this.$refs.chart.exportImageWidth - 210,
                                x: 45,
                                y: this.$refs.chart.exportImageHeight - 37,
                                width: 205,
                                height: 32
                            }
                        }
                    ]
                };
            }
        },

        insertLineBreaks (text, maxWidth, textFormat) {
            let textArray,
                textLine,
                finalText = '';

            if (window.getTextWidth(text, textFormat) > maxWidth) {
                textArray = text.split(' ');
                textLine = '';

                this.exportTitleLines = 1;

                for (let i = 0; i < textArray.length; i++) {
                    if (window.getTextWidth(textLine + ' ' + textArray[i], textFormat) < maxWidth) {
                        textLine += ' ' + textArray[i];
                    } else {
                        finalText += textLine + '\n';
                        textLine = textArray[i];

                        this.exportTitleLines++;
                    }
                }

                finalText += textLine + '\n';

                return finalText.trim();
            }

            return text.trim();
        },

        awardsTrendLabel (chartOptions) {
            let selectedLength = this.selectedBarIndices.length,
                labelText,
                // labelTextArray,
                textFormat = '600 16px Roboto',
                // tempTextWidth,
                // textWidth,

                insertLineBreaks = (text, maxWidth) => {
                    let textArray,
                        textLine,
                        finalText = '';

                    if (window.getTextWidth(text, textFormat) > maxWidth) {
                        textArray = text.split(' ');
                        textLine = '';

                        for (let i = 0; i < textArray.length; i++) {
                            if (window.getTextWidth(textLine + ' ' + textArray[i], textFormat) < maxWidth) {
                                textLine += ' ' + textArray[i];
                            } else {
                                finalText += textLine + '\n';
                                textLine = textArray[i];
                            }
                        }

                        finalText += textLine + '\n';

                        return finalText.trim();
                    }

                    return text.trim();
                };

            if (selectedLength === 0 || selectedLength === this.data.data.length - 2) {
                return (this.activeDataSet ? this.activeDataSet : this.data.chartAxesLabels[1].y) + ' Trend ' + this.data.data[this.data.data.length - 1][0];
            } else {
                labelText = (this.activeDataSet ? this.activeDataSet : this.data.chartAxesLabels[1].y) + ' Trend for ' + this.getSelectedNames(this.selectedBarIndices).join(', ');

                labelText = labelText.replace(/\n/g, ' ');

                labelText = insertLineBreaks(labelText, this.$refs.chart.eChart.getWidth());

                this.dynamicLabelLineCount = (labelText.match(/\n/g) || []).length;

                this.$nextTick(() => {
                    if (this[chartOptions].grid[1]) {
                        this.$refs.chart.eChart.setOption({
                            grid: [
                                this[chartOptions].grid[0],
                                {
                                    top: (this.$refs.chart.eChart.getHeight() * 0.58) + (this.dynamicLabelLineCount * 16),
                                    right: this[chartOptions].grid[1].right,
                                    bottom: this[chartOptions].grid[1].bottom,
                                    left: this[chartOptions].grid[1].left
                                }
                            ]
                        });
                    }
                });


                return labelText;
            }
        },

        clickTopChart (chartOptions, params) {
            if (params) {
                if (this.selectedBarIndices.length === this.data.data.length - 2) {
                    this.selectedBarIndices = [params.dataIndex];
                } else {
                    if (this.selectedBarIndices.indexOf(params.dataIndex) === -1) {
                        this.selectedBarIndices.push(params.dataIndex);
                    } else {
                        this.selectedBarIndices.splice(this.selectedBarIndices.indexOf(params.dataIndex), 1);

                        if (this.selectedBarIndices.length === 0) {
                            this.selectedBarIndices = copyData(this.allBarIndices);
                        }
                    }
                }
            }

            this[chartOptions].series = this.getChartSeries();

            this.$nextTick(() => {
                this.$refs.chart.eChart.setOption({
                    // series: this.getMultiSeriesData(this.selectedBarIndices)
                    series: this.getChartSeries(),
                    title: [
                        { text: (this.activeDataSet ? this.activeDataSet : this.data.chartAxesLabels[0].y) + ' for ' + this.selectedName },
                        { text: this.awardsTrendLabel(chartOptions) }
                    ]
                });
            });
        },

        resetChart () {
            if (this.selectedBarIndices && this.allBarIndices) {
                this.selectedBarIndices = copyData(this.allBarIndices);
                this.clickTopChart('chartOptions');
            }
        },

        getReportURL () {
            let href = location.href.toString();

            if (href.indexOf('/category/') === href.length - ('/category/' + this.$route.params.categoryId).length) {
                href = href.replace('/category/' + this.$route.params.categoryId, '/');
            }

            if (href.indexOf('/report/') === href.length - ('/report/' + this.reportId).length) {
                return href;
            } else if (href.indexOf('/report/') !== -1 && href.indexOf('?') !== 1) {
                return href;
            } else if (href.indexOf('/report/') === -1) {
                return href + 'report/' + this.reportId;
            }
        },

        exportChart () {
            // this.$refs.graph.saveGraphImage();
            if (this.$refs.chart && this.$refs.chart.buildExportChart) {
                this.$refs.chart.buildExportChart();
            } else {
                bus.$emit('lrpChart:export', this.reportId);   
            }
        },

        setUpExport (isModal) {
            this.isModal = isModal;
            this.$nextTick(() => {
                TableExport.prototype.typeConfig.date.assert = function () {
                    return false;
                };

                TableExport.prototype.formatConfig.xlsx = {
                    defaultClass: 'xlsx dropdown-item',
                    buttonContent: 'Export Data as Excel',
                    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    fileExtension: '.xlsx'
                };

                // TableExport.prototype.formatConfig.csv = {
                //     defaultClass: 'csv dropdown-item',
                //     buttonContent: 'Export Data as CSV',
                //     mimeType: 'application/csv',
                //     fileExtension: '.csv'
                // };
            });

            this.$nextTick(() => {
                this.initExportTable();
            });
        },

        initExportTable () {
            var widget = $("#report-" + this.reportId),
                todaysDate = new Date(),
                formatedDate = ("0" + (todaysDate.getMonth() + 1)).slice(-2) + ("0" + todaysDate.getDate()).slice(-2) + todaysDate.getFullYear();

            if (this.tableExportObj) {
                this.tableExportObj = null;
                if (!this.isModal) {
                    widget.find('.dropDownCaption').remove();
                }
            }

            this.tableExportObj = $('#report-' + this.reportId + '-data table').tableExport({
                position: 'top',
                bootstrap: true,
                filename: 'NDB_Report_ID_' + this.reportId + '_exp_' + formatedDate,
                formats: ['xlsx']
            });

            this.$nextTick(() => {
                this.$nextTick(() => {
                    // widget.find('.dropdown-menu.dropdown-menu-right.widget-level').find('.xlsx, .csv').remove();
                    var exportButtons = widget.find('table .tableexport-caption').addClass('dropDownCaption'),
                        exportMenu;

                    if (this.isModal) {
                        exportMenu = widget.find('.modal-body .dropdown-menu.dropdown-menu-right');
                    } else {
                        exportMenu = widget.find('.dropdown-menu.dropdown-menu-right.widget-level');
                    }

                    // if (exportMenu.find('.xlsx, .csv').length === 0) {
                    if (exportMenu.find('.xlsx').length === 0) {
                        exportMenu.prepend(exportButtons);

                        this.$nextTick(() => {
                            exportMenu.find('.xlsx').click(() => {
								gtag('event','Click', {
									'event_category': 'Report ' + this.reportId,
									'event_label':  'Export Data XLSX',
									'value':  'Export Data XLSX'
								});	
                            });                            

                        });
                    }
                    $('#report-' + this.reportId + '-data table').find('caption.dropDownCaption').remove();
                });
            });
        },

        clickDataView () {
            let $this = this;
            this.setUpPopupExport();
            if (!this.LRPReport) {
                setTimeout(() => {
                    this.$refs.dataTable.updateAfterModalOpen();
                }, 301);
            }
        },

        setUpPopupExport () {
            var widget = $('#report-' + this.reportId);

            if (widget.find('.modal-body .tableexport-caption').length === 0) {
                this.setUpExport(true);
            }
        },

        clickChartView () {
            let $this = this;
            this.setUpPopupExport();
            if (this.LRPReport) {
                setTimeout(() => {
                    this.$refs.lrpChart.reDrawChart(this.reportId, 0, false, false);
                }, 301);
            }
        },
    }
};
