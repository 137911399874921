<template>
    <div id="retired-report-view" class="page">
        <h1>Retired Report</h1>
        <p>We're sorry, the report you are looking for has been retired and is no longer available.</p>
    </div>
</template>

<script>
export default {
    name: 'RetiredReport'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
