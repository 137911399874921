<template>
    <div id="report-index-view" class="page">
        <h1>Report Index</h1>
        <!-- <div class="row">
            <div class="col-lg-7 sort-header">Sort By</div>
            <div class="col-lg-5 sort-header">Filter Reports</div>
        </div> -->
        <div class="row">
            <div class="col-auto me-3">
                <div class="sort-header">Sort By</div>
                <div class="btn-toggle-wrapper d-inline-block">
                    <button class="btn-toggle" :class="{'selected': sortField === 'ReportId', 'desc': !sortOrder && sortField === 'ReportId', 'asc': sortOrder && sortField === 'ReportId'}" @click.prevent="sortReports('ReportId')">Report ID <i class="fas fa-angle-up arrow"></i></button><button class="btn-toggle" :class="{'selected': sortField === 'ReportName', 'desc': !sortOrder && sortField === 'ReportName', 'asc': sortOrder && sortField === 'ReportName'}" @click.prevent="sortReports('ReportName')">Report Title <i class="fas fa-angle-up arrow"></i></button>
                </div>
            </div>
            <div class="filter-col">
                <div class="sort-header">Filter Reports</div>
                <div class="filter-wrapper">
                    <input type="text" v-model="searchText" name="filterby" class="filterby" />
                    <button class="search-button" @click.prevent="goToReport()">
                        <i class="fas fa-search search-icon text-secondary p-1"></i>
                        <span class="sr-only">Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-for="(report, index) in filterReports(reportList)" :key="index" class="report-list mt-3 pt-3">
            <a
				rel="noopener"
                class=""
                :href="baseURL + '/report/' + report.ReportId"
                v-html="report.ReportName"
                @click.prevent="loadReport(report.ReportId)">
            </a>
            <!-- <div class="report-info">NIH Data Book Report ID: <span>{{report.ReportId}}</span></div> -->
            <div class="report-info">NIH Data Book Report ID: <span v-html="report.ReportDisplayId"></span></div>
            <div class="report-info">NIH Data Book Categor{{report.ReportCategories.length === 1 ? 'y' : 'ies'}}: <span v-for="(cat, subIndex) in getUnique(report.ReportCategories)" :key="subIndex">
                <a :href="baseURL + '/category/' + cat.CategoryId" @click.prevent="loadReports(cat.CategoryId)" rel="noopener">{{cat.CategoryName}}</a><span v-if="subIndex + 1 < report.ReportCategories.length"></span></span>
            </div>
        </div>
        <div v-if="filterReports(reportList).length === 0" class="no-records">No matching reports found.</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
    name: 'reportIndex',
    data () {
        return {
            pageTitle: 'Report Index',
            searchText: '',
            reportList: [],
            sortOrder: false,
            sortField: null,
            baseURL: window.baseURL
        };
    },
    computed: {
        ...mapGetters([
            'categoryList'
        ])
    },
    watch: {
        categoryList: function () {
            setTimeout(() => {
                this.parseReports();
            }, 301);
        }
    },
    mounted () {
        document.title = this.$parent.pageTitle + ' - ' + this.pageTitle;

        if (this.reportList) {
            this.parseReports();
        }
    },
    methods: {
        getUnique (categories) {
            return _.uniqBy(categories, 'CategoryId');
        },
        loadReport: function (id) {
            this.$parent.loadReport(id);
        },
        loadReports: function (id) {
            this.$parent.$refs.sidebar.loadReports(id);
        },
        parseReports () {
            var reportExists,
                $self = this;

            $.each(this.categoryList, function (i, category) {
                if (category.Reports.length === 0) {
                    $.each(category.Categories, function (k, subCategory) {
                        $.each(subCategory.Reports, function (j, rep) {
                            reportExists = $self.reportList.filter(function (report) {
                                return report.ReportId === rep.ReportId;
                            });
                            if (reportExists.length === 0) {
                                $self.reportList.push({
                                    ReportName: rep.ReportName,
                                    ReportId: rep.ReportId,
                                    ReportDisplayId: rep.ReportId,
                                    ReportCategories: [{
                                        CategoryId: subCategory.CategoryId,
                                        CategoryName: subCategory.CategoryName
                                    }]
                                });
                            } else {
                                reportExists[0].ReportCategories.push({
                                    CategoryId: subCategory.CategoryId,
                                    CategoryName: subCategory.CategoryName
                                });
                            }
                        });
                    });
                } else {
                    $.each(category.Reports, function (j, rep) {
                        reportExists = $self.reportList.filter(function (report) {
                            return report.ReportId === rep.ReportId;
                        });
                        if (reportExists.length === 0) {
                            $self.reportList.push({
                                ReportName: rep.ReportName,
                                ReportId: rep.ReportId,
                                ReportDisplayId: rep.ReportId,
                                ReportCategories: [{
                                    CategoryId: category.CategoryId,
                                    CategoryName: category.CategoryName
                                }]
                            });
                        } else {
                            reportExists[0].ReportCategories.push({
                                CategoryId: category.CategoryId,
                                CategoryName: category.CategoryName
                            });
                        }
                    });
                }
            });

            this.sortReports('ReportId', true);
        },
        sortReports (sortField, sortOrder) {
            if (sortOrder === undefined) {
                this.sortOrder = this.sortField === sortField ? !this.sortOrder : true;
            } else {
                this.sortOrder = sortOrder;
            }

            this.sortField = sortField;
            this.reportList = _.orderBy(this.reportList, this.sortField, this.sortOrder ? 'asc' : 'desc');
        },
        filterReports (reportList) {
            var app = this,
                searchText = app.searchText.trim(),
                searchTextRegExp = new RegExp(searchText, 'ig'),
                tempReportName;

            reportList = copyData(this.reportList);
            if (searchText !== '') {
                if (isNaN(searchText)) {
                    // check against report title
                    return reportList.filter(function (report) {
                        if (report.ReportName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                            tempReportName = report.ReportName.replace(searchTextRegExp, function (str) {
                                return '<span class="search-highlight">' + str + '</span>';
                            });
                            report.ReportName = tempReportName;
                            return true;
                        }
                        return false;
                    });
                } else {
                    // check agaist report ID
                    return reportList.filter(function (report) {
                        if (report.ReportId.toString().indexOf(searchText.toString()) !== -1) {
                            tempReportName = report.ReportId.toString().replace(searchTextRegExp, function (str) {
                                return '<span class="search-highlight">' + str + '</span>';
                            });
                            report.ReportDisplayId = tempReportName;
                            return true;
                        }
                        return false;
                    });
                }
            }
            return reportList;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#report-index-view {
    .search-highlight {
        background-color: #ffe98c;
    }
    $toggle-radius: 4px;
    .btn-toggle-wrapper {
        border-radius: $toggle-radius $toggle-radius $toggle-radius $toggle-radius;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
    .btn-toggle {
        display: inline-block;
        padding: 4px 10px;
        font-size: 16px;
        background-color: #fff;
        border: none;
        outline: none;
        border-radius: $toggle-radius 0 0 $toggle-radius;
        cursor: pointer;
        + .btn-toggle {
            border-radius: 0 $toggle-radius $toggle-radius 0;
        }
        &.selected {
            color: #fff;
            background-color: $blue;
        }
        .arrow {
            display: none;
        }
        &.desc {
            .arrow {
                transform: rotate(180deg);
                display: inline;
                fill: #fff;
            }
        }
        &.asc {
            .arrow {
                display: inline;
                fill: #fff;
            }
        }
    }

    .filter-col {
        width: 500px;
    }
    .filter-wrapper {
        position: relative;
        .filterby {
            position: relative;
            width: 100%;
            border: none;
            border-radius: 3px;
            padding: 0.35em 0.5em;
            transition: 0.3s;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
            outline: none;
            z-index: 5;
            &:focus {
                // width: 300px;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
                ~ .search-button {
                    pointer-events: all;
                    svg {
                        fill: darken($category-header-background, 20%);
                    }
                    &:hover,
                    &:focus {
                        svg {
                            fill: $category-header-background;
                        }
                    }
                }
            }
        }
        .search-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px 6px;
            height: 31px;
            background: none;
            border: none;
            outline: none;
            pointer-events: none;
            z-index: 6;
            &.search-button-mobile {
                pointer-events: auto;
            }
            .search-icon {
                width: 23px;
                height: 23px;
                fill: $category-header-background;
                transition: 0.3s;
            }
        }
        
    }
    .sort-header {
        font-weight: bold;
        font-size: 18px;
        color: $grey-dark;
    }
    .report-list {
        a {
            font-size: 18px;
            font-weight: 500;
            text-decoration: none;
        }
        .report-info {
            color: $grey;
            font-size: 14px;
            font-weight: 600;
            span {
                // color: $grey;
                // font-weight: normal;
                a {
                    font-size: 14px;
                    color: $grey;
                    // text-decoration: underline;
                    text-decoration: none;
                    background-color: #fff;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
                    border-radius: 12px;
                    padding: 2px 6px;
                    margin-right: 10px;
                    line-height: 2.2em;
                    transition: 0.3s;
                    &:hover, &:focus {
                        // text-decoration: none;
                        color: $grey-dark;
                        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
    .no-records {
        color: $grey-medium;
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }
}
</style>
