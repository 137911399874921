<template>
    <div id="sidebar" :class="!this.$parent.sidebarVisible ? 'hide' : ''">
        <div class="loader" v-if="!categoryList">Loading...</div>
        <div class="sidebar-toggle-wrapper">
            <button class="sidebar-toggle" @click.prevent="userToggleSidebar()">
                Report Categories
                <i class="fas fa-chevron-up menu-icon"></i>
            </button>
        </div>
        <div class="sidebar-scroll">
            <div v-for="(category, index) in categoryList" :key="'category-' + index">
                <div class="category-header" :id="'heading'+category.CategoryId">
                    <div v-if="category.Categories.length > 0">
                        <a class="category"
							rel="noopener"
                            :href="baseURL + '/category/' + category.Categories[0].CategoryId"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#collapse' + category.CategoryId"
                            aria-expanded="false"
                            :aria-controls="'collapse' + category.CategoryId"
                            @click.prevent="loadReports(category.Categories[0].CategoryId, $event)"
                        >
                            <i class="fas fa-chevron-right arrow"></i>
                            <!-- @click.prevent="loadReports(category.CategoryId)" -->
                            {{category.CategoryName}}
                        </a>
                    </div>
                    <div v-else>

                        <a
							rel="noopener"
                            class="category"
                            :class="category.CategoryId === Number($route.params.categoryId) ? 'active' : ''"
                            :href="baseURL + '/category/' + category.CategoryId"
                            @click.prevent="loadReports(category.CategoryId, $event)"
                        >
                            <!-- <svgicon name="icon-arrow-right" class="arrow" alt="" /> -->
                            {{category.CategoryName}}
                        </a>
                        <!-- @click.prevent="loadReports(category.CategoryId)" -->
                    </div>
                </div>
                <div :id="'collapse'+category.CategoryId"
                    class="collapse"
                    :aria-labelledby="'heading' + category.CategoryId"
                    data-bs-parent="#sidebar"
                    v-if="category.Categories.length > 0"
                >
                    <!-- data-parent="#sidebar" -->
                    <div class="subcategory-list">
                        <div v-for="(subCat, index) in category.Categories" class="subcategory" :key="'subcategory-' + index">
                            <!-- <svgicon name="icon-arrow-right" class="arrow" alt="" /> -->
                            <a
                                class=""
                                :class="subCat.CategoryId === Number($route.params.categoryId) ? 'active' : ''"
                                :href="baseURL + '/category/' + subCat.CategoryId"
                                @click.prevent="loadReports(subCat.CategoryId, $event)"
								rel="noopener"
                            >
                                {{subCat.CategoryName}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="category-header secondary-header" v-if="categoryList">
                <a :href="baseURL + '/page/faq'" class="category"
                    :class="$route.params.page === 'faq' ? 'active' : ''"
                    @click.prevent="goToPage('faq')" rel="noopener">
                    FAQs
                </a>
            </div>
            <div class="category-header secondary-header" v-if="categoryList">
                <a :href="baseURL + '/page/historical-data-books'" class="category"
                    :class="$route.params.page === 'historical-data-books' ? 'active' : ''"
                    @click.prevent="goToPage('historical-data-books')" rel="noopener">
                    Historical Data Books
                </a>
            </div>
            <div class="category-header secondary-header" v-if="categoryList">
                <a :href="baseURL + '/page/report-index'" class="category"
                    :class="$route.params.page === 'report-index' ? 'active' : ''"
                    @click.prevent="goToPage('report-index')" rel="noopener">
                    Report Index
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import bus from '@/js/event-bus.js';

export default {
    name: 'sidebar',
    props: [
        'sidebarVisible'
    ],
    computed: {
        ...mapGetters([
            'categoryList',
            'LRPLookupURL',
            'LRPFilters'
        ])
    },
    data () {
        return {
            categoryRedirects: {
                '1': 1, // NIH Budget History
                '2': 6, // Research Grants: Research Project Grants
                '3': 8, // Research Grants: Research Project Grants
                '4': 10, // Success Rates and Funding Rates
                '5': 12, // NIH Peer Review
                '8': 14, // The NIH-Funded Research Workforce: Overview
                '9': 15, // The NIH-Funded Research Workforce: New NIH Investigators
                '10': 16, // The NIH-Funded Research Workforce: Data by Gender
                '11': 17, // The NIH-Funded Research Workforce: Career Development
                '12': 8, // Small Business Research (SBIR / STTR)
                '13': 10, // Success Rates and Funding Rates
                '14': 14, // The NIH-Funded Research Workforce: Overview
                '15': 16, // The NIH-Funded Research Workforce: Data by Gender
                '16': 17, // The NIH-Funded Research Workforce: Career Development
                '17': 18, // The NIH-Funded Research Workforce: Research Training Grants and Fellowships
                '19': 20, // National Statistics on Graduate Students
                '20': 21, // National Statistics on Postdoctorates
                '21': 19, // The NIH-Funded Research Workforce: Ph.D. Recipients
                '22': 15, // The NIH-Funded Research Workforce: New NIH Investigators
                '23': 12 // NIH Peer Review
            },
            baseURL: window.baseURL,
            currentFilters: {}
        };
    },
    mounted () {
        this.getFilterData();
        this.getSidebarData();

        this.$parent.sidebarVisible = !this.$route.params.id;
    },
    methods: {
        ...mapActions([
            'setCategoryList',
            'setLRPFilterOptions',
            'setGlobalFilters'
        ]),
        getSidebarData () {
            var handleData,
                url = window.serverless ? '/static/data/category.json' : (window.location.hostname === 'localhost' ?  'https://localhost:59971' :  window.location.origin +  '/reportweb') + '/api/category/get',
                categoryObj,
                self = this;

            /*--------------------------------------------------------------
            * Handle user service call and other calls in init services
            *-------------------------------------------------------------*/
            handleData = (data) => {
                this.setCategoryList(data);

                if (this.$route.query.catid && !this.$route.query.chartId && !this.$route.params.oldAspxPage) {
                    let catIdRedirect = this.getCategoryIdRedirect(this.$route.query.catid);

                    this.$nextTick(() => {
                        this.loadReports(catIdRedirect);
                        return false;
                    });
                }

                // once category list is loaded, check for category in URL
                if (this.$route.params.categoryId) {

                    this.categoryList.filter(function (cat) {
                        if (cat.Categories.length > 0) {
                            cat.Categories.filter(function (subCat) {
                                if (subCat.CategoryId === parseInt(self.$route.params.categoryId)) {
                                    categoryObj = subCat;
                                    return;
                                }
                            });
                            if (categoryObj) {
                                return;
                            }
                        } else {
                            if (cat.CategoryId === parseInt(self.$route.params.categoryId)) {
                                categoryObj = cat;
                                return;
                            }
                        }
                    });

                    if (categoryObj) {
                        document.title = this.$parent.pageTitle + ' - ' + categoryObj.CategoryName;
                        bus.$emit('dashboard:categoryId', this.$route.params.categoryId);

                        this.$nextTick(() => {
                            this.menuHighlight();
                        });
                    } else {
                        document.title = this.$parent.pageTitle;
                        this.$router.push({
                            path: '/page/report-not-found'
                        }).catch(()=>{});
                    }
                }
            };

            customAjax({
                service: {
                    name: 'category',
                    url: url,
                    type: 'get',
                    storeAction: 'setCategoryList'
                },
                callback: handleData
            });
            
        },
        getFilterData () {
            var handleData,
                timerCnt = 10,
                $this = this,
                baseURL = (window.location.hostname === 'localhost' ?  'https://localhost:59971' :  window.location.origin +  '/reportweb') + '/api/databook/',
                completedService = 0;

            handleData = (data, xhr) => {
                completedService++;
                let urlDetails = this.LRPLookupURL.filter(u => u.url === xhr.url.replace(baseURL, ''))[0],
                    filterName = urlDetails.name;
                this.setLRPFilterOptions({data: data, order: urlDetails.order, filterName: filterName, isExtramural: urlDetails.isExtramural});
                if (completedService === this.LRPLookupURL.length) {
                    this.setupDefaultFilters();
                }
            }
            let cnt = timerCnt * this.LRPLookupURL.length;
            for (let i = 0; i < this.LRPLookupURL.length; i++) {
                setTimeout(function () {
                    customAjax({
                        service: {
                            name: $this.LRPLookupURL[i].name,
                            url: baseURL + $this.LRPLookupURL[i].url,
                            type: 'get'
                        },
                        callback: handleData
                    });
                }, cnt);
                cnt = cnt - timerCnt;
            }
        },
        getCategoryIdRedirect (catId) {
            return this.categoryRedirects[catId];
        },
        menuHighlight () {
            let activeLink = $(this.$el).find('.active'),
                parentCategory = activeLink.closest('.collapse'),
                parentCategoryId = parentCategory.attr('id');

            if (parentCategory.length !== 0) {
                let bsCollapse = bootstrap.Collapse.getInstance($(this.$el).find('.collapse:not(#' + parentCategoryId + ')'));
                if (bsCollapse === null) {
                    bsCollapse = new bootstrap.Collapse($(this.$el).find('.collapse:not(#' + parentCategoryId + ')'), {
                        toggle: false   // this parameter is important!
                    })
                }
                bsCollapse.hide();

                $('#' + parentCategoryId).toggle('show');
            }
        },
        loadReports: function (catId, event) {
            var curEle = (event ? $(event.target) : null),
                categoryObj;

            this.categoryList.filter(function (cat) {
                if (cat.Categories.length > 0) {
                    cat.Categories.filter(function (subCat) {
                        if (subCat.CategoryId === parseInt(catId)) {
                            categoryObj = subCat;
                            return;
                        }
                    });
                    if (categoryObj) {
                        return;
                    }
                } else {
                    if (cat.CategoryId === parseInt(catId)) {
                        categoryObj = cat;
                        return;
                    }
                }
            });

            if (curEle && !curEle.attr('data-bs-target') && curEle.hasClass('category')) {
                let colEles = [].slice.call(document.querySelectorAll('.collapse:not(#applicationMsgInfo):not(#applicationMsgWarning):not(#applicationMsgError)'));
                var collapseList = colEles.map(function (collapseEl) {
                    let bsCollapse = bootstrap.Collapse.getInstance(collapseEl);
                    if (bsCollapse === null) {
                        bsCollapse = new bootstrap.Collapse(collapseEl, {
                            toggle: false   // this parameter is important!
                        })
                    }
                    bsCollapse.hide();
                })
            }
            if (window.isMobile) {
                this.$parent.showSidebar(false);
            }

            // push category ID to router for use of
            // browser back/forward buttons
            if (categoryObj) {
                document.title = this.$parent.pageTitle + ' - ' + categoryObj.CategoryName;
            } else {
                document.title = this.$parent.pageTitle;
            }
            this.$router.push({
                path: '/category/' + catId
            }).catch(()=>{});
        },
        goToPage: function (page) {
            if (window.isMobile) {
                this.$parent.showSidebar(false);
            }

            // push category ID to router for use of
            // browser back/forward buttons
            this.$nextTick(() => {
                document.title = this.$parent.pageTitle;
                this.$router.push({
                    path: '/page/' + page
                }).catch(()=>{});

                this.$parent.scrollToTop();
            });
        },
        userToggleSidebar () {
            this.$parent.sidebarVisible = !this.$parent.sidebarVisible;
            // this.$parent.sidebarUserToggled = true;

            if (this.$route.params.categoryId) {
				gtag('event','Click', {
					'event_category': 'Toggle Sidebar - Category View',
					'event_label': this.$parent.sidebarVisible ? 'Open' : 'Close',
					'value': 'Category View'
				});	
            } else if (this.$route.params.id) {
				gtag('event','Click', {
					'event_category': 'Toggle Sidebar - Report View',
					'event_label': this.$parent.sidebarVisible ? 'Open' : 'Close',
					'value': 'Report View'
				});	
            } else if (this.$route.params.page) {
				gtag('event','Click', {
					'event_category': 'Toggle Sidebar - Page View',
					'event_label': this.$parent.sidebarVisible ? 'Open' : 'Close',
					'value': 'Page View'
				});	
            }
            bus.$emit('lrpChart:resize');  
        },
        setupDefaultFilters () {
            var $this = this;

            for (let i = 0; i < $this.LRPFilters.length; i++) {
                let element = $this.LRPFilters[i];
                if (element.name === 'fy') {
                    $this.currentFilters[element.data[0].name] = element.data[0].options[0];
                    $this.currentFilters[element.data[1].name] = element.data[0].options[element.data[0].options.length - 1];
                } else {
                    $this.currentFilters[element.name] = element.options[0].id;
                }
            }
            $this.currentFilters['chartBy'] = 1;
            this.setGlobalFilters($this.currentFilters);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>

