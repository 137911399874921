// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'es6-promise/auto';
import $ from 'jquery'; // eslint-disable-line
import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import 'core-js/stable';

import './js/custom-libs';
import './js/sharedSpecs';
import './js/mobile-check';
import './js/global';
import './js/widgetMixins';
import './js/LRPChartMixins';

import {createApp, h} from 'vue';
import VueMarkdown from 'vue3-markdown-it';

import App from './App';
import router from './router';
import store from './js/store';
import bus from './js/event-bus.js';

import './js/filters';


// consumption model....see https://bootstrap-vue.org/docs#using-module-bundlers for other options including importing scss versions...
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';import { BootstrapVueNext } from 'bootstrap-vue-next';
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

/*-- Google Analytics --*/
let measurementId = '';
if (window.location.hostname === 'report.nih.gov') {
    measurementId = 'G-FVWVHV9MHE';
} else if (window.location.hostname === 'report.stage.era.nih.gov') {
    measurementId = 'G-36H5PRSJ05';
} else {
    measurementId = 'G-HQZ0SX75E5';
}

var script = document.createElement('script');
script.src = 'https://www.googletagmanager.com/gtag/js?id='+measurementId;
document.head.appendChild(script);
script = document.createElement('script');
script.innerHTML = "window.dataLayer = window.dataLayer || []; \
	function gtag(){dataLayer.push(arguments);}	\
	gtag('js', new Date());	 \
	gtag('config', '" + measurementId + "');";
document.head.appendChild(script);

export const EventBus = bus;
export const app = createApp({
    router,
    store,
    render: () => ( h(App)),
    async created () {
        
    },
}).use(router).use(BootstrapVueNext).use(VueMarkdown).use(store).mount('#app');

app.$options.$filters = window.filters;
