<template>
    <div>
        <ul class="legends" v-if="isLegendVisible()" ref="legend">
            <!-- $parent.chartOptions && $parent.legend.y && ($parent.legend.y.length > 1 || $parent.legend.y2.length > 0) && $parent.data.chartType !== 'compoundBar/area' && ($parent.data.chartType !== 'pie' || ($parent.data.chartType === 'pie' && !$parent.$parent.$parent.isLargeScreen && !miniMode)) && ($parent.data.chartType === 'bar/line' && !miniMode) -->
            <li v-for="(datum, $index) in $parent.legend.y" :key="$parent.reportId + 'y' + $index" :class="$parent.data.chartType !== 'line' && $parent.data.chartType !== 'area' && $parent.data.chartType !== 'pie' ? 'legend-bar' : ''">
                <button :class="$parent.unSelectedLegends.indexOf(datum.name) === -1 ? '' : 'unselected'" class="legend-item"  @click="toggleChartFilter(datum)" :key="$parent.reportId + '-graph-legend-item-' + $index">
                    <div class="color-swatch" :style="'background-color: ' + legendIconColor(datum, chartOptions.color[$index])"></div>
                    <span>{{datum.name}}</span>
                </button>
            </li>
            <li v-for="(datum, $index) in $parent.legend.y2" :key="$parent.reportId + 'y2' + $index" v-if="$parent.legend.y2">
                <button :class="$parent.unSelectedLegends.indexOf(datum.name) === -1 ? '' : 'unselected'" class="legend-item"  @click="toggleChartFilter(datum)" :key="$parent.reportId + '-graph-legend-item-' + $index">
                    <div class="color-swatch" :style="'background-color: ' + legendIconColor(datum, chartOptions.color[chartOptions.color.length - $index - 1])"></div>
                    <span>{{datum.name}}</span>
                </button>
            </li>
        </ul>
        <div :id="'report-' + this.$parent.reportId + '-chart'" class="chart" :class="$parent.data.chartType === 'compoundBar/area'? 'chart-large' : ''" ref="chart" v-if="!showNoData">
        </div>
        <div v-else="showNoData" class="noData">
            <div>Current filter selection of <span>{{$parent.activeDataSetSecondary}}</span> and <span>{{$parent.activeDataSet}}</span> does not have sufficient data.</div>
            <div>Please try another combination.</div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
    name: 'chart',
    props: [
        'id',
        'chartOptions',
        'chartAxisLabels',
        'sidebarVisible',
        'miniMode'
    ],
    data () {
        return {
            eChart: null,
            animate: true,
            resizeTimeout: null,
            exportChart: null,
            exportImageWidth: 1024,
            exportImageHeight: 768,
            chartId: null,
            chartOnly: this.$route.query.chartOnly,
            showNoData: false
        };
    },
    mounted () {
    },
    watch: {
        sidebarVisible: function () {
            setTimeout(() => {
                if (this.miniMode) {
                    this.$parent.$parent.sizeChart();
                } else {
                    this.resizeChart();
                }
            }, 301);
        }
    },
    methods: {
        isLegendVisible () {
            // make sure options and legend objects exist,
            // otherwise, do not show the legend
            if (this.$parent.chartOptions && this.$parent.legend.y && (this.$parent.legend.y.length > 1 || this.$parent.legend.y2.length > 0)) {

                // hide legend for some chart types,
                // and in specific scenarios
                switch (this.$parent.data.chartType) {
                    case 'compoundBar/area': {
                        return false;
                    }
                    case 'pie': {
                        if (this.$parent.$parent.$parent.isLargeScreen || this.miniMode) {
                            return false;
                        }
                    }
                    case 'bar/line':
                    case 'bar': {
                        if (this.miniMode) {
                            return false;
                        }
                    }
                }

                // the legend should show in most situations,
                // if it is not one of the exceptions above
                return true;
            } else {
                return false;
            }
        },
        initChart (id) {
            this.showNoData = false;

            this.$nextTick(() => {
                if (id) {
                    this.chartId = id;
                } else {
                    this.chartId = 'report-' + this.$parent.reportId + '-chart';
                }

                this.buildChart(this.chartId);

                window.addEventListener('resize', () => {
                    this.sizeChartOnlyView();
                    this.resizeChart();
                });

                // bind click events for compoundBar/area charts
                // do not bind for 'miniMode' chart on homepage
                if (this.$parent.data.chartType === 'compoundBar/area' && !this.miniMode) {
                    let tooltip,
                        chartContainer = $('#' + this.chartId),
                        canvas;

                    this.$nextTick(() => {
                        tooltip = chartContainer.children('div:last-of-type');
                        canvas = chartContainer.find('canvas');

                        canvas.off('click');
                        canvas.on('click', () => {
                            if (tooltip.css('display') !== 'none' && this.$parent.tooltipSeriesId === 'topChart') {
                                this.$parent.clickTopChart('chartOptions', {
                                    dataIndex: this.$parent.tooltipdataIndex + 1
                                });
                            }
							
							gtag('event','Click', {
								'event_category': 'Report ' + this.$parent.reportId,
								'event_label': 'Click Chart',
								'value': this.$parent.reportId
							});	
                        });
                    });
                }
            });
        },
        buildChart (id, isExport) {
            var chartDom = document.getElementById(id),
                thisChart;

            if (this.$parent.setGraphOptions) {
                this.$parent.setGraphOptions(isExport);
            }

            if (this.$parent.data.chartType === 'pie' && this.$parent.chartOptions.series && this.$parent.chartOptions.series.length > 0 && this.$parent.chartOptions.series[0].data.length === 0) {
                this.showNoData = true;
                return false;
            }

            if (isExport) {
                thisChart = echarts.init(chartDom);
            } else if (!this.eChart) {
                this.eChart = echarts.init(chartDom);
            }


            this.$nextTick(() => {
                if (isExport) {
                    thisChart.setOption(this.$parent.exportChartOptions, true);
                } else {
                    this.eChart.setOption(this.$parent.chartOptions, true);

                    setTimeout(() => {
                        this.sizeChartOnlyView();
                    }, 100);
                }
            });

            return thisChart;
        },
        destroyChart () {
            if (this.eChart) {
                this.eChart.dispose();
            }
            this.eChart = null;
        },
        sizeChartOnlyView () {
            if (this.chartOnly) {
                if (this.$refs.legend && this.$refs.chart) {
                    this.$refs.chart.style.height = (window.innerHeight - this.$refs.legend.clientHeight) + 'px';

                    this.resizeChart();
                }
            }
        },
        resizeChart () {
            this.$nextTick(() => {
                if (this.animate) {
                    this.eChart.setOption({
                        animation: false
                    });
                }
            });

            try {
                this.eChart.resize();
            } catch (err) {

            }

            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                this.animate = true;

                this.eChart.setOption({
                    animation: true
                });
            }, 300);
        },
        legendIconColor (name, color) {
            if (this.$parent.unSelectedLegends.indexOf(name) === -1) {
                return color;
            } else {
                return '';
            }
        },
        toggleChartFilter (selectedName) {
            var name = !!selectedName.name ? selectedName.name : selectedName,
                index = this.$parent.unSelectedLegends.indexOf(name);

            // if category is selected
            if (index !== -1) {
                this.$parent.unSelectedLegends.splice(index, 1);
            } else {
                this.$parent.unSelectedLegends.push(name);
            }

            this.$parent.resetFormatter();
            this.$nextTick(() => {
                this.eChart.dispatchAction({
                    type: 'legendToggleSelect',
                    name: name
                });
            });

			gtag('event','Click', {
				'event_category': 'Report ' + this.$parent.reportId,
				'event_label': 'Chart Legend',
				'value': this.$parent.reportId
			});	

        },
        buildExportChart () {
            var exportDiv = document.createElement('div'),
                exportDivId = 'export-chart';

            exportDiv.setAttribute('id', exportDivId);
            exportDiv.style.width = this.exportImageWidth + 'px';
            exportDiv.style.height = this.exportImageHeight + 'px';
            exportDiv.style.position = 'fixed';
            exportDiv.style.top = '200%';
            exportDiv.style.opacity = '0';

            document.body.appendChild(exportDiv);

            this.exportChart = this.buildChart(exportDivId, true);

            setTimeout(() => {
                if (this.$parent.legend.all.length !== this.$parent.unSelectedLegends.length) {
                    for (let i = 0; i < this.$parent.unSelectedLegends.length; i++) {
                        this.exportChart.dispatchAction({
                            type: 'legendToggleSelect',
                            name: this.$parent.unSelectedLegends[i]
                        });
                    }
                }
                this.saveChartImage();
            }, 200);
        },
        saveChartImage () {
            var dataURL,
                downloadButton;

            dataURL = this.exportChart.getDataURL({
                pixelRatio: 2,
                type: 'png',
                backgroundColor: '#fff'
            });

            if (isIE) {
                dataURL = this.exportChart.getDataURL();
                top.consoleRef = window.open('', 'Chart Download', 'width=1024, height=768, menubar=0, toolbar=1 , status=0, scrollbars=1, resizable=1');
                top.consoleRef.document.writeln('<html><head><title>' + this.$parent.title + '</title></head><body bgcolor=white onLoad=\'self.focus()\'><p>Please right click on image and select <b>Save picture as...</b> to save.</p><img src=\'' + dataURL + '\'/></body></html>');
                top.consoleRef.document.close();
            } else {
                downloadButton = document.createElement('a');
                downloadButton.setAttribute('id', 'chart-export-button');
                downloadButton.setAttribute('download', 'NIH_Data_Book_Report_' + this.$parent.reportId + '.png');
                downloadButton.setAttribute('href', dataURL);
                downloadButton.style.opacity = '0';
                document.body.appendChild(downloadButton);

                downloadButton.click();
                downloadButton.remove();

            }

            this.$nextTick(() => {
                if (this.$parent.formatTimer) {
                    clearTimeout(this.$parent.formatTimer);

                    this.$parent.isFormattedExport = false;
                }

                this.exportChart.dispose();
                document.getElementById('export-chart').remove();
				
				gtag('event','Click', {
					'event_category':  'Report ' + this.$parent.reportId,
					'event_label': 'Export Chart PNG',
					'value':  this.$parent.reportId
				});	
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .legends {
        margin-bottom: 4px;
        padding: 0;
        list-style: none;
        .legend-bar {
            .circle {
                height: 1.2rem;
                width: 1.2rem;
                position: absolute;
                top: 2px;
                left: 4px;
            }
            .color-swatch {
            top: 3px;
            left: 5px;
            height: 15px;
            width: 15px;
            border-radius: 3px;
            }
        }
        li {
            display: inline-block;
            position: relative;
            font-size: 13px;
            .legend-item {
                display: inline-block;
                border: none;
                background-color: transparent;
                padding: 1px 4px 2px;
                span {
                    padding-left: 20px;
                    padding-right: 3px;
                }

                &.unselected {
                    color: #bbb;
                    svg {
                        fill: #999;
                    }
                    .color-swatch {
                        background-color: #999 !important;
                    }
                }
                &:hover, &:focus {
                    outline: none;
                    background-color: #eee;
                    border-radius: 5px;
                }
            }
            .circle {
                height: 1.2rem;
                width: 1.2rem;
                position: absolute;
                top: 2px;
                left: 4px;
            }
            .color-swatch {
                position: absolute;
                top: 3px;
                left: 5px;
                height: 16px;
                width: 16px;
                border-radius: 50%;
            }
        }
    }
</style>

