<template>
    <div class="header-container">
        <template v-if="applicationMsg">
            <b-collapse class="collapse application-msg" visible id="applicationMsgInfo">
                <template v-for="(msg, ind) in applicationMsg.Info" v-if="applicationMsg.Info && applicationMsg.Info.length > 0 && applicationMsg.Info[0].message !== ''">
                    <button v-b-toggle.applicationMsgInfo type="button" aria-label="Close" class="close" @click="adjustHeight"><span aria-hidden="true">×</span></button>
                    <div class="alert m-0 alert-info" :class="getClass(ind, applicationMsg.Info.length)" role="alert" v-if="msg.message" v-html="msg.message">
                    </div>
                </template>
            </b-collapse>
            <b-collapse class="collapse application-msg" visible id="applicationMsgWarning">
                <template v-for="(msg, ind) in applicationMsg.Warning" v-if="applicationMsg.Warning && applicationMsg.Warning.length > 0 && applicationMsg.Warning[0].message !== ''">
                    <button v-b-toggle.applicationMsgWarning type="button" aria-label="Close" class="close" @click="adjustHeight"><span aria-hidden="true">×</span></button>
                    <div class="alert m-0 alert-warning"
                    :class="getClass(ind, applicationMsg.Warning.length)" role="alert" v-if="msg.message" v-html="msg.message">
                    </div>
                </template>
            </b-collapse>
            <b-collapse class="collapse application-msg" visible id="applicationMsgError">
                <div v-for="(msg, ind) in applicationMsg.Error" v-if="applicationMsg.Error && applicationMsg.Error.length > 0 && applicationMsg.Error[0].message !== ''">
                    <button v-b-toggle.applicationMsgError type="button" aria-label="Close" class="close" @click="adjustHeight"><span aria-hidden="true">×</span></button>
                    <div class="alert m-0 alert-danger" :class="getClass(ind, applicationMsg.Error.length)" role="alert" v-if="msg.message" v-html="msg.message">
                    </div>
                </div>
            </b-collapse>
        </template>
        <div id="header">

            <a :href="parentURL ? parentURL : '/'">
                <img src="static/img/nih-logo.png" alt="NIH" class="logo"/>
                <span class="link-text">RePORT</span>
            </a>
            <a :href="baseURL ? baseURL : '/'">
                <i class="fas fa-chevron-right divider-arrow"></i>
                <span class="link-text">NIH Data Book</span>
            </a>
            <search v-if="categoryList"></search>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Search from '@/components/Search';
export default {
    name: 'Header',
    components: {
        Search
    },
    computed: {
        ...mapGetters([
            'categoryList'
        ])
    },
    data () {
        return {
            baseURL: window.baseURL,
            parentURL: window.parentURL,
            applicationMsg: null
        };
    },
    mounted () {
        this.getAppSettings();
        this.setHeight();
        $(window).resize(() => {
            this.setHeight();
        });
    },
    methods: {
        getAppSettings () {
            customAjax({                
                service: {
                    name: 'category',
                    url: window.baseURL + '/static/config/appsettings.json',
                    type: 'get'
                },
                callback: this.handleData
            });
        },
        handleData (data) {
            if (data) {
                this.applicationMsg = data.ApplicationMessage;
            }
            this.$nextTick(() => {
                this.setHeight();
            });
        },
        setHeight () {
            var appMsg = $(".header-container"),
                sidebar = $("#sidebar"),
                content = $("#content");

            if (appMsg && appMsg.length > 0) {
                sidebar.css({'top' : appMsg.height()});
                content.css({'padding-top' : appMsg.height()});
            }
        },
        getClass (ind, totalCnt) {
            if (totalCnt === 1) {
                return '';
            } else {
                if (ind === 0) {
                    return 'border-bottom-0';
                } else {
                    return 'border-top-0';
                }
            }
        },
        adjustHeight () {
            var header = $("#header"),
                sidebar = $("#sidebar"),
                content = $("#content");

            if (header && header.length > 0) {
                sidebar.css({'top' : header.outerHeight()});
                content.css({'padding-top' : header.outerHeight()});
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
