<template>
    <div class="d3ChartContainer position-relative">
        <div class="text-center  my-2" v-show="$parent.loadWidgetData">
            <b-spinner class="align-middle"></b-spinner>
        </div>
        <div :id="'d3idContainer' + id" v-show="!$parent.loadWidgetData">
            <div :id="'d3id' + id"></div>
            <div class="notes"></div>
        </div>
        <div :id="'d3idCard' + currentReportId" v-show="!$parent.loadWidgetDat && isCardDataParsed"></div>
    </div>  
</template>
   
<script>
import * as d3 from 'd3';
import LRPChartMixins from '../js/LRPChartMixins';
import EventBus from '@/js/event-bus.js';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "LRPBaseChart",
    mixins: [LRPChartMixins],
    data () {
        return {
            tooltip: null,
            data: null,
            tabId: null,
            currentReportId: null,
            chartData: [],
            noOfDrawnLines: 0,
            clickableLegends: false,
            selectionLimit: 0,
            isCardDataParsed: false
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        tabdata: {
            type: Object,
            required: true
        },
        reportId: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'LRPReportData',
            'chartSelection'
        ])
    },
    mounted () {        
        this.currentReportId = this.reportId;
        let tabId = $("#report-" + this.reportId + " .tab-pane.active").attr("data-tabid");
        this.reDrawChart(this.reportId, tabId, false, false);
        EventBus.$off('lrpreport:redraw');
        EventBus.$on('lrpreport:redraw', (reportId) => {
            let tabId = $("#report-" + reportId + " .tab-pane.active").attr("data-tabid");
            this.reDrawChart(reportId, tabId, false, false);
        });

        EventBus.$off('lrpreport:tabChanged');
        EventBus.$on('lrpreport:tabChanged', (reportId, tabId) => {
            this.setChartSelection({reportId: reportId, data: null});
            d3.select("#d3id-"+ reportId + '-' + this.tabId+ " svg").remove();
            this.reDrawChart(reportId, tabId, false, false);
        });

        $(window).resize(() => {
            let tabId = $("#report-" + this.reportId + " .tab-pane.active").attr("data-tabid");
            this.reDrawChart(this.reportId, tabId, false, true);
        });

        EventBus.$on('lrpChart:resize', () => {
            let tabId = $("#report-" + this.reportId + " .tab-pane.active").attr("data-tabid"),
                $this = this;

            setTimeout(() => {
                $this.reDrawChart($this.reportId, tabId, false, true);    
            }, 500);
                
        });

    },
    methods: {
        ...mapActions([
            'setChartSelection'
        ]),
    /*
        Method to add Bar
    */
    reDrawChart (reportId, tabId, isExport, isResize = false) {       
        this.isCardDataParsed = false;
        this.currentReportId = reportId;
        let repInd = this.LRPReportData.findIndex((obj => obj.reportId === reportId)),
            curRep = repInd !== -1 ? this.LRPReportData[repInd].data : [];

        this.tabId = tabId ? tabId : 0;
        let containerInfo = isExport ? copyData(this.chartOptions.exportContainerInfo) : copyData(this.chartOptions.containerInfo); 
        
        if (curRep && curRep.tabs  && curRep.tabs.length > 0) {
            this.chartData = [];
            let widgetCharts = curRep.tabs[this.tabId].charts,
                container;
            
            this.data = curRep.data;

            if (widgetCharts[0].chartType === 'Card') {
                this.displayCard(widgetCharts[0]);
            } else {

                if (isExport) {
                    d3.select("#export-div svg").remove();
                    container = d3.select("#export-div");
                } else {
                    d3.select("#d3id-"+ reportId + '-' + this.tabId+ " svg").remove();
                    container = d3.select('#d3id-'+ reportId + '-' + this.tabId);
                }

                if (isResize) {
                    containerInfo.width = $(".chart-container").first().width()-20;
                }

                if (isExport && widgetCharts.length > 2) {
                    containerInfo.height = containerInfo.totalHeight/2; 
                }

                containerInfo.width = containerInfo.width ? containerInfo.width : $(".chart-container").first().width()-20;
                let graphWidth = (containerInfo.width/ (widgetCharts.length > 1 ? 2 : 1)) - containerInfo.left - containerInfo.right,
                    graphHeight = containerInfo.height + containerInfo.top + containerInfo.bottom + (widgetCharts.length == 2 ? (containerInfo.xAxisLabelOffset + containerInfo.xAxisLabelHeight) : 0),
                    containerHeight,
                    containerWidth = containerInfo.width + containerInfo.xAxisLabelHeight;

                containerHeight = widgetCharts.length > 2 ? ((containerInfo.height + containerInfo.top + containerInfo.bottom+ containerInfo.xAxisLabelHeight) * widgetCharts.length/2) - containerInfo.xAxisLabelHeight : graphHeight;
                graphHeight = isExport && widgetCharts.length  > 2 ? graphHeight/2 - 20 : graphHeight;
                containerInfo.graphWidth = graphWidth;

            let svgContainer = container
                .append("svg")
                .style("background", "#fff")
                .attr("width", containerWidth )
                .attr("height", containerHeight - containerInfo.xAxisLabelHeight);
            
            let offsetLeft = containerInfo.left;
            let offsetTop = containerInfo.top;
            
            if (this.$route.params.id) {
                containerInfo.barPadding = .65;
                if (widgetCharts.length > 1) {
                    containerInfo.barPadding = .5;
                }
            }

            if (!isExport) {
                this.setupTooltip(container);
            }
            
            let xAxisLabelWidth =   this.getTextWidth(widgetCharts[0].xAxisLabel);
            svgContainer.append("text")
                .attr("class", "xAxisLabel")
                .attr("style", "font-weight: bold; fill: gray; font-size:"+containerInfo.fontSize)
                .attr("text-anchor", "end")
                .attr("x", containerWidth/2+xAxisLabelWidth/2)
                .attr("y", function () {return  isExport ? containerHeight - containerInfo.xAxisLabelHeight - 20: containerHeight - containerInfo.xAxisLabelHeight})
                .text(widgetCharts[0].xAxisLabel);

            let yAxisLabelWidth =   this.getTextWidth(widgetCharts[0].yAxisLabel);
            svgContainer.append("text")
                .attr("class", "yAxisLabel")
                .attr("style", "font-weight: bold; fill: gray; font-size:"+containerInfo.fontSize)
                .attr("text-anchor", "end")
                .attr("y", function () {return  isExport ? 20 : 12} )
                .attr("x", -(((containerHeight - containerInfo.xAxisLabelHeight) - yAxisLabelWidth)/2))
                .attr("transform", "rotate(-90)")
                .text(widgetCharts[0].yAxisLabel);

                for (let i = 0; i < widgetCharts.length; i++) {
                    this.chartData.push(copyData(widgetCharts[i]));
                    offsetTop = containerInfo.top;
                    offsetLeft = containerInfo.left;
                    if (i%2 == 1) {
                        offsetLeft = containerInfo.left + containerInfo.right + containerInfo.graphWidth + containerInfo.left;
                    }
                    if (i/2 >= 1) {
                        offsetTop = containerInfo.top + containerInfo.bottom + containerInfo.height + containerInfo.xAxisLabelHeight;
                    }
                    
                    if (widgetCharts[i].title) {
                        offsetTop = offsetTop + containerInfo.graphTitleOffset;
                    }

                    svgContainer
                    .append("g")
                    .attr("class", 'legend-container')
                    .attr("id", (isExport ? 'export-' : 'legend-') + reportId + '-' + this.tabId)
                    .attr("width", containerInfo.graphWidth)
                    .attr("height", 0)
                    .attr("transform", `translate(0,${containerInfo.top})`);

                    let svg = svgContainer
                    .append("g")
                    .attr("id", 'graph' + this.id + "-" + i)
                    .attr("class", 'graph' + this.id)
                    .attr("width", containerInfo.graphWidth)
                    .attr("height", graphHeight + (widgetCharts[i].title ? containerInfo.graphTitleOffset : 0))
                    .attr("transform", `translate(${offsetLeft+containerInfo.yAxisLabelOffset},${offsetTop})`);
                    
                    if (widgetCharts[i].title) {
                        svgContainer.append("text")
                        .attr("class", 'chart-title')
                        .attr("x", 0)             
                        .attr("y", 0)  
                        .style("font-size", containerInfo.fontSize)
                        .style("font-weight", "bold")  
                        .style("fill", "#000")
                        .text(widgetCharts[i].title)
                        .attr("transform", function () {
                            let topPos = containerInfo.top;
                            if (i > 1) {
                                topPos += containerInfo.graphHeight + containerInfo.top + containerInfo.bottom + containerInfo.xAxisLabelHeight+ containerInfo.xAxisLabelOffset ;
                            }
                            return `translate(${offsetLeft+containerInfo.yAxisLabelOffset},${topPos})`
                        });
                    }

                    this.clickableLegends = false;
                    this.selectionLimit = widgetCharts[i].keys.length;
                    switch (widgetCharts[i].chartType.toLowerCase()) {
                        case 'bar':
                            this.DrawBarChart(svg, containerInfo, widgetCharts[i], isExport);
                        break;
                        case 'line':
                            let chartData = copyData(this.chartData[i]);
                            if (widgetCharts[i].subType == 'IC') {
                                this.clickableLegends = true;
                                this.selectionLimit = 9;
                                if (this.chartSelection[this.currentReportId]) {
                                    chartData.keys = copyData(this.chartSelection[this.currentReportId]);
                                } 
                            }
                            this.collectAndPlotLengeds(svg, containerInfo, chartData, isExport)
                            this.DrawLineChart(svg, containerInfo, chartData, isExport);
                        break;
                        case 'bubble':
                            this.DrawBubbleClusterChart(svg, containerInfo, widgetCharts[i], isExport);
                        break;
                        case 'hexbin':
                            this.DrawHexbinChart(svg, containerInfo, widgetCharts[i], isExport);
                        break;   
                        case 'pie':
                            svgContainer.select('.legend-container')
                                .attr("transform", `translate(0,${containerInfo.top})`);
                            this.DrawPieChart(svg, containerInfo, widgetCharts[i], isExport);
                        break;   
                        default:
                            break;
                    }
                    this.$nextTick(() => {
                        this.adjustAxisLabelSpacing(svgContainer);
                    });
                }
            }
        }
    },
    getTextWidth (textString) {
        var f = '14px "Helvetica Neue", Helvetica, Arial, sans-serif',
            o = $('<div></div>')
                    .text(textString)
                    .css({'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font': f})
                    .appendTo($('body')),
            w = o.width();

        o.remove();

        return w;
    },
    setupTooltip (svgContainer) {
        if ($(".d3-tooltip", svgContainer).length == 0){
            this.tooltip = svgContainer
                .append('div')
                .attr('class', 'd3-tooltip')
                .style('position', 'absolute')
                .style('z-index', '10')
                .style('visibility', 'hidden')
                .style('padding', '10px')
                .style('background', 'rgba(0,0,0,0.6)')
                .style('border-radius', '4px')
                .style('color', '#fff')
                .text('');
        }
    },
    LightenDarkenColor(col, amt) {
           var usePound = false,
                num,
                r,
                b,
                g;

        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }

        num = parseInt(col, 16);
        r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        b = ((num >> 8) & 0x00FF) + amt;
        if (b > 255) {
           b = 255;
        } else if (b < 0) {
           b = 0;
        }

        g = (num & 0x0000FF) + amt;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }
        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    },
    ApplySaturationToHexColor(hex, saturationPercent) {
        var saturationFloat,
            rgbIntensityFloat,
            rgbIntensityFloatSorted,
            maxIntensityFloat,
            mediumIntensityFloat,
            minIntensityFloat,
            newMediumIntensityFloat,
            newMinIntensityFloat,
            intensityProportion,
            newRgbIntensityFloat,
            newRgbIntensityFloatSorted,
            rgbSortedIndex,
            newHex;

        function floatToHex(val) {
            return ('0' + Math.round(val * 255).toString(16)).substr(-2);
        }
        function rgb2hex(rgb) {
            return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]);
        };

        if (!/^#([0-9a-f]{6})$/i.test(hex)) {
            console.error('Unexpected color format');
            return false;
        }

        if (saturationPercent < 0 || saturationPercent > 100) {
            console.error('Unexpected color format');
            return false;
        }

        saturationFloat = saturationPercent / 100;
        rgbIntensityFloat = [
            parseInt(hex.substr(1, 2), 16) / 255,
            parseInt(hex.substr(3, 2), 16) / 255,
            parseInt(hex.substr(5, 2), 16) / 255
        ];

        rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function (a, b) {
            return a - b;
        });

        maxIntensityFloat = rgbIntensityFloatSorted[2];
        mediumIntensityFloat = rgbIntensityFloatSorted[1];
        minIntensityFloat = rgbIntensityFloatSorted[0];

        if (maxIntensityFloat === minIntensityFloat) {
            // All colors have same intensity, which means
            // the original color is gray, so we can't change saturation.
            return hex;
        }

        // New color max intensity wont change. Lets find medium and weak intensities.
        newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

        if (mediumIntensityFloat === minIntensityFloat) {
            // Weak colors have equal intensity.
            newMediumIntensityFloat = newMinIntensityFloat;
        } else {
            // Calculate medium intensity with respect to original intensity proportion.
            intensityProportion = (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
            newMediumIntensityFloat = (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
        }

         newRgbIntensityFloat = [];
         newRgbIntensityFloatSorted = [
            newMinIntensityFloat,
            newMediumIntensityFloat,
            maxIntensityFloat
         ];

            // We've found new intensities, but we have then sorted from min to max.
            // Now we have to restore original order.
            rgbIntensityFloat.forEach(function (originalRgb) {
            rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
            newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
        });

        newHex = rgb2hex(newRgbIntensityFloat);

        return newHex;
    },
    CreateBubbleNodes(graphData, keyValues) {
        let dataNodes = [];                
            for (let i = 0; i < keyValues.length; i++) {
                const kv = keyValues[i];                    
                for (let j = 0; j < graphData.length; j++) {                        
                    let dollarRange = graphData[j]["DollarRange"];
                    let ranges = dollarRange.replace(" ", "").split("–");
                    let lowAmount= parseInt(ranges[0].replace(/[-$,\s]/g, ""));
                    let highAmount= parseInt(ranges[1].replace(/[-$,\s]/g, ""));  
                    dataNodes.push({
                        value: graphData[j][kv.id],
                        id: i + j,
                        name: kv.display,
                        range : dollarRange,
                        amount: (lowAmount + highAmount)/2
                    });
                }
            }                
            dataNodes.sort(function (a, b) {                
            return b.name - a.name;
        });
        return dataNodes;
    },
    GenerateBubblesColors(mainColor, intensity, colorCount) {
        var i,
            newShade,
            newColor,
            newColors = [];
        for (i = 0; i < colorCount; i++) {
            newShade = this.LightenDarkenColor(mainColor, (intensity / colorCount) * i);
            newColor = this.ApplySaturationToHexColor(newShade, (100 / colorCount) * (colorCount - i));
            newColors.unshift(newColor);
        }
        return newColors;
    },
    AddBubbleChartNote() {
        let chartNoteObj = $("#d3idContainer" + this.id + " #chart-note"); 
        if (chartNoteObj.length === 0) {
            $("#d3idContainer" + this.id + " .notes").append("<div id='chart-note' class='alert alert-warning'></div>");
            chartNoteObj = $("#d3idContainer" + this.id + " #chart-note"); 
        }
        chartNoteObj.html(`<strong>Rollover bubbles for details:</strong>
            <div>Each bubble = a cluster of awards</div>
            <div>Bigger bubbles = more people in that cluster</div>
            <div>Darker shading = larger awards</div>
        `);
    },
    AddStackBar(parent, containerInfo, xKeyInfo, xScale, yScale, keyData, isExport) {   
        let keys = $.map(xKeyInfo, k=>{ return k.key; });
        let stack = d3.stack().keys(keys);        
        let layers = stack(this.data);
        let $this = this;
        
        // Define tooltip Div and dictionary 
        let tooltipKeys = keyData.filter(key => { return key.type === 'tooltip' });
         // ----------------
         // Layers/Series Rendering
         // ----------------       
 
         let mouseover = function (event, d) {       
         let key = d3.select(this.parentNode).datum().key;       
               let tooltipInfo = tooltipKeys.filter(d => { return d.id === key;})[0].display;
               $this.tooltip.transition().duration(200).style('visibility', 'visible');
               $this.tooltip.html(`<b>${d.data[keyData[0].id]}</b> <br> ${tooltipInfo}  <br>${d[1] - d[0]}%`)
                 .style('left', `${event.offsetX}px`)
                 .style('top', `${(event.offsetY+15)}px`);
             };

        parent.selectAll(".layer")
            .data(layers)
            .enter().append("g")
            .attr("class", "layer")
            .attr("fill", layer => {
                return xKeyInfo.filter(k => k.key === layer.key)[0].color;
            })
            .attr("opacity", "0.9")
            .selectAll("rect")
            .attr("class", "bar")
            .data(function(d) {
                return d;
            })
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function(d) {
                return xScale(d.data.fiscalYear);
            })
            .attr("y", function(d) {
                return  isExport ? yScale(d[1]) : containerInfo.height - containerInfo.top - containerInfo.bottom - containerInfo.legendSpacing
            })
            .attr("height", function(d) {
                return isExport ?  yScale(d[0]) - yScale(d[1]) : 1;
            })
            .attr("width", xScale.bandwidth())
            .on('mouseover', mouseover)
            .on('mouseout', () => $this.tooltip.transition().duration(500).style('visibility', 'hidden'));

            if (!isExport) {
                parent.selectAll("rect.bar")
                .transition() 
                .delay(200)
                .duration(1500)
                .attr("y", function(d) {
                    return yScale(d[1]);
                })
                .attr("height", function(d) {
                    let curHeight = (yScale(d[0]) -  yScale(d[1]));
                    return isNaN(curHeight) ? 0 : curHeight;
                })
            }
    },
    AddOverlappingLineOverBar(parent, d, containerInfo, x, y, keyData, yMaxAmount, $this) {
        let meanText,
            xField = keyData.filter(dt=> { return dt.type === 'x'}),
            symbol = d3.symbol().size([containerInfo.graphSymbolSize]),
            triangleSymbol = d3.symbol().size([containerInfo.graphSymbolSize - 50]).type(d3.symbolTriangle);

        parent.insert('rect')
                .data([d])
                .attr('class', 'bg-box')
                .attr('width', function () {
                    return (x(d[keyData[0].id]) + (x.bandwidth() / 2) + 10) - (x(d[keyData[0].id]) + (x.bandwidth() / 2) - 10);
                })
                // .attr('fill', '#ff89b1')
                .attr('fill', d => {
                    let medianFields = keyData.filter(i => { return i.id.includes("Median") && i.type === "color" });
                    if (medianFields.length > 1) {
                        let medianColor = '';
                        let shouldSkip = false;
                        medianFields.forEach(j => {
                            let fldColorKey = j.id.split("-")[1];
                            let fldColorValue = d[j.id.split("-")[1]];
                            let medianField = keyData.filter(i => {
                                return i.id === "Median-" + fldColorKey + "-" + fldColorValue && i.type === "color"
                            });
                            if (shouldSkip) {
                                return
                            }
                            medianColor = medianField[0].color;
                        })
                        return medianColor;
                    }
                    return "rgb(227, 166, 173)";
                })
                .attr('x', x(d[keyData[0].id]) + (x.bandwidth() / 2) - 10)
                .attr('y', function () {
                    if (d.lower < 0) {
                        return y(0);
                    } else {
                        return y(d.lower);
                    }
                })
                .attr('height', 0)
                .transition()
                .duration(100)
                .attr('y', function () {
                    if (d.higher > yMaxAmount) {
                        return y(yMaxAmount);
                    } else {
                        return y(d.higher);
                    }
                })
                .attr('height', function () {
                    var yHigh,
                        yLow;

                    if (d.higher > yMaxAmount) {
                        yHigh = y(yMaxAmount);
                    } else {
                        yHigh = y(d.higher);
                    }

                    if (d.lower < 0) {
                        yLow = y(0);
                    } else {
                        yLow = y(d.lower);
                    }

                    return yLow - yHigh;
                });

            // center line
            parent.selectAll('line.center')
                .data([[d.lower, d.higher, d[keyData[0].id]]])
                .enter().insert('line')
                .attr('class', 'center')
                .attr('style', "stroke: #000; stroke-dasharray: 3, 3;")
                .attr('x1', x(d[keyData[0].id]) + (x.bandwidth() / 2))
                .attr('x2', x(d[keyData[0].id]) + (x.bandwidth() / 2))
                .attr('y1', function () {
                    if (d.lower < 0) {
                        return y(0);
                    } else {
                        return y(d.lower);
                    }
                })
                .attr('y2', function () {
                    return y(d.lower);
                })
                .transition()
                .duration(100)
                .attr('y1', function () {
                    if (d.lower < 0) {
                        return y(0);
                    } else {
                        return y(d.lower);
                    }
                })
                .attr('y2', function () { // top point
                    if (d.higher > yMaxAmount) {
                        return y(yMaxAmount);
                    } else {
                        return y(d.higher);
                    }
                });

            // median line
            parent.selectAll('path.median')
                .data([d.mean])
                .enter()
                .append('path')
                .attr('class', 'median')
                .attr('d', symbol.type(d3.symbolSquare))
                .attr('fill', '#ad003b')
                .attr('transform', 'translate(' + (x(d[keyData[0].id]) + (x.bandwidth() / 2)) + ',' + y(d.mean) + ') rotate(-45)');

            // whiskers
            if (d.lower >= 0) {
                parent.selectAll('line.whisker-low')
                    .data([d.lower])
                    .enter()
                    .insert('line')
                    .attr('class', 'whisker-low')
                    .attr('style', "stroke: #000;")
                    .attr('x1', x(d[keyData[0].id]) + (x.bandwidth() / 2) - 10)
                    .attr('x2', x(d[keyData[0].id]) + (x.bandwidth() / 2) + 10)
                    .attr('y1', function (d) {
                        return y(d);
                    })
                    .attr('y2', function (d) {
                        return y(d);
                    });
            } else {
                parent.selectAll('line.tri-whisker-low')
                    .data([d.lower])
                    .enter()
                    .append('path')
                    .attr('class', 'tri-whisker-low')
                    .attr('d', triangleSymbol)
                    .attr('fill', '#d77594')
                    .attr('transform', 'translate(' + (x(d[keyData[0].id]) + (x.bandwidth() / 2)) + ',' + (y(0) - 5) + ') rotate(180)');
            }

            if (d.higher <= yMaxAmount) {
                parent.selectAll('line.whisker-high')
                    .data([d.higher])
                    .enter()
                    .insert('line')
                    .attr('class', 'whisker-high')
                    .attr('style', "stroke: #000;")
                    .attr('x1', x(d[keyData[0].id]) + (x.bandwidth() / 2) - 10)
                    .attr('x2', x(d[keyData[0].id]) + (x.bandwidth() / 2) + 10)
                    .attr('y1', function (d) {
                        return y(d);
                    })
                    .attr('y2', function (d) {
                        return y(d);
                    });
            } else {
                parent.selectAll('line.tri-whisker-high')
                    .data([d.higher])
                    .enter()
                    .append('path')
                    .attr('class', 'tri-whisker-high')
                    .attr('d', triangleSymbol)
                    .attr('fill', '#d77594')
                    .attr('transform', 'translate(' + (x(d[keyData[0].id]) + (x.bandwidth() / 2)) + ',' + (y(yMaxAmount) + 7) + ') rotate(0)');
            }

            // mean text background
            meanText = parent.selectAll('text.box')
                .data([d.mean]);

            meanText.enter().insert('rect')
                .attr('class', 'mean-text-bg')
                .attr('fill', '#ffffff')
                .attr('width', x.bandwidth())
                .attr('height', 15)
                .attr('x', x(d[keyData[0].id]))
                .attr('y', y(d.mean) - 30);

            // mean text text
            meanText.enter().insert('text')
                .attr('class', 'mean-text')
                .attr('text-anchor', 'middle')
                .attr('background-color', '#ffffff')
                .style("font-size", containerInfo.axisFontSize)
                .text(window.filters.roundAndFormatCurrency(d.mean, '$'))
                .attr('x', x(d[keyData[0].id]) + (x.bandwidth() / 2))
                .attr('y', y(d.mean) - 18);
        parent.insert('rect')
            .attr('class', 'tooltip-box')
            .attr('width', x.bandwidth())
            .attr('fill', 'transparent')
            .attr('x', x(d[keyData[0].id]) + (x.bandwidth() / 2) - 10)
            .attr('y', function () {
                if (d.higher > yMaxAmount) {
                    return y(yMaxAmount);
                } else {
                    return y(d.higher);
                }
            })
            .attr('height', containerInfo.height - containerInfo.top - containerInfo.bottom)
            .on('mouseover', function (event, d) {
                let htmlStr = '';
                for (let i=0; i<keyData.length; i++) {
                    if (keyData[i].type === 'x') {                            
                        htmlStr += `<div><b>${d[keyData[i].id]}</b></div>`;
                    }
                    else if (keyData[i].type === 'tooltip') {                     
                        htmlStr += `<div>`+ keyData[i].display+`: ${window.filters.formatCurrency(d[keyData[i].id], keyData[i].format)}</div>`;
                    }
                }
                $this.tooltip.html(htmlStr).style("top", (event.offsetY+15)+"px")
                .style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px")
                .style('visibility', 'visible');
            })
            .on("mousemove", function(){return $this.tooltip.style("top", (event.offsetY+15)+"px").style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px");})
            .on("mouseout", function(){return $this.tooltip.style("visibility", "hidden");});        
    },
    AddOverlappingBar(parent, containerInfo, field, color, xs, ys, overlap = 1,keyData, isExport, lowerBoundField = null) {           
        let $this = this;
        parent.selectAll(".bar." + field)
        .data(d => [d])
        .enter()
        .append("rect")
        .attr("class", "bar " + field)
        .attr("x", d => overlap === 1 ? xs(field) : xs(field) + overlap)
        .style("fill", color === '' ? d => {
            let medianFields = keyData.filter(i => { return i.id.includes("Bar") && i.type === "color" }); 
                        let medianColor = '';
                        let shouldSkip = false;
                        medianFields.forEach( j => {
                            let fldColorKey = j.id.split("-")[1];                            
                            let fldColorValue = d[j.id.split("-")[1]];
                            let medianField = keyData.filter(i => { 
                                return i.id === "Bar-" + fldColorKey + "-" + fldColorValue && i.type === "color" 
                            });                                 
                            if(shouldSkip) {
                                return
                            }
                            medianColor = medianField[0].color;                        
                        })            
                        return   medianColor;                
        } : color)
        .attr("y", d => {
            return isExport ? (isNaN(ys(d[field])) ?  0 : ys(d[field])) : containerInfo.height - containerInfo.top - containerInfo.bottom
        })
        .attr("width", d => {
            let wid = xs.bandwidth() - 3 * overlap;
            return wid > 0 ? wid : 0;
        })
        .attr("height", d => {
            let fieldHeight = lowerBoundField === null ? ys(d[field]) : ys(d[field] - d[lowerBoundField])
            let newHeight = containerInfo.height - containerInfo.top - containerInfo.bottom - fieldHeight; 
            return isExport ? isNaN(newHeight) ? 0 : newHeight : 0
        })
        .on('mouseover', function (event, d) {
            let htmlStr = '', formattedVal = '';
            for (let i=0; i<keyData.length; i++) {
            if (keyData[i].type === 'x') {
                formattedVal = d[keyData[i].id];
                htmlStr += `<div><b>${formattedVal}</b></div>`;
            }
            else if (keyData[i].type === 'y') {
                switch (keyData[i].format.toLowerCase()) {
                    case 'number':
                        formattedVal = window.filters.addCommas(d[keyData[i].id]) || '*'
                        break;
                    case '%':
                        formattedVal = window.filters.formatPercentage(d[keyData[i].id], keyData[i].format)
                        break;
                    case '$':
                        formattedVal = window.filters.formatLargeCurrency(d[keyData[i].id], keyData[i].format)
                        break;
                    default: 
                        formattedVal = d[keyData[i].id];
                        break;
                }
                htmlStr += `<div>`+ keyData[i].display+`: ${formattedVal}</div>`;
            }
            }
            $this.tooltip
                .html(htmlStr).style("top", (event.offsetY+15)+"px")
                .style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px")
                .style('visibility', 'visible');
        })
        .on("mousemove", function(){return $this.tooltip.style("top", (event.offsetY+15)+"px").style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px");})
        .on("mouseout", function(){return $this.tooltip.style("visibility", "hidden");});

        if (!isExport) {
            parent.selectAll(".bar." + field)
            .transition()
            .duration(1000)
            .attr("y", d => ys(d[field]))        
            .attr("height", d => {
                let fieldHeight = lowerBoundField === null ? ys(d[field]) : ys(d[field] - d[lowerBoundField])
                let newHeight = containerInfo.height - containerInfo.top - containerInfo.bottom - fieldHeight; 
                return isNaN(newHeight) ? 0 : newHeight;
            })
            .delay(function(d,i){return(i*100)})
        }        
    },
    AddLine(parent, containerInfo, field, color, xs, ys, keyData, isExport, subChart) {   
        let $this = this;
		let shape = keyData.filter(kd => kd.id === field)[0].shape;	
		this.data.forEach(function(dataItem, index, theArray) {
			if(theArray[index][field] == null) {
				let tmpObj = { };
				tmpObj[field] = null;
				theArray[index] = Object.assign({}, dataItem, tmpObj);
			}
		});
		
        parent.append("path")
        .datum(this.data) 
        .attr("class", "line")
        .attr("d", d3.line()
            .x(d => xs(d[keyData[0].id])+containerInfo.xAxisLabelOffset)
            .y(d => ys(d[field]))
            .defined(function(d) { return d[field] !== null && d[field] !== '*'; }) 
        )
        .style("fill", "none")
        .style("stroke", color)
        .style("stroke-width", 3)
        .call(transition);

        switch (shape) {
            case 'square':
                parent.selectAll("myCircles")
                    .data(this.data)
                    .enter()
                    .append("rect")
                        .attr("fill", color)
                        .attr("stroke", "none")
                        .attr('width', 10)
                        .attr('height', 10)
                        .attr("x", function(d) {  return d[field] ? xs(d[keyData[0].id])+containerInfo.xAxisLabelOffset -5 : 0})
                        .attr("y", function(d) {  return d[field] ? ys(d[field]) -5 : 0 })
                        .style("display", function(d) {  return d[field] ? 'inline' : 'none' })
                        .on('mouseover', function (event, d) {
                            if (subChart && subChart == 'IC') {
                                $this.generateICToolTip(event, d, keyData, field, $this);
                            }
                            else {
                                $this.generateToolTip(event, d, keyData, field, $this)
                            }
                        })
                        .on("mousemove", function(){return $this.tooltip.style("top", (event.offsetY+15)+"px").style("left",(event.offsetX -$this.tooltip.node().getBoundingClientRect().width/2)+"px");})
                        .on("mouseout", function(){return $this.tooltip.style("visibility", "hidden");})
                break;
            case 'triangle':
                parent.selectAll("myCircles")
                    .data(this.data)
                    .enter()
                    .append('svg')
                    .attr('class', 'point')
                    .attr("x", function(d) { return d[field] ? xs(d[keyData[0].id]) - 5 +containerInfo.xAxisLabelOffset : 0})
                    .attr("y", function(d) { return d[field] ? ys(d[field]) - 5 : 0 })
                    .attr('width', function(d) {  return d[field] ? 10 : 0 })
                    .attr('height', function(d) {  return d[field] ? 10 : 0 })
                    .attr("fill", color)
                    .append('polygon')
                    .attr('points', '0,10 5,0 10,10')
                    .attr('stroke-width', 0)
                    .attr("fill", color)
                    .attr('r', function(d) {  return d[field] ? 3 : 0 })
                    .on('mouseover', function (event, d) {
                        if (subChart && subChart == 'IC') {
                            $this.generateICToolTip(event, d, keyData, field, $this);
                        }
                        else {
                            $this.generateToolTip(event, d, keyData, field, $this)
                        }
                    })
                    .on("mousemove", function(){return $this.tooltip.style("top", (event.offsetY+15)+"px").style("left",(event.offsetX -$this.tooltip.node().getBoundingClientRect().width/2)+"px");})
                    .on("mouseout", function(){return $this.tooltip.style("visibility", "hidden");})
                break;
            case 'circle':
                parent.selectAll("myCircles")
                    .data(this.data)
                    .enter()
                    .append("circle")
                    .attr("fill", color)
                    .attr("stroke", "none")
                    .attr("cx", function(d) {  return d[field] || d[field] == 0 ? xs(d[keyData[0].id])+containerInfo.xAxisLabelOffset : 0})
                    .attr("cy", function(d) {  return d[field] || d[field] == 0  ? ys(d[field]) : 0 })
                    .attr("r", function (d) { return isExport || $this.clickableLegends ? (d[field] || d[field] == 0  ? 6 : 0) : 0})
                    .on('mouseover', function (event, d) {
                        if (subChart && subChart == 'IC') {
                            $this.generateICToolTip(event, d, keyData, field, $this);
                        }
                        else {
                            $this.generateToolTip(event, d, keyData, field, $this)
                        }
                    })
                    .on("mousemove", function(){return $this.tooltip.style("top", (event.offsetY+15)+"px").style("left",(event.offsetX -$this.tooltip.node().getBoundingClientRect().width/2)+"px");})
                    .on("mouseout", function(){return $this.tooltip.style("visibility", "hidden");})
                    .transition()
                    .delay(200)
                    .duration(1500)
                    .attr("r", function (d) { return (d[field] || d[field] == 0) && d[field] != '*'  ? 6 : 0});
                break;
            case 'cross':
                parent.selectAll("myCircles")
                    .data(this.data)
                    .enter()
                    .append('svg')
                    .attr('class', 'point')
                    .attr("x", function(d) {  return d[field] ? xs(d[keyData[0].id]) - 5 +containerInfo.xAxisLabelOffset : 0})
                    .attr("y", function(d) {  return d[field] ? ys(d[field]) - 5 : 0 })
                    .attr('width', 15)
                    .attr('height', 15)
                    .attr("fill", color)
                    .append('polygon')
                    .attr('points', '6.86 5.03 9.96 8.16 8.08 10.05 4.98 6.92 1.88 10.05 0 8.16 3.1 5.03 0 1.89 1.88 0 4.98 3.13 8.08 0 9.96 1.89 6.86 5.03')
                    .attr('stroke-width', 0)
                    .attr("fill", color)
                    .attr('r', 5)
                    .on('mouseover', function (event, d) {
                        if (subChart && subChart == 'IC') {
                            $this.generateICToolTip(event, d, keyData, field, $this);
                        }
                        else {
                            $this.generateToolTip(event, d, keyData, field, $this)
                        }
                    })
                    .on("mousemove", function(){return $this.tooltip.style("top", (event.offsetY+15)+"px").style("left",(event.offsetX -$this.tooltip.node().getBoundingClientRect().width/2)+"px");})
                    .on("mouseout", function(){return $this.tooltip.style("visibility", "hidden");})
                break;
        }
        

            function tweenDash() {
                const l = this.getTotalLength(),
                    i = d3.interpolateString("0," + l, l + "," + l);
                return function(t) { return i(t) };
            }
            function transition(path) {
                if (!isExport && !$this.clickableLegends) {
                    path.transition()
                        .duration(1500)
                        .attrTween("stroke-dasharray", tweenDash)
                        .on("end", () => { d3.select('.temperature-line').call(transition); });
                }
            }
    },
    AddLineArea(parent, containerInfo, field, color, xs, ys, keyData, isExport) {   
        let $this = this;

        parent.append("path")
        .datum(this.data) 
        .attr("fill", color)
        .attr("fill-opacity", .3)
        .attr("stroke", "none")
        .attr("d", d3.area()
            .x(function(d) { return xs(d[keyData[0].id])+containerInfo.xAxisLabelOffset })
            .y0( containerInfo.height - containerInfo.top - containerInfo.bottom )
            .y1(function(d) { return ys(d[field]) })
        )
    },    
    async AddHorizantalLengends(svg, containerInfo, legends, isExport, curChart) {            
        let padding = 20,
            legY = 0,
            legX = 0,
            $this = this,
            legendContainerId = isExport ? '#export-' : '#legend-';      

        let legendContainer =  d3.select(legendContainerId + this.currentReportId + '-' + this.tabId);
        let legend = legendContainer.selectAll('.legend')                    
            .data(legends)
            .enter()                                             
            .append('g')
            .attr('class', function (d) {
                let className = 'legend ';
                if (d.default) {
                    className += ' selected'
                }
                return className;
            })
            .style('color', function (d) {
                return d.legendColor;
            })
            .style('visibility', 'hidden')
            .on('click', function (e,d) {
                if ($this.clickableLegends) {
                    if ($this.noOfDrawnLines < $this.selectionLimit ) {
                        if (!d.default || (d.default && $this.noOfDrawnLines > 1)) {
                            d.default = !d.default;
                            $(e.target).parent().toggleClass('selected');
                            $("text", $(e.target).parent()).css("fill", d.default ? 'rgb(0, 0, 0)' : 'rgba(51, 51, 51, 0.5)');
                            $("rect, circle, polygon", $(e.target).parent()).css("fill", d.legendColor );
                            $("rect, circle, polygon", $(e.target).parent()).css("opacity", d.default ? '1' : '0.3');
                            $this.addRemoveLine(d, svg, containerInfo, isExport, curChart);
                            if ($this.noOfDrawnLines === $this.selectionLimit) {
                                $(e.target).parent().parent().find("g:not(.selected) rect").css("fill", 'rgba(51, 51, 51, 0.5)');
                                $(e.target).parent().parent().find("g:not(.selected) polygon").css("fill", 'rgba(51, 51, 51, 0.5)');
                                $(e.target).parent().parent().find("g:not(.selected) circle").css("fill", 'rgba(51, 51, 51, 0.5)');
                            }
                        }
                    }
                    else if ($this.noOfDrawnLines === $this.selectionLimit ) {
                        if (d.default) {
                            let unselecteNode = $(e.target).parent().parent().find("g:not(.selected)");
                            for (let c = 0; c <unselecteNode.length; c++) {
                                $("rect, circle, polygon", $(unselecteNode[c])).css("fill", $(unselecteNode[c]).prop("style").color);
                            }
                            d.default = !d.default;
                            $(e.target).parent().toggleClass('selected');
                            $("text", $(e.target).parent()).css("fill", d.default ? 'rgb(0, 0, 0)' : 'rgba(51, 51, 51, 0.5)');
                            $("rect, circle, polygon", $(e.target).parent()).css("fill", d.legendColor );
                            $("rect, circle, polygon", $(e.target).parent()).css("opacity", d.default ? '1' : '0.3');
                            $this.addRemoveLine(d, svg, containerInfo, isExport, curChart);
                        }
                    }
                }
            })
            .on('mouseover', function (e,d) {
                $this.highLightLegend(e,d,true);
            }).on('mouseout', function (e,d) {
                $this.highLightLegend(e,d,false);
            });
            
        if (curChart.chartType === 'Hexbin') {                                    
            let legendlabel = curChart.keys.filter(d => { return d.type === "legendlabel" }), cnt = 6, initialSpacing = 0;            
            if(legendlabel.length > 0) {                
                if (isExport) {
                    initialSpacing = 25;
                }
                legendContainer.append('text')
                    .attr('x', isExport ? 5 : -1)
                    .attr('y', -5)
                    .attr('with', containerInfo.fontSize)
                    .attr("style", "font-weight: bold")
                    .style("font-size", containerInfo.axisFontSize)
                    .text(d => legendlabel[0].display);
            }
            legend.each(function (e, i) {                   
            let legendG = d3.select(this);
            let currLegend = legends[i];               
           
            legendG = d3.select(this);
            legendG.append('text')
                    .attr('x', 15  + initialSpacing + cnt * 10)
                    .attr('y', -5)
                    .attr('with', containerInfo.fontSize)
                    .attr("style", "font-weight: bold")
                    .style("font-size", containerInfo.axisFontSize)
                    .text(d => { return d.legendText; })            

                    
                    var _s32 = (Math.sqrt(3)/2);
                    var A = 10;
                    var xDiff = -10;
                    var yDiff = (cnt++ * 10) + initialSpacing;
                    var pointData = [
                                        [0+yDiff, A+xDiff], 
                                        [A*_s32+yDiff, A/2+xDiff], 
                                        [A*_s32+yDiff, -A/2+xDiff], 
                                        [0+yDiff, -A+xDiff],
                                        [-A*_s32+yDiff, -A/2+xDiff], 
                                        [-A*_s32+yDiff, A/2+xDiff]
                                    ];

                legendG.selectAll("path.area")
                            .data([pointData]).enter().append("path")
                            .attr("d", d3.line())
                            .attr("fill", currLegend.legendColor);                         

            });
        } else if (curChart.chartType === 'Bubble') {
            legendContainer.attr('transform', function (d, i) {
                return 'translate(20, 25)';
            });
            legend.each(function (e, i) {   
                let legendG = d3.select(this);
                let currLegend = legends[i];
                var lg = svg.append('linearGradient')
                    .attr('id', 'Gradient'+i)
                    .attr('x1', 0)
                    .attr('x2', 1)
                    .attr('y1', 0)
                    .attr('y2', 0);

                    lg            
                    .append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', currLegend.legendColor.split("-")[0]);

                    lg            
                    .append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', currLegend.legendColor.split("-")[1]);

                    legendG.append('rect')
                    .attr('x', -20)
                    .attr('y', -22)
                    .attr('width', 35)
                    .attr('height', 15)
                    .style("fill", "url(#Gradient" + i + ")")

                    legendG.append('text')
                    .attr('x', 20)
                    .attr('y', -10)
                    .attr('width', "15px")
                    .style("font-size", containerInfo.axisFontSize)
                    .text(d => { return d.legendText; })
            });
        } else {
            legend.filter(d => d.shape == 'circle')
                .append('circle')
                .attr('r', 5)
                .attr('cx', 15)
                .attr('cy', -15)
                .style('fill', function (dt) {
                    return dt.legendColor
                })
                .style('opacity', function (dt) {
                    return !dt.default ? '0.3' : 1
                })
                .style('stroke', legends)
                .style('disabled', function (d) {
                    return true;
                });

            legend.filter(d => d.shape == 'square')
                .append('rect')
                .attr('width', 13)
                .attr('height', 13)
                .attr('x', 7)
                .attr('y', -21)
                .style('fill', function (dt) {
                    return dt.legendColor
                })
                .style('opacity', function (dt) {
                    return !dt.default ? '0.3' : 1
                })
                .style('stroke', legends)
                .style('disabled', function (d) {
                    return true;
                });

            legend.filter(d => d.shape == 'triangle')
                .append('svg')
                .attr('class', 'point')
                .attr("x", 8)
                .attr("y", -21)
                .attr('width', 12)
                .attr('height', 12)
                .style('fill', function (dt) {
                    return dt.legendColor
                })
                .append('polygon')
                .attr('points', '0,12 6,0 12,12')
                .attr('stroke-width', 0)
                .style('fill', function (dt) {
                    return dt.legendColor
                })
                .attr('r', 5)
                .style('opacity', function (dt) {
                    return !dt.default ? '0.5' : 1
                })
                .style('stroke', legends)
                .style('disabled', function (d) {
                    return true;
                });

            legend.filter(d => d.shape == 'cross')
                .append('svg')
                    .attr('class', 'point')
                    .attr("x", 8)
                    .attr("y", -20)
                    .attr('width', 15)
                    .attr('height', 15)
                    .style('fill', function (dt) {
                        return dt.legendColor
                    })
                    .append('polygon')
                    .attr('points', '6.86 5.03 9.96 8.16 8.08 10.05 4.98 6.92 1.88 10.05 0 8.16 3.1 5.03 0 1.89 1.88 0 4.98 3.13 8.08 0 9.96 1.89 6.86 5.03')
                    .attr('stroke-width', 0)
                    .style('fill', function (dt) {
                        return dt.legendColor
                    })
                    .attr('r', 5)
                    .style('opacity', function (dt) {
                        return !dt.default ? '0.5' : 1
                    });
                    
            
            if (!$this.clickableLegends && curChart.chartType.toLowerCase() !== 'pie') {
                legend.append('polygon')
                    .attr('points', '6.86 5.03 9.96 8.16 8.08 10.05 4.98 6.92 1.88 10.05 0 8.16 3.1 5.03 0 1.89 1.88 0 4.98 3.13 8.08 0 9.96 1.89 6.86 5.03 ')
                    .attr('stroke-width', 0)
                    .attr('transform', function (d, i) {
                        return 'translate(8, -20) scale(1)';
                    })
                    .style('fill', function (d, i) {
                        var valLenth = $this.data.filter (function (dt) {
                            return dt[legends[i].id] !== null;
                        })
                        return valLenth.length == 0 ? '#fff' : legends[i].legendColor;
                    });
            }

            legend.append('text')
            .attr('x', 25)
            .attr('y', -10)
            .text(function(d) { return d.legendText; })
            .style("font-weight", "bold")
            .style("font-size", containerInfo.axisFontSize)
            .style("fill", function(d, i) { 
                var valLenth = $this.data.filter (function (dt) {
                    return dt[legends[i].id] !== null;
                })
                return valLenth.length == 0 || !legends[i].default ? 'rgba(51, 51, 51, 0.5)' : 'rgb(0, 0, 0)'; 
            })
            .each(function (d, j) {
                let legendWidth = $this.getTextWidth(d.legendText);
                if (containerInfo.graphWidth < legendWidth) {
                    d3.select(this)
                        .text(d.legendText.substring(0, d.legendText.length - 15) + '...');
                }
            })
        }
        
        await $this.$nextTick(() => {
            legend.attr('transform', function (d, i) {
                if (i>0) {
                    legX = legX + legend.nodes()[i-1].getBBox().width + padding;

                    if (legX+legend.nodes()[i].getBBox().width + padding  > containerInfo.graphWidth) {
                        legX = 0;
                        legY = legY +20;
                    } 
                }
                return 'translate(' + legX + ',' + legY + ')';
            }).style('visibility', 'visible');

            if (legendContainer.nodes().length > 0) {
                let legendSpacing = legendContainer.nodes()[0].getBBox().height,
                    titleNode = svg.select('.chart-title').nodes(),
                    positionX = containerInfo.yAxisLabelOffset;
            
                if (titleNode.length > 0) {
                    legendSpacing += titleNode[0].getBBox().height;
                }
                if (curChart.chartType === 'Bubble') {
                    let svgEle = $(svg.nodes()[0]).parent();
                    d3.select(svgEle[0])
                        .attr('height', containerInfo.height + legendSpacing);
                    legendSpacing += (containerInfo.top - containerInfo.bottom);
                } else if (curChart.chartType.toLowerCase() === 'line') {
                    let svgEle = $(svg.nodes()[0]).parent();
                    d3.select(svgEle[0])
                        .attr('height', containerInfo.height + legendSpacing + 20);
                        d3.select(svgEle[0]).select('.xAxisLabel')
                        .attr('y', containerInfo.height + legendSpacing + 15);
                        
                    positionX += containerInfo.yAxisLabelOffset;
                    legendSpacing += 20;
                } else if (curChart.chartType.toLowerCase() === 'pie') {
                    positionX = 0;
                    legendSpacing += 20;
                } else {
                    positionX += containerInfo.yAxisLabelOffset;
                    legendSpacing += 20;
                }

                svg                             
                    .attr('transform', function(d, i) {                  
                        return "translate(" + (positionX) + "," + legendSpacing + ")";                  
                    });
            }
        });
    },
    AddBarLengends(svg, legends, containerInfo) {            
        let itemWidth = 90,
            itemHeight = 18,
            $this = this;      
        
        let legend = svg.selectAll('.legend')                    
            .data(legends.map(d => d.legendText))                                
            .enter()                                             
            .append('g')                                         
            .attr('class', 'legend')                             
            .attr('transform', function(d, i) {                  
                return "translate(" + i%legends.length * itemWidth + "," + Math.floor(i/legends.length) * itemHeight + 3 + ")";                  
            }); 
        
        legend.append('rect')
        .attr('x', 0)
        .attr('y', -22)
        .attr('width', 15)
        .attr('height', 15)
        .style('fill', function(d, i) { 
            return legends[i].legendColor; 
        })
        .style('stroke', legends);    
        
        legend.append('polygon')
            .attr('points', '6.86 5.03 9.96 8.16 8.08 10.05 4.98 6.92 1.88 10.05 0 8.16 3.1 5.03 0 1.89 1.88 0 4.98 3.13 8.08 0 9.96 1.89 6.86 5.03 ')
            .attr('stroke-width', 0)
            .attr('transform', function (d, i) {
                return 'translate(3, -19) scale(.9)';
            })
            .style('fill', function (d, i) {
                var valLenth = $this.data.filter (function (dt) {
                    return dt[legends[i].id] !== null;
                })
                return valLenth.length == 0 ? '#fff' : legends[i].legendColor;
            });

        legend.append('text')
        .attr('x', 20)
        .attr('y', -10)
        .style("font-size", containerInfo.axisFontSize)  
        .text(function(d) { return d; })
        .style("font-weight", "bold") 
        .style("fill", function(d, i) { 
            var valLenth = $this.data.filter (function (dt) {
                return dt[legends[i].id] !== null;
            })
            return valLenth.length == 0 ? 'rgba(51, 51, 51, 0.5)' : 'rga(0, 0, 0)'; 
        })
    },
    filterAxisLabel (text, keyData) {
        let $this = this,
            fyRange = this.data.map(d => d[keyData[0].id].toString());

        text.each(function () {
            var text = d3.select(this).text();

            if (text) {
                if ($this.data.length > 6 && fyRange.indexOf(text) % 2 !== 0) {
                    d3.select(this).attr('fill', 'transparent');
                }
            }
        });
    },
    addRemoveLine (data, svg, containerInfo, isExport, curChart) {
        let thisChart = curChart,
            legendsData = thisChart.keys,   
            curLegend = legendsData.filter(ld => ld.id === data.id && ld.type === 'y'),
            curLegendType = legendsData.filter(ld => ld.id === data.id && ld.type === 'legend');

        curLegend[0].default = data.default;
        curLegendType[0].default = data.default;
        this.setChartSelection({reportId: this.reportId, data: copyData(legendsData)});
        $(".graph" + this.id).empty();
        this.DrawLineChart(svg, containerInfo, thisChart, isExport);
    },
    highLightLegend (e, data, isHighLight) {
        if (this.noOfDrawnLines !== this.selectionLimit) {
            if (isHighLight ) {
                $("text", $(e.target).parent()).css("fill", 'rgb(0, 0, 0)');
                $("rect, circle, polygon", $(e.target).parent()).css("fill", data.legendColor );
                $("rect, circle, polygon", $(e.target).parent()).css("opacity", '1' );
            } else if (!$(e.target).parent().hasClass('selected')) {
                $("text", $(e.target).parent()).css("fill", 'rgba(51, 51, 51, 0.5)');
                $("rect, circle, polygon", $(e.target).parent()).css("fill", data.legendColor );
                $("rect, circle, polygon", $(e.target).parent()).css("opacity", '0.3' );
            }
        }
    },
    generateICToolTip(event, d, keyData, field, $this) {
        let htmlStr = '<strong>' + field + '</strong>', formattedVal = '';
        let selData = keyData.filter(rec => rec.id === field);
        if (keyData[0].display && keyData[0].display != "") {
            htmlStr += `<div>`+ keyData[0].display+`: ${d[keyData[0].id]}</div>`;
        } else {
            htmlStr += `<div><strong>${d[keyData[0].id]}</strong></div>`;
        }
        if (selData.length > 0) {
            formattedVal = window.filters.addCommas(d[selData[0].id]);
            htmlStr += `<div>Awards: ${formattedVal}</div>`;
        }
        $this.tooltip
            .html(htmlStr).style("top", (event.offsetY+15)+"px")
            .style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px")
            .style('visibility', 'visible');
    },
    generateToolTip (event, d, keyData, field, $this) {
        let htmlStr = '', formattedVal = '';
        let selData = keyData.filter(rec => rec.id === field);
        if (keyData[0].display && keyData[0].display != "") {
            htmlStr += `<div>`+ keyData[0].display+`: ${d[keyData[0].id]}</div>`;
        } else {
            htmlStr += `<div><strong>${d[keyData[0].id]}</strong></div>`;
        }
        if (selData.length > 0) {
            switch (selData[0].format) {
                case '%':
                    formattedVal = window.filters.formatPercentage(d[selData[0].id], selData[0].format);
                    break;
                case '$M':
                    formattedVal = '$' + Math.round(d[selData[0].id]) + 'M';
                    break;
                default:
                    formattedVal = d[selData[0].id];
            }
            htmlStr += `<div>`+ selData[0].display+`: ${formattedVal}</div>`;
        }
        $this.tooltip
            .html(htmlStr).style("top", (event.offsetY+15)+"px")
            .style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px")
            .style('visibility', 'visible');
    },
    async collectAndPlotLengeds (svg, containerInfo, curChart, isExport) {
        let keyData = curChart.keys,
            $this = this;
            
        this.data = this.data.filter(function (d) { return d[keyData[0].id] != "Type" ? d[keyData[0].id] : false });

        let legendInfo = []; 
        $.map( keyData, function( val, i ) {
            // Do something
            if (i !== 0 && val.type === "legend") {
                if ($this.clickableLegends) {
                    var valLength = $this.data.filter (function (dt) {
                        return dt[val.id] !== null;
                    })
                    if (valLength.length > 0) {
                        legendInfo.push({"legendText": val.display, "legendColor": val.color, "id": val.id, "default": val.default, "shape": val.shape})    
                    }
                } else {
                    legendInfo.push({"legendText": val.display, "legendColor": val.color, "id": val.id, "default": val.default, "shape": val.shape})
                }
            }
        });

        // Adding Legends
        if (legendInfo && legendInfo.length > 0) {
            await this.AddHorizantalLengends(svg, containerInfo, legendInfo, isExport, curChart);
            let legendContainerId = isExport ? '#export-' : '#legend-';  
            if ( d3.select(legendContainerId+ this.currentReportId + '-' + this.tabId).nodes().length > 0) {
                containerInfo.legendSpacing = d3.select(legendContainerId+ this.currentReportId + '-' + this.tabId).nodes()[0].getBBox().height;
            }
        }

    },
    DrawLineChart(svg, containerInfo, curChart, isExport) {
        let keyData = curChart.keys,
            $this = this,
            divisor;
            
        let maxLen = 0;
        let xKeys = [];
        $.map( keyData, function( val, i ) {
            // Do something
            if (val.type === "y" && val.default) {
                xKeys.push(val.id);
            }
        });
        if (curChart.yAxisDomainFormat === '%') {
            maxLen = 100;
        } else {
            if (xKeys.length == 1) {
                maxLen = d3.max(this.data, d => d[xKeys[0]]);
                
                divisor = Math.pow(10, String(Math.ceil(maxLen)).length - 1);
                maxLen = Math.ceil(maxLen / divisor) * divisor;
            } else {
                for (let i = 0; i < xKeys.length-1; i++) {
                    let curMaxLen = d3.max(this.data, d => d[xKeys[i]] > d[xKeys[i+1]] ? d[xKeys[0]] : d[xKeys[i+1]]);
                    if (curMaxLen > maxLen) {
                        maxLen = curMaxLen;
                    }
                }
            }
        }

        let xAxisData = this.data.map(d => d[keyData[0].id]);     
        let xScale = d3.scaleLinear().range([0, containerInfo.graphWidth - containerInfo.left - containerInfo.right]);
        let yScale = d3.scaleLinear().range([containerInfo.height - containerInfo.top - containerInfo.bottom- containerInfo.legendSpacing, 0]);
        yScale.domain([0, maxLen]);

        let xAxis = d3.axisBottom(xScale).tickFormat(d3.format("d")).ticks(xAxisData.length > 1 ? xAxisData.length-1 : xAxisData.length);        
        let yAxis = d3.axisLeft().scale(yScale)
                .tickFormat(function (d) {
                    switch(curChart.yAxisDomainFormat) {
                        case '%':
                            return Math.round(d) + '%';
                        case '$':
                            return window.filters.formatLargeCurrency(d);
                        case '$M':
                            return '$' + d + 'M';
                        case 'number':
                            return window.filters.addCommas(d);
                        default:
                            return d;
                    }
                })
                .tickSize(-containerInfo.graphWidth - containerInfo.left - containerInfo.right)
                .ticks(containerInfo.axisTicks);

        xScale.domain([d3.min(xAxisData), d3.max(xAxisData)]);             

        // Add the X Axis
        svg.append("g")
            .attr("class", "x-axis")
            .style("font-size", containerInfo.axisFontSize)
            .attr("transform", `translate(${containerInfo.xAxisLabelOffset},${containerInfo.height - containerInfo.top - containerInfo.bottom - containerInfo.legendSpacing})`)
            .call(xAxis)
            .selectAll('.tick text')
            .call(function (d) {
                if(!$this.$route.params.id)
                    $this.filterAxisLabel(d, keyData)
            });

        // Add the Y Axis
        svg.append("g")
            .attr("class", "y-axis")
            .style("font-size", containerInfo.axisFontSize)
            .call(yAxis)  
            .call(g => g.select(".domain").remove())
            .attr("transform", `translate(${isExport ? 10 : 0}, 0)`);


        this.noOfDrawnLines = 0;
        for (let i = 0; i < keyData.length; i++) {
            if (keyData[i].type === 'y' && this.noOfDrawnLines < this.selectionLimit && keyData[i].default  ) {
                this.AddLine(svg, containerInfo, keyData[i].id, keyData[i].color, xScale, yScale, keyData.filter(kd => kd.type == 'x' || kd.type == 'y'), isExport, curChart.subType);  
                this.noOfDrawnLines++;
            }
        }
        if (curChart.subType && curChart.subType == 'Area') {
            this.AddLineArea(svg, containerInfo, keyData[1].id, keyData[1].color, xScale, yScale, keyData.filter(kd => kd.type == 'x' || kd.type == 'y'), isExport);  
        }
    },
    async DrawBarChart(svg, containerInfo, curChart, isExport) {
        let keyData = curChart.keys,
            $this = this;

        this.data = this.data.filter(function (d) { return d[keyData[0].id] != "Type" ? d[keyData[0].id] : false })
        if (curChart.subType === "Mean") {
            let xKeys = [],
                yScale1 = d3.scaleLinear().range([containerInfo.height - containerInfo.top - containerInfo.bottom, 0]),
                meanTextBox = [];
            $.map(keyData, function (val, i) {
                if (val.type === "y" && !val.overlapping) {
                    xKeys.push(val.id);
                }
            });
                
            let x = d3.scaleBand()
                .rangeRound([0, containerInfo.graphWidth]).padding(containerInfo.barPadding);

            let y = d3.scaleLinear()
                .range([containerInfo.height - containerInfo.top - containerInfo.bottom, 0]);

            let yMaxAmount = d3.max($this.data, function (d) {
                return d.higher;
            });

            let  divisor = Math.pow(10, String(yMaxAmount).length - 1);
            yMaxAmount = Math.ceil(yMaxAmount / divisor) * divisor;

            // x, y domain
            x.domain($this.data.map(function (d) {
                return d[keyData[0].id];
            }));

            y.domain([0, yMaxAmount]);
            let maxLen = 0, tickOperator = 100000;
            if (xKeys.length == 1) {
                maxLen = d3.max(this.data, d => d[xKeys[0]]);
                maxLen = Math.ceil(maxLen/tickOperator)*tickOperator;
            } 
            yScale1.domain([0, maxLen]);

            let xAxis = d3.axisBottom()
                .scale(x);

             let yAxis = d3
                .axisLeft(yScale1)                            
                .tickFormat( d => { return   d === 0 ? '$' + (d / 1000).toFixed(0) : '$' + (d / 1000).toFixed(0) + 'K'  } )                                     
                .ticks(3)
                .tickSizeInner(-containerInfo.graphWidth);
                // Add the X Axis

                svg.append("g")
                    .attr("class", "x-axis")
                    .style("font-size", containerInfo.axisFontSize)
                    .attr("transform", `translate(0,${containerInfo.height - containerInfo.top - containerInfo.bottom })`)
                    .call(xAxis)
                    .selectAll('.tick text')
                    .call(function (d) {
                        if(!$this.$route.params.id)
                            $this.filterAxisLabel(d, keyData)
                    });

                // Add the Y Axis
                svg.append("g")
                    .attr("class", "y-axis")
                    .style("font-size", containerInfo.axisFontSize)
                    .call(yAxis)
                    .call(g => g.select(".domain").remove());

                let model_name = svg.selectAll(".model_name")
                    .data(this.data)
                    .enter().append("g")
                    .attr("class", "model_name");

                /* Add Bar and Line */            
                model_name.each(function (d) {
                    var g = d3.select(this);

                    $this.AddOverlappingLineOverBar(g, d, containerInfo, x, y, keyData, yMaxAmount, $this)
                }); // svg each
                function setBgPosition (svg) {
                    // mean text
                    svg.selectAll('.mean-text').each(function (d, i) {
                        meanTextBox[i] = d3.select(this).node().getBBox();
                    });

                    svg.selectAll('.mean-text-bg').each(function (d, i) {
                        d3.select(this)
                            .attr('x', meanTextBox[i].x)
                            .attr('y', meanTextBox[i].y-3)
                            .attr('width', meanTextBox[i].width)
                            .attr('height', meanTextBox[i].height+6);
                    });
                }

                setTimeout(() => {
                    setBgPosition(svg);
                }, 1)

                // g = svg.append('g')
                //     .attr('transform', 'translate(' + x.bandwidth() / 2 + ',0)');

                svg.selectAll('path.domain').remove();
                svg.selectAll('.x-axis').selectAll('.tick').selectAll('line').remove();
        } else if (curChart.subType === "Stack") {   
                
                let xKeyInfo = [],
                    legendInfo = [];

                $.map(keyData, d => {            
                    if (d.type === "y") {
                        xKeyInfo.push({ key : d.id, color: d.color });
                    }
                    if (d.type === "legend") {
                        legendInfo.push({"legendText": d.display, "legendColor": d.color, "id": d.id, "default": d.default, "shape": d.shape})
                    }
                });
                // ----------------
                // Legends Processing
                // ----------------
                // Adding Legends
                if(legendInfo && legendInfo.length > 0 ) {          
                    await this.AddHorizantalLengends(svg, containerInfo, legendInfo.reverse(), isExport, curChart);
                    let legendContainerId = isExport ? '#export-' : '#legend-';  
                    if ( d3.select(legendContainerId+ this.currentReportId + '-' + this.tabId).nodes().length > 0) {
                        containerInfo.legendSpacing = d3.select(legendContainerId+ this.currentReportId + '-' + this.tabId).nodes()[0].getBBox().height;
                    }
                }  
                
                // Define X and Y Scales
                let xScale = d3.scaleBand().range([0, containerInfo.width - containerInfo.left - containerInfo.right]).padding(containerInfo.barPadding);        
                let yScale = d3.scaleLinear().range([containerInfo.height - containerInfo.top - containerInfo.bottom - containerInfo.legendSpacing, 0]);

                // Define X and Y Axis
                let xAxis = d3.axisBottom(xScale);
                let yAxis = d3.axisLeft(yScale);        

                let ticksCount = curChart.yAxisDomainValues.length;
                // Setting Tick Count for Y Axis
                if(ticksCount) {
                    yAxis.ticks(ticksCount);
                }
                // Setting Tick Format and Domain for Y Axis        
                if(curChart.yAxisDomainFormat === '%') {
                    yAxis.tickFormat(d => Math.round(d) + "%");
                    yScale.domain([0, 100]);
                }
                // Setting Tick values for Y Axis        
                if(curChart.yAxisDomainValues) {
                    yAxis.tickValues(curChart.yAxisDomainValues);
                }
                // Setting Grid Line thikness
                yAxis.tickSizeInner();
                
                // X and Y Axis Processing
                // X Axis
                let xKey = keyData.filter(key => { return key.type === 'x' })[0].id;       
                xScale.domain(this.data.map(d => d[xKey]));     
                svg.append("g")
                .attr("class", "x-axis")
                .style("font-size", containerInfo.axisFontSize)
                .attr("transform", `translate(0,${containerInfo.height - containerInfo.top - containerInfo.bottom - containerInfo.legendSpacing})`)
                .call(xAxis)
                .selectAll('.tick text')
                .call(function (d) {
                    if(!$this.$route.params.id)
                        $this.filterAxisLabel(d, keyData)
                });

                // Y Axis
                svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${isExport ? 10 : 0}, 0)`)
                .style("font-size", containerInfo.axisFontSize)
                .call(yAxis)
                .call(g => g.select(".domain").remove());    

                this.AddStackBar(svg, containerInfo, xKeyInfo, xScale, yScale, keyData, isExport);
        } else {      
                // Adding Legends  
                let legendInfo = []; 
                $.map( keyData, function( val, i ) {
                    // Do something
                    if (i !== 0 && val.type === "legend") {
                        legendInfo.push({"legendText": val.display, "legendColor": val.color, "id": val.id})
                    }
                });
                if(legendInfo && legendInfo.length > 0 ) {
                    this.AddBarLengends(svg, legendInfo, containerInfo);
                    if (!this.$route.params.id) {
                        containerInfo.barPadding = 0.1;
                    }
                }
                let xScale0 = d3.scaleBand().range([0, containerInfo.graphWidth]).padding(containerInfo.barPadding);
                let xScale1 = d3.scaleBand();
                let yScale1 = d3.scaleLinear().range([containerInfo.height - containerInfo.top - containerInfo.bottom, 0]);

                let xScale2 = d3.scaleBand().range([0, containerInfo.graphWidth]).padding(containerInfo.barPadding);
                let xScale3 =  d3.scaleBand();
                let yScale2 = d3.scaleLinear().range([containerInfo.height - containerInfo.top - containerInfo.bottom, 0]);

                let xAxis = d3.axisBottom(xScale0).ticks(5);
                let yAxis = d3.axisLeft(yScale1).ticks(containerInfo.axisTicks).tickSizeInner(-containerInfo.graphWidth);
                
                let xKeys = [];
                $.map( keyData, function( val, i ) {
                    // Do something
                    if (val.type === "y" && !val.overlapping) {
                        xKeys.push(val.id);
                    }
                });
                xScale0.domain(this.data.map(d => d[keyData[0].id]));
                xScale1.domain(xKeys).range([0, xScale0.bandwidth()]);
                let maxLen = 0;
                if (xKeys.length == 1) {
                    maxLen = d3.max(this.data, d => parseInt(d[xKeys[0]]));
                } else {
                    for (let i = 0; i < xKeys.length-1; i++) {
                    let curMaxLen = d3.max(this.data, d => d[xKeys[i]] > d[xKeys[i+1]] ? d[xKeys[0]] : d[xKeys[i+1]]);
                    if (curMaxLen > maxLen) {
                        maxLen = curMaxLen;
                    }
                    }
                }
                yScale1.domain([0, maxLen]);

                let xKeysOverlap = [];
                $.map( keyData, function( val, i ) {
                    // Do something
                    if (val.type === "y" && val.overlapping) {
                        xKeysOverlap.push(val.id);
                    }
                });
                xScale2.domain(this.data.map(d => d[keyData[0].id]));
                xScale3.domain(xKeysOverlap).range([0, xScale2.bandwidth()]);
                yScale2.domain([0, maxLen]);
                // Add the X Axis
                
                svg.append("g")
                    .attr("class", "x-axis")
                    .style("font-size", containerInfo.axisFontSize)
                    .attr("transform", `translate(0,${containerInfo.height - containerInfo.top - containerInfo.bottom})`)
                    .call(xAxis)
                    .selectAll('.tick text')
                    .call(function (d) {
                        if(!$this.$route.params.id)
                            $this.filterAxisLabel(d, keyData)
                    });

                // Add the Y Axis
                svg.append("g")
                    .attr("class", "y-axis")
                    .style("font-size", containerInfo.axisFontSize)
                    .call(yAxis)
                    .call(g => g.select(".domain").remove());    

                let model_name = svg.selectAll(".model_name")
                    .data(this.data)
                    .enter().append("g")
                    .attr("class", "model_name")
                    .attr("transform", d => `translate(${xScale0(d[keyData[0].id])},0)`);

                /* Add field1 bars */
                for (let i = 0; i < keyData.length; i++) {
                    if (keyData[i].type === 'y') {
                        if (keyData[i].overlapping) {
                            this.AddOverlappingBar(model_name, containerInfo, keyData[i].id, keyData[i].color, xScale3, yScale2, 3, keyData, isExport);  
                        } else {
                            this.AddOverlappingBar(model_name, containerInfo, keyData[i].id, keyData[i].color, xScale1, yScale1, 1, keyData, isExport);  
                        }
                    }
                }
        }
    },
    async DrawHexbinChart(svg, containerInfo, curChart, isExport) {
        let $this = this;   
        let tooltipKeys = curChart.keys.filter(key => { return key.type === 'tooltip' });  
        let geoStateData = { "type": "FeatureCollection", "features": [] };

        let containerId = isExport ? "#export-div" : "#d3id-"+ this.currentReportId + '-' + this.tabId,
            scale = isExport ? 500 : window.isMobile ? 250 : 400,
            fontSize = window.isMobile ? '10px' : containerInfo.fontSize,
            svgContainer = d3.select(containerId + " svg"),
            offsetLeft = window.isMobile ? 0 : containerInfo.left;isMobile

            
        // ----------------
        // Legends Processing
        // ----------------
        // Adding Legends          
        let legendInfo = []; 
        curChart.keys.map( val => {            
            if (val.type === "legend") {
                legendInfo.push({"legendText": val.display, "legendColor": val.color, "id": val.id, "default": val.default, "shape": val.shape})
            }
        });
        if(legendInfo && legendInfo.length > 0 ) {        
            await this.AddHorizantalLengends(svg, containerInfo, legendInfo, isExport, curChart);
        }   
        let legendNodes = d3.select("#" + (isExport ? 'export-' : 'legend-') + this.reportId + '-' + this.tabId).nodes(),
            legendHeight = legendNodes.length > 0 ? legendNodes[0].getBBox().height : 0;

        this.data.forEach(d => {           
            let nodeData =    { 
                    "type": "Feature", 
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": d.coordinates
                    }, 
                    "properties": [] 
                };                
            nodeData.properties = d;
            geoStateData.features.push(nodeData);
        });
     
        // Map and projection
        var projection = d3.geoMercator()
            .scale(scale)
            .fitSize([containerInfo.width - containerInfo.right,containerInfo.height-legendHeight],geoStateData);

        // Path generator
        var path = d3.geoPath()
            .projection(projection)   
				
        // Draw the map
        svgContainer.append("g")
            .attr("id", "state-chart")
            .attr("transform", `translate(${offsetLeft},${containerInfo.top + legendHeight})`)
            .selectAll("path")
            .data(geoStateData.features)
            .enter()
            .append("path")
            .attr("fill", d => {
                if(d.properties.awardees > 60) {
                   return legendInfo[4].legendColor;
                } else if(d.properties.awardees >= 41 && d.properties.awardees <= 60) {
                   return legendInfo[3].legendColor;
                } else if(d.properties.awardees >= 21 && d.properties.awardees <= 40) {
                   return legendInfo[2].legendColor;
                } else if(d.properties.awardees >= 12 && d.properties.awardees <= 20) {
                   return legendInfo[1].legendColor;
                } else if(d.properties.awardees >= 0 && d.properties.awardees <= 11) {
                   return legendInfo[0].legendColor;
                }
                return "rgb(99, 63, 169)";
            })
            .attr("d", path)
            .attr("stroke", "white")
        
        // Defining Mouseover event for tooltip    
        let mouseover = function (event, d) {                
            $this.tooltip.transition().duration(200).style('visibility', 'visible');     
            let html = "";
            tooltipKeys.forEach(ttkv => {         
                html += "<div>";
                if(ttkv.display.length > 1) {
                    if(d.properties[ttkv.id] < 12) {
                        html += ttkv.display + ': ' + "*";
                    } else {
                        html += ttkv.display + ': ' + d.properties[ttkv.id];
                    }
                }   else {
                    html +=  "<b>" + d.properties[ttkv.id] + "</b>";
                }  
                html += "</div>";
            });            
            $this.tooltip.html(html)            
                .style('left', `${event.offsetX}px`)
                .style('top', `${(event.offsetY + 15)}px`);
        };
        
        // Add the labels
        svgContainer.append("g")
            .attr("id", "state-label")
            .attr("transform", `translate(${offsetLeft},${containerInfo.top + legendHeight})`)
            .selectAll("labels")
            .data(geoStateData.features)
            .enter()
            .append("text")
                .attr("x", function(d){return path.centroid(d)[0]})
                .attr("y", function(d){return path.centroid(d)[1]})
                .text(function(d){ return d.properties.code})
                .attr("text-anchor", "middle")
                .attr("alignment-baseline", "central")
                .style("font-size", fontSize)
                .style("font-weight", "bold")
                .style("fill", d => {
                    if(d.properties.awardees >= 0 && d.properties.awardees <= 11) {
                      return "black";
                    } else {
                      return "white";
                    }
                })
                .on('mouseover', mouseover)
                .on('mouseout', () => $this.tooltip.transition().duration(500).style('visibility', 'hidden'));   
     
    },
    async DrawBubbleClusterChart(svg, containerInfo, curChart, isExport) {        
        let $this = this;            
        let bubbleColors = [];
        let keyValues = curChart.keys.filter(d => d.type === "value");
        // Define tooltip Div and dictionary 
        let tooltipKeys = curChart.keys.filter(key => { return key.type === 'tooltip' });            

        // Setting Bubble Color
        keyValues.forEach(d=>  {
            bubbleColors[d.id] = this.GenerateBubblesColors(d.color, d.colorIntensity, 7)
        });
        
        // Packing Nodes for Bubble Chart
        let pack = d3
            .pack()
            .size([containerInfo.width - containerInfo.legendSpacing - containerInfo.right, containerInfo.height -containerInfo.bottom ])
            .padding(1.5);
        let root = d3
            .hierarchy({
                children: this.CreateBubbleNodes(this.data, keyValues)
            })
            .sum(d => {
                return d.value;
            });


        let bubbleColor = function(lrpColors, awardamount) {        
            return d3.scaleThreshold()
                .domain([ 10000, 20000, 30000, 40000, 50000, 60000, 70000])
                .range(lrpColors)(awardamount);
        }

        // Defining Mouseover event for tooltip    
        let mouseover = function (event, d) {                
            $this.tooltip.transition().duration(200).style('visibility', 'visible');
            let html = "";
            tooltipKeys.forEach(ttkv => {                     
                html += `${ttkv.display}: ${d.data[ttkv.id]}<br>`;                    
                });
            $this.tooltip.html(html)
                .style('left', `${event.offsetX}px`)
                .style('top', `${(event.offsetY + 15)}px`);
        };

        // Bubble Chart Nodes Processing
        let node = svg.selectAll('.node')
            .data(pack(root).leaves())
            .enter().append('g')
            .attr('class', 'node')
            .attr('transform', d => { return 'translate(' + d.x + ',' + d.y + ')'; });

        let animationDuration = isExport ? 0 : 1000;

        if(this.data.length > 0) {         
        node.append('circle')
            .attr('id', function (d) {
                return d.id;
            })
            .attr('r', 0)
            .on('mouseover', mouseover)
            .on('mouseout', () => $this.tooltip.transition().duration(500).style('visibility', 'hidden'))
            .transition()
            .duration(animationDuration)
            .attr('r', function (d) {
                return d.r;
            })
            .style('fill', function (d) {
                let kvs = keyValues.filter(kv => { return d.data.name === kv.display });    
                return bubbleColor(bubbleColors[kvs[0].id], d.data.amount);
            });
        }
        // ----------------
        // Legends Processing
        // ----------------
        // Adding Legends          
        let legendInfo = []; 
        curChart.keys.map( val => {            
            if (val.type === "legend") {
                legendInfo.push({"legendText": val.display, "legendColor": val.color, "id": val.id, "default": val.default, "shape": val.shape})
            }
        });
        if(legendInfo && legendInfo.length > 0 ) {        
            await this.AddHorizantalLengends(svg, containerInfo, legendInfo, isExport, curChart);
        }
        
        if (!isExport) {
            // Adding Bubble Chart Notes
            this.AddBubbleChartNote();
        }
    },
    displayCard (curChart) {
        let container,
            $this = this,
            totalData = 0,
			applications =0,
			awards = 0,
            hmltStr = `<div class="row row-eq-height mx-0">`;
		
		$this.data.map(dt => awards+=dt['awards']);
		$this.data.map(dt => applications+=dt['applications']);
        $this.isCardDataParsed = true;
        curChart.keys.forEach(function (key) {
            totalData = 0;			
            $this.data.map(dt => totalData+=dt[key.id]);
            if (key.id.toLowerCase().indexOf('mean') != -1) {
                totalData = Math.ceil(totalData/$this.data.length); 
            }
            switch (key.type && key.type.toLowerCase()) {
                case 'number':
                    totalData = window.filters.addCommas(totalData) || '*'
                    break;
                case '%':
                    totalData = Math.round(awards/applications*100); 
                    totalData = window.filters.formatPercentage(totalData, key.type)
                    break;
                case '$':
                    totalData = window.filters.formatCurrency($this.data[0][key.id], key.type)
                    break;
                case 'range':
                    totalData = window.filters.appendNumberString(totalData, key.type)
                    break;
                case 'years':
                    totalData = window.filters.addCommas($this.data[0][key.id]) + ' Years';
                    break;
                default: 
            }
            
            hmltStr += `<div class="col-lg-4 col-md-6 py-2 px-0"><div class="data-box mx-2">
                <div class="card-body px-0">
                    <h5 class="card-title text-primary">` + key.display + `</h5>
                    <p class="card-text fw-bold">` + totalData + `</p>
                </div></div>
            </div>`;
            
        });
        this.isCardDataParsed = true;
        setTimeout(function () {
            container = $("#d3idCard" + $this.currentReportId)
            container.html(hmltStr + "</div>");
        }, 301)
    },
    async DrawPieChart(svg, containerInfo, curChart, isExport) {
        let legends = curChart.keys.filter(key => { return key.type === 'legend' }),
            tooltips = curChart.keys.filter(key => { return key.type === 'tooltip' });
        let chartDataKey = curChart.keys.filter(key => { return key.type === 'chartKey' })[0],
            legendContainerId = isExport ? '#export-' : '#legend-',
            $this = this;
        // ----------------
        // Legends Processing
        // ----------------
        // Adding Legends
        let legendInfo = []; 
        legends.map( val => {
            let dataNode = this.data.filter(dt => dt.category.toString().toLowerCase() === val.display.toString().toLowerCase());
            if (dataNode && dataNode.length > 0) {
                if (dataNode[0][chartDataKey.id] !== 0) {
                    legendInfo.push({"legendText": val.display, "legendColor": val.color, "id": val.id, "default": val.default, "shape": val.shape})
                }
            }
        });
        if(legendInfo && legendInfo.length > 0 ) {        
            await this.AddHorizantalLengends(svg, containerInfo, legendInfo, isExport, curChart);
        }
        if ( d3.select(legendContainerId+ this.currentReportId + '-' + this.tabId).nodes().length > 0) {
            containerInfo.legendSpacing = $(legendContainerId+ this.currentReportId + '-' + this.tabId)[0].getBoundingClientRect().height;
        }
        const radius = (isExport ? containerInfo.graphWidth/4 : Math.min(containerInfo.graphWidth, containerInfo.height) / 2) - (containerInfo.left + containerInfo.right+containerInfo.legendSpacing);

        let svgChart = svg
        .append("svg")
            .attr("width", containerInfo.graphWidth+containerInfo.left + containerInfo.right+ containerInfo.legendSpacing)
            .attr("height", containerInfo.height- containerInfo.legendSpacing)
            .append("g")
            .attr("transform", `translate(${(containerInfo.graphWidth + 10)/2}, ${(containerInfo.height  - containerInfo.legendSpacing - containerInfo.top- containerInfo.bottom)/2})`);


        const color = d3.scaleOrdinal()
        .range(legendInfo.map(d=>d.legendColor))

        let filteredData = this.data.filter(d => {return d[chartDataKey.id] !== 100 && d[chartDataKey.id] !== 0 && d.category.toLowerCase() !== 'total'});

        // Compute the position of each group on the pie:
        const pie = d3.pie()
            .sort(function(d) { return d[1][chartDataKey.id]; })                          
            .value(function(d) { return d[1][chartDataKey.id]; });  

        const data_ready = pie(Object.entries(filteredData))
        var arcGenerator = d3.arc()
        .innerRadius(0)
        .outerRadius(radius)

        var labelGen = d3.arc()
        .innerRadius(radius - 25)
        .outerRadius(radius - 25)

        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svgChart
            .selectAll('whatever')
            .data(data_ready)
            .join('path')
            .attr('d', d3.arc()
                .innerRadius(isExport ? 0 : 1)
                .outerRadius(isExport ? radius : 0)
            )
            .transition()
            .duration(isExport ? 0 : 500)
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(radius)
            )
            .attr('fill', function(d){return(color(d.index)) });

        svgChart
            .selectAll('path')
            .on('mouseover', function (event, d) {
                let htmlStr = '',
                    formattedVal = d.data[1][tooltips[0].id];
                switch (tooltips[0].format.toLowerCase()) {
                    case '%':
                        formattedVal = window.filters.formatPercentage(d.data[1][tooltips[0].id], tooltips[0].format)
                        break;
                }
                htmlStr = d.data[1].category + "<br>" + (tooltips[0].display ? tooltips[0].display + ": " : "") + formattedVal;
                $this.tooltip
                .html(htmlStr).style("top", (event.offsetY+15)+"px")
                .style("left",(event.offsetX-$this.tooltip.node().getBoundingClientRect().width/2)+"px")
                .style('visibility', 'visible');
            })
            .on('mouseout', () => $this.tooltip.style('visibility', 'hidden'));
    },
    adjustAxisLabelSpacing (svg) {
        let legendHeight = svg.select('.legend-container').nodes().length>0 ? svg.select('.legend-container').nodes()[0].getBBox().height : 0;

        if (svg.select('.yAxisLabel')) {
            let existingPos = svg.select('.yAxisLabel').nodes().length > 0 ? parseInt(svg.select('.yAxisLabel').attr('x'))*-1 : 0;
            svg.select('.yAxisLabel')
                .attr('x', -(existingPos + legendHeight/2));
        }      
    }
    }
};
</script>

<style scoped>
.bar-positive { 
    transition: r 0.2s ease-in-out;
}

.axis {
font: 10px sans-serif;
}

.axis path,
.axis line {
    fill: none;
    stroke:#bbb;
    line-height:1.42857143
}

.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 1%;
    vertical-align: top;
    overflow: hidden;
}

</style>